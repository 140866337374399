<template>
  <div class="container-1000" style="padding-bottom: 64px;">
    <div class="page-title">
      <h1 class="title main-title has-text-centered">
        Rząska® - Stwórz wyjątkowy wystrój, który pokochasz!
      </h1>
    </div>
    <div class="content">
      <p class="center">
        Z przyjemnością witamy Państwa w naszej rodzinnej firmie, gdzie każdy z nas z pełną odpowiedzialnością i zaangażowaniem pracuje nad budowaniem wizerunku marki Rząska poprzez dostarczanie produktów najwyższej jakości oraz profesjonalnej obsługi. Nasza specjalizacja to produkcja osłon okiennych, na której skupiamy się każdego dnia, rozwijając i doskonaląc nasze produkty. Jesteśmy dumni z tego, co robimy i cieszymy się z Państwa zaufania oraz uznania dla naszej pasji i zaangażowania. Dziękujemy!
      </p>
      <div class="columns is-centered is-marginless">
        <ul class="ul-check column is-8 is-narrow m-b-20 is-size-6">
          <li>Złóż zamówienie szybko i wygodnie - skonfiguruj i zamów online.</li>
          <li>W ciągu kilku sekund dokonasz łatwego wyboru sposobu mocowania, materiału, profilu i rozmiaru.</li>
          <li>Jesteśmy gotowi służyć Ci swoją pomocą i udzielić porad, jeśli tego potrzebujesz.</li>
          <li>Wszystkie ceny są jasno widoczne w naszym sklepie internetowym, a koszt wysyłki pokrywamy my!</li>
        </ul>
      </div>
      <div class="columns is-multiline is-centered m-t-20 m-b-40">
        <div class="column is-12-tablet is-4-desktop">
          <div class="product-card">
            <div class="product-image">
              <a href="https://rzaska.pl/rzaska-roleta-plisowana-na-wymiar-montaz-bezinwazyjny-kolekcja-premium-white-day-bialy-kolor-p1">
                <figure class="image">
                  <picture>
                    <source srcset="../../static/rzaska/1.webp" type="image/webp">
                    <source srcset="../../static/rzaska/1.jpg" type="image/jpeg">
                    <img src="../../static/rzaska/1.jpg" alt="rolety plisowane bezinwazyjne">
                  </picture>
                </figure>
              </a>
            </div>
            <div class="product-content">
              <div class="title is-6 has-text-centered is-marginless products-list-title">
                <a href="https://rzaska.pl/rzaska-roleta-plisowana-na-wymiar-montaz-bezinwazyjny-kolekcja-premium-white-day-bialy-kolor-p1">
                  Rząska® rolety plisowane bezinwazyjne
                </a>
              </div>

              <div class="content m-t-10">
                <ul>
                  <li>60 sekundowy montaż</li>
                  <li>praktycznie niewidoczny zacisk Risifix</li>
                  <li>ponad 40 tkanin w 4 kolekcjach</li>
                  <li>gwarancja wysokiej jakości</li>
                  <li>darmowa wysyłka ❤️</li>
                </ul>
              </div>
            </div>

            <footer class="product-button">
              <a href="https://rzaska.pl/rzaska-roleta-plisowana-na-wymiar-montaz-bezinwazyjny-kolekcja-premium-white-day-bialy-kolor-p1" class="button is-primary is-fullwidth m-t-10">
                konfigurator produktu
              </a>
            </footer>
          </div>
        </div>
        <div class="column is-12-tablet is-4-desktop">
          <div class="product-card">
            <div class="product-image">
              <a href="https://rzaska.pl/rzaska-roleta-plisowana-na-wymiar-montaz-inwazyjny-kolekcja-premium-white-day-bialy-kolor-p230">
                <figure class="image">
                  <picture>
                    <source srcset="../../static/rzaska/2.webp" type="image/webp">
                    <source srcset="../../static/rzaska/2.jpg" type="image/jpeg">
                    <img src="../../static/rzaska/2.jpg" alt="rolety plisowane inwazyjne">
                  </picture>
                </figure>
              </a>
            </div>
            <div class="product-content">
              <div class="title is-6 has-text-centered is-marginless products-list-title">
                <a href="https://rzaska.pl/rzaska-roleta-plisowana-na-wymiar-montaz-inwazyjny-kolekcja-premium-white-day-bialy-kolor-p230">
                  Rząska® Rolety plisowane inwazyjne
                </a>
              </div>

              <div class="content m-t-10">
                <ul>
                  <li>prosty i bezpieczny montaż w listwie przyszybowej</li>
                  <li>małe i estetyczne uchwyty Risifix</li>
                  <li>ponad 40 tkanin w 4 kolekcjach</li>
                  <li>gwarancja wysokiej jakości</li>
                  <li>darmowa wysyłka ❤️</li>
                </ul>
              </div>
            </div>

            <footer class="product-button">
              <a href="https://rzaska.pl/rzaska-roleta-plisowana-na-wymiar-montaz-inwazyjny-kolekcja-premium-white-day-bialy-kolor-p230" class="button is-primary is-fullwidth m-t-10">
                konfigurator produktu
              </a>
            </footer>
          </div>
        </div>
        <div class="column is-12-tablet is-4-desktop">
          <div class="product-card">
            <div class="product-image">
              <a href="https://rzaska.pl/rzaska-roleta-plisowana-na-wymiar-dachowa-montaz-inwazyjny-kolekcja-premium-white-day-bialy-p459">
                <figure class="image">
                  <picture>
                    <source srcset="../../static/rzaska/3.webp" type="image/webp">
                    <source srcset="../../static/rzaska/3.jpg" type="image/jpeg">
                    <img src="../../static/rzaska/3.jpg" alt="rolety plisowane dachowe">
                  </picture>
                </figure>
              </a>
            </div>
            <div class="product-content">
              <div class="title is-6 has-text-centered is-marginless products-list-title">
                <a href="https://rzaska.pl/rzaska-roleta-plisowana-na-wymiar-dachowa-montaz-inwazyjny-kolekcja-premium-white-day-bialy-p459">
                  Rząska® rolety plisowane dachowe
                </a>
              </div>

              <div class="content m-t-10">
                <ul>
                  <li>pasujące do każdego rodzaju okien</li>
                  <li>małe i estetyczne uchwyty Risifix</li>
                  <li>ponad 40 tkanin w 4 kolekcjach</li>
                  <li>gwarancja wysokiej jakości</li>
                  <li>darmowa wysyłka ❤️</li>
                </ul>
              </div>
            </div>

            <footer class="product-button">
              <a href="https://rzaska.pl/rzaska-roleta-plisowana-na-wymiar-dachowa-montaz-inwazyjny-kolekcja-premium-white-day-bialy-p459" class="button is-primary is-fullwidth m-t-10">
                konfigurator produktu
              </a>
            </footer>
          </div>
        </div>
      </div>
      <h2 id="szanowny-kliencie-">
        Szanowny Kliencie,
      </h2>
      <p>chcielibyśmy podziękować za poświęcony czas na zapoznanie się z naszą firmą oraz ofertą produktów.</p>
      <p>Rolety marki Rząska to nie tylko praktyczne osłony okienne, zapewniające prywatność i wygodę, ale również oryginalna dekoracja, która wzbogaca każde wnętrze.</p>
      <p>W naszym sklepie oferujemy szeroki wybór tkanin i wykończeń, dzięki którym można stworzyć indywidualny system osłon idealnie dopasowany do Twoich potrzeb. W Rząska priorytetem jest wysoka jakość materiałów i rozwiązań konstrukcyjnych oraz indywidualne podejście do każdego klienta. Stale rozwijamy się, aby sprostać wymaganiom rynku, trendom i potrzebom naszych Klientów, korzystając z najnowszych technologii.</p>
      <p>Przed wprowadzeniem produktu do sprzedaży dokładnie go testujemy, aby zapewnić jego trwałość i wygodę użytkowania. Wszystkie produkty są starannie dopasowane do Twoich potrzeb, a bogata kolekcja tkanin pozwala na dostosowanie rolet do każdego wnętrza. Dzięki najwyższym standardom jakości, produkcji i materiałom, spełniamy oczekiwania nawet najbardziej wymagających Klientów.</p>
      <p>Zespół Rząska dokłada wszelkich starań, aby nasze produkty spełniały nie tylko najwyższe standardy, ale także spełniały wymagania Twojego domu, który jest dla nas równie ważny, jak dla Ciebie.</p>
      <p>Dziękujemy za zaufanie i zapraszamy do skorzystania z naszej oferty!</p>
      <figure class="kg-card kg-image-card kg-width-full">
        <img src="../../static/rzaska/pl-1.jpeg" class="kg-image" alt="żaluzje plisowane" srcset="../../static/rzaska/pl-1.jpeg 600w, ../../static/rzaska/pl-1.jpeg 1000w, ../../static/rzaska/pl-1.jpeg 1500w">
      </figure>
      <h2 id="dlaczego-warto-nam-zaufa-">
        Dlaczego warto nam zaufać?
      </h2>
      <p>Nasza firma Rząska® specjalizuje się w produkcji wysokiej jakości osłon okiennych, takich jak rolety, plisy i żaluzje. W przeciwieństwie do innych firm, wszystkie nasze produkty są zaprojektowane i produkowane przez nas samych, co gwarantuje, że otrzymasz produkt markowy i nie będziesz musiał męczyć się z trudnym montażem skomplikowanych chińskich zestawów.</p>
      <p>Posiadamy szeroki wybór produktów, które można łatwo skonfigurować za pomocą naszego intuicyjnego konfiguratora. Dzięki niemu możesz wybrać rodzaj mocowania, materiał, profil i rozmiar rolety, plisy czy żaluzji. Wszystko to pozwala na zwizualizowanie i wycenienie produktu, co znacznie ułatwia podjęcie decyzji.</p>
      <p>Jeśli potrzebujesz pomocy lub masz pytania, możesz skontaktować się z nami w dowolnej formie, którą preferujesz, a my chętnie Ci pomożemy. Aby ułatwić Ci decyzję, możemy również wysłać Ci darmowe próbki tkanin.<br><br>Jako producent plis i rolet, naszym celem jest zapewnienie trwałej satysfakcji naszych klientów. Dlatego szczególnie dbamy o spełnienie wszystkich ich życzeń i potrzeb. Codziennie zbieramy opinie klientów, aby jak najlepiej dopasować naszą ofertę do ich oczekiwań. <br><br>Chcemy zaoferować Państwu coś wyjątkowego dla Państwa domu: roletę Rząska®, wykonaną specjalnie na zamówienie, która będzie pasować idealnie i dostarczać przyjemności każdego dnia. Każda roleta jest produkowana lokalnie w naszej fabryce, od początku do końca. </p>
      <figure class="kg-card kg-image-card kg-width-full">
        <!-- <img src="../static/pl-2.jpeg" class="kg-image" alt="rolety dzień noc" srcset="../static/pl-2.jpeg 600w, ../static/pl-2.jpeg 1000w, ../static/pl-2.jpeg 1500w"> -->
        <img src="../../static/rzaska/pl-2.jpeg" class="kg-image" alt="rolety dzień noc" srcset="../../static/rzaska/pl-2.jpeg 600w, ../../static/rzaska/pl-2.jpeg 1000w, ../../static/rzaska/pl-2.jpeg 1500w">
      </figure>
      <p>Jako rodzina prowadząca firmę doskonale wiemy, co oznacza pojęcie "dom". Dlatego projektujemy i produkujemy nasze produkty zgodnie z najwyższymi standardami jakości materiałów i produkcji, aby spełnić Twoje indywidualne wymagania jako użytkownika, bo szanujemy i doceniamy Twój "dom".</p>
      <p>W przeszłości wiele osób, szukając oszczędności, zdecydowało się na zakup tzw. plis lub rolet z marketów budowlanych lub produktów chińskich, jednakże jakość tych produktów często pozostawia wiele do życzenia. Wyższej jakości produkty często były zbyt drogie dla przeciętnego konsumenta.</p>
      <p>Jako producent chcemy rozwiązać ten problem i otworzyć się bezpośrednio na rynek detaliczny, aby nasi klienci mogli bezpośrednio nabywać nasze produkty, a nie tylko przez architektów i renomowane firmy montażowe systemów osłon okiennych, jak to miało miejsce w przeszłości. Praca z naszymi klientami na co dzień sprawia nam nieopisaną radość.</p>
      <p>Byłoby dla nas i naszego zespołu niezwykłą przyjemnością, gdybyście mieli okazję doświadczyć naszych produktów w swoim domu, bawiąc się promieniami słońca i ciesząc się ich urokiem.</p>
      <p><strong>Serdecznie pozdrawiamy,</strong></p>
      <p>Rodzina Rząska</p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  }

}
</script>

<style></style>
