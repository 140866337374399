<template>
  <div class="section-faq">
    <div class="container-1000">
      <div class="section-tag-container">
        <div class="section-tag">
          Schuette.shop FAQ
        </div>
      </div>
      <h2 class="text-center">
        Schuette.shop - Ofte stilte spørsmål
      </h2>
      <div class="category-faq-container">
        <category-faq>
          <template #question>
            Hva står Schuette® for?
          </template>
          <template #answer>
            Vår familiebedrift tilbyr mye mer enn bare solskjerming, innsynsskjermer og tilbehør til hjemmet. Vi har gjort
            det til vår viktigste oppgave å få deg til å smile, øke komforten og forbedre livskvaliteten din. Med
            Schuette®-produkter får du ikke bare produkter til hjemmet ditt, men mye mer! Bare prøv dem og kjenn
            forskjellen. ♥︎
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Kan jeg få råd hvis jeg er usikker på hva som er den beste vindusbekledningen?
          </template>
          <template #answer>
            Selvfølgelig kan du det. Hvis du er usikker på om plisségardiner, plisségardiner med bikakestruktur,
            rullegardiner eller gardiner er det beste valget for dine vinduer, hjelper vårt ekspertteam deg gjerne når som
            helst! Send oss bilder av vinduene dine og fortell oss om du kan bore eller ikke, og eventuelt hvilken effekt
            du er mest interessert i: gjennomskinnelig, litt mørkere, helt mørkere, mot varme og kulde osv. Vi ser frem
            til å høre fra deg når som helst!
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Hvordan kan jeg kontakte kundeservice?
          </template>
          <template #answer>
            Vår kundeservice kan kontaktes via e-post på emailfuerdich@schuette.shop eller via meldingssystemet vårt på
            hjemmesiden.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Er alle Schuette®-produkter ugjennomsiktige?
          </template>
          <template #answer>
            Ja, uansett om du velger en plisségardin, rullegardin eller gardin, er alle Schuette®-produkter alltid 100 %
            ugjennomsiktige, og noen holder også sollyset helt ute og bidrar til å gjøre rommet helt mørkt.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Hvilket stoff bør jeg velge?
          </template>
          <template #answer>
            Valget av riktig stoff kan gjøres ut fra farge, design og stoffets egenskaper. Hver stofftype og design har
            sine egne fordeler. Stoffets egenskaper kan også variere avhengig av fargevalg. Du finner mer informasjon om
            de ulike stoffegenskapene på produktsiden vår.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Hva er sølvbelegget på Thermo-produkter?
          </template>
          <template #answer>
            Belegget på baksiden (vindussiden) av Thermo-stoffene våre reflekterer solstrålene og reduserer blant annet
            varmegjennomtrengningen betydelig. I tillegg gjør det blanke belegget rommet middels til svært mørkt. For
            mørke stofffarger er den iboende fargen på varmebeskyttelsen på baksiden av stoffet sølv, mens den for lyse
            farger er mer beige.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Kan jeg bestille stoffprøver?
          </template>
          <template #answer>
            Med glede. Stoffprøver kan bestilles via vår kundeservice.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Kan jeg bestille bare stoff eller bare profiler?
          </template>
          <template #answer>
            Det kommer an på. Det er mulig å etterbestille stoffprøver og ekstra profiler for takvinduer. Ta kontakt med
            vår kundeservice ved behov. Plisségardin- og rullegardinbraketter kan også bestilles som tilbehør når som
            helst via hjemmesiden vår. Hele stoffpaneler, profilskinner og gardinøyer kan ikke bestilles separat.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Hva er returbetingelsene?
          </template>
          <template #answer>
            Returbetingelsene varierer avhengig av produkttype. Alle skreddersydde produkter kan ikke returneres eller
            byttes, ettersom de lages individuelt og spesifikt for hver enkelt kunde umiddelbart etter at bestillingen er
            lagt inn. Produkter som ikke er skreddersydde, kan returneres innenfor returperioden, forutsatt at de er i
            perfekt stand. Den respektive produkttypen er beskrevet i produkttittelen. Du finner mer detaljert informasjon
            om returbetingelsene våre i våre generelle vilkår og betingelser og i avbestillingsreglene.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Hvor ligger Schuette®-fabrikken?
          </template>
          <template #answer>
            Schuette®-familiens fabrikk ligger i hjertet av Europa og ikke, som de fleste andre produsenter, utenfor EU,
            f.eks. i Asia. Lokale materialer, lokal produksjon, lokal levering for en bærekraftig fremtid! ♥︎
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Er det mulig å se Schuette®-produkter i et utstillingslokale før du kjøper dem?
          </template>
          <template #answer>
            For øyeblikket har vi ingen utstillingslokaler eller stasjonære butikker med Schuette®-produkter. Derfor har
            vi gjort produktbildene enda mer realistiske, slik at du kan være sikker på at du får akkurat det produktet du
            ser på hjemmesiden vår. I tillegg kan du bestille stoffprøver via vår kundeservice hvis du ønsker det.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Når begynner produksjonen av bestillingen min?
          </template>
          <template #answer>
            For å sikre raskest mulig utsendelse av alle produkter (inkludert spesialtilpassede produkter), produseres
            alle Schuette®-produkter for våre kunder umiddelbart etter at bestillingen er lagt inn. Vi starter til og med
            noen av forberedelses- og bearbeidingstrinnene automatisk og umiddelbart etter at vi har mottatt bestillingen.
            Vi kan derfor med stolthet si at majoriteten av alle bestillinger kan leveres før den angitte leveringsdatoen.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Er det nødvendig med verktøy for å montere Schuette®-produkter?
          </template>
          <template #answer>
            Dette avhenger av den valgte monteringsmetoden. For montering uten boring er det ikke nødvendig med verktøy.
            Hvis du velger å montere plisségardiner eller rullegardiner med boring, kan det til og med være tilstrekkelig
            med en skrutrekker, avhengig av type vindu. Hvis du i tillegg har en batteridrevet skrutrekker og små bor (⌀
            1,0 - 2,0 mm) for montering av plisségardiner "med boring", går det enda raskere og enklere.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Hvor lang tid trenger jeg til monteringen?
          </template>
          <template #answer>
            Dette avhenger av det valgte produktet og den tilhørende monteringsmetoden. Det har vært svært viktig for oss
            å gjøre monteringen av hvert enkelt produkt, uansett monteringsmetode, så rask og enkel som mulig for deg.
            Siden alle produktene våre allerede er montert så godt som mulig, er monteringen/installasjonen unnagjort på
            kort tid. Vi har sørget for at du slipper å legge puslespill, og at Schuette®-produktene kan installeres så
            raskt som mulig. Selve monteringen er en barnelek og tar bare noen få minutter.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Hvor finner jeg monteringsanvisningen?
          </template>
          <template #answer>
            Monteringsanvisningen følger med hvert produkt. Du finner den i emballasjen sammen med håndtakene og
            brakettene. Hvis du trenger et ekstra eksemplar, kan du kontakte vår kundeservice. De sender deg gjerne en
            PDF-fil på e-post.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Må jeg kjøpe braketter eller annet tilbehør separat?
          </template>
          <template #answer>
            Det er ikke nødvendig. Alle plissé- og rullegardinbraketter og andre enkeltdeler som kreves for montering,
            følger alltid med det bestilte produktet. For gardiner med tunnelbånd må imidlertid kroker eller glidere
            kjøpes separat, f.eks. i et byggevarehus.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Tilbyr Schuette® også monteringsservice?
          </template>
          <template #answer>
            Det er ikke nødvendig med en monteringstjeneste, da monteringen av våre produkter er en barnelek og ikke
            krever profesjonell hjelp. Alle produktene er allerede klargjort av oss, slik at ingenting trenger å justeres
            på nytt, og det er bare brakettene og håndtakene som skal monteres. Det er ikke nødvendig med profesjonell
            hjelp fra et monteringsteam, noe som også sparer betydelige kostnader!
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Har Schuette® en reparasjonstjeneste?
          </template>
          <template #answer>
            Ja, vi har en reparasjonstjeneste hvis plisségardiner eller rullegardiner må justeres eller forkortes, eller
            hvis noe går galt under monteringen. Ta kontakt med kundeserviceteamet vårt via e-post eller chat.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Hva kan jeg gjøre hvis jeg har gjort en feil?
          </template>
          <template #answer>
            Avhengig av i hvilken grad du har målt feil, er dette ikke noe problem. Når det gjelder plisségardiner, er det
            noen ganger til og med tilstrekkelig å bytte ut brakettene med andre. Hvis det ikke er nok, har vi også en
            reparasjonstjeneste der vi kan gjøre mindre målendringer. Har du tatt mål selv? Ta da kontakt med vår
            kundeservice og oppgi ordrenummeret ditt og de berørte produktene sammen med de nye ønskede målene.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Hvor lang tid tar forsendelsen?
          </template>
          <template #answer>
            Leveringstiden for hvert enkelt produkt vises på produktsiden og i handlekurven. Som regel sender vi også
            større bestillinger i løpet av 5-10 virkedager. Ta kontakt med oss hvis du har spesielle krav til
            leveringstid.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Hvor mye koster frakten?
          </template>
          <template #answer>
            Frakt er gratis innen Tyskland og til Østerrike. Fraktkostnadene for andre leveringsland vises i handlekurven
            så snart leveringslandet er valgt.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Hvordan kan jeg sjekke leveringsstatusen for bestillingen min?
          </template>
          <template #answer>
            I løpet av få dager etter at du har bestilt, sender vi en e-post til e-postadressen du har oppgitt i
            bestillingen, med forsendelsesnummer, leveringsfirma og en lenke til sporing av forsendelsen. Du kan når som
            helst bruke denne informasjonen til å sjekke forsendelsesstatusen.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Hvilke betalingsmåter er tilgjengelige for meg?
          </template>
          <template #answer>
            Alle tilgjengelige betalingsmåter vises i handlekurven.
            Følgende betalingsmåter er tilgjengelige for deg:

            <p>Forskuddsbetaling/bankoverføring</p>
            <p>Umiddelbar bankoverføring</p>
            <p>Kredittkort</p>
            <p>PayPal</p>
            <p>Klarna Pay Later-kjøp på konto</p>
            <p>Klarna Slice It avbetalingskjøp</p>
            <p>Giropay</p>
            <p>EPS</p>
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Når og hvordan mottar jeg fakturaen min?
          </template>
          <template #answer>
            Du mottar proformafakturaen på e-post som vedlegg til ordrebekreftelsen. Den endelige fakturaen sendes etter
            at betalingen er mottatt i betalingsbekreftelsen. Hvis du ikke har mottatt noen faktura, kan du kontakte vår
            kundeservice.
          </template>
        </category-faq>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryFaq from '~/components/shared/CategoryFaq'

export default {
  components: {
    CategoryFaq
  },
  props: {
    categories: {
      type: Array,
      default: null
    }
  }
}
</script>
