export default [
  {
    question: '¿Qué es Schuette®?',
    answer:
      'Nuestra fábrica familiar ofrece mucho más que revestimientos de ventanas y accesorios para el hogar. Hemos convertido en nuestra principal tarea poner una sonrisa en su cara, aumentar su comodidad y mejorar su calidad de vida. Con los productos Schuette®, no sólo obtendrá productos para su hogar, ¡sino mucho más! Simplemente pruébelos y sienta la diferencia. ♥︎'
  },
  {
    question:
      '¿Puedo obtener asesoramiento si no estoy seguro de cuáles son los mejores revestimientos para ventanas?',
    answer:
      'Sí, si no está seguro de si un estor plisado, un estor enrollable o una cortina son la mejor opción para sus ventanas, nuestro equipo de expertos estará encantado de ayudarle en cualquier momento. Sólo tiene que enviarnos fotos de sus ventanas e indicarnos si puede taladrar o no y, en caso necesario, qué efecto desea: translúcido, ligeramente oscurecedor, completamente oscurecedor, contra el calor y el frío, etc. Estaremos encantados de atenderle.'
  },
  {
    question:
      '¿Cómo puedo ponerme en contacto con el servicio de atención al cliente?',
    answer:
      'Puede ponerse en contacto con nuestro servicio de atención al cliente por correo electrónico en emailfuerdich@schuette.shop o a través de nuestro sistema de mensajería en la página de inicio.'
  },
  {
    question: '¿Son opacos todos los productos Schuette®?',
    answer:
      'Sí, independientemente de si elige un estor plisado, enrollable o cortina, todos los productos Schuette® son siempre 100% opacos.'
  },
  {
    question: '¿Qué tejido debo elegir?',
    answer:
      'La elección del tejido adecuado puede hacerse en función del color, el diseño y las propiedades del tejido. Cada tipo de tejido y diseño tiene sus propias ventajas. A veces, las propiedades del tejido también varían en función del color elegido. Encontrará más información sobre las propiedades de cada tejido en nuestra página de productos.'
  },
  {
    question: '¿Qué es el revestimiento de plateado de los productos Thermo?',
    answer:
      'El revestimiento plateado del reverso (lado de la ventana) de nuestros tejidos Thermo refleja los rayos solares y, entre otras cosas, ralentiza considerablemente la penetración del calor. Una propiedad adicional del revestimiento plateado brillante es un oscurecimiento medio o alto de la habitación.'
  },
  {
    question: '¿Puedo pedir muestras de tejido?',
    answer:
      'Sí, puede pedir muestras de tejido a través de nuestro servicio de atención al cliente.'
  },
  {
    question: '¿Puedo pedir sólo tejidos o perfiles?',
    answer:
      'Depende. Es posible pedir muestras de tejido y perfiles guía para tragaluces adicionales. Si lo desea, póngase en contacto con nuestro servicio de atención al cliente. Los soportes para estores plisados y enrollables también se pueden pedir en cualquier momento a través de nuestra página web. Los paneles enteros de tejido, los perfiles guía y los ojales de cortina no se pueden pedir por separado.'
  },
  {
    question: '¿Cuáles son las condiciones de devolución?',
    answer:
      'La política de devoluciones varía en función del tipo de producto. Todos los productos personalizados, es decir, hechos a medida, no pueden devolverse ni cambiarse, ya que se confeccionan de forma individual y específica para cada cliente directamente después de realizar el pedido. Los productos que no están hechos a medida pueden devolverse dentro del plazo de devolución, siempre que estén en perfectas condiciones. El tipo de producto correspondiente se describe en el título del producto. Encontrará información más detallada sobre nuestras condiciones de devolución en nuestras condiciones generales y en la política de cancelación.'
  },
  {
    question: '¿Dónde se encuentra la fábrica de Schuette®?',
    answer:
      'Nuestra fábrica de la familia Schuette® se encuentra en el corazón de Europa y no, como la mayoría de los demás fabricantes, fuera de la UE, por ejemplo en Asia. Usamos materiales locales, producción local, entrega local para un futuro sostenible. ♥︎'
  },
  {
    question:
      '¿Es posible ver los productos Schuette® en una sala de exposición antes de comprarlos?',
    answer:
      'Por el momento no disponemos de ninguna sala de exposición o tienda física con los productos Schuette®. Por eso hemos hecho que las imágenes de los productos sean lo más realistas posible, para que pueda estar seguro de que recibirá exactamente el producto que ve en nuestra página web. Además, si lo desea, puede solicitar muestras de tejido a través de nuestro servicio de atención al cliente.'
  },
  {
    question: '¿Cuándo comienza la producción de mi pedido?',
    answer:
      'Para garantizar el envío más rápido posible de todos los productos (incluidos los productos personalizados), todos los productos Schuette® se fabrican para nuestros clientes inmediatamente después de realizar el pedido. Por ello, podemos afirmar con orgullo que la mayoría de los pedidos se entregan antes de la fecha de entrega indicada.'
  },
  {
    question:
      '¿Se necesitan herramientas para el montaje de los productos Schuette®?',
    answer:
      'Depende del método de montaje elegido. Para el montaje sin atornillar no se necesitan herramientas. Si decide instalar los estores plisados o enrollables para atornillar, sólo necesitará un destornillador. Si además tiene a mano un taladro inalámbrico, será aún más rápido.'
  },
  {
    question: '¿Cuánto tiempo necesito para la instalación?',
    answer:
      'Depende del producto elegido y del método de montaje correspondiente. Para nosotros era muy importante que el montaje de cada producto, independientemente del método de montaje, fuera lo más fácil y rápido posible para usted. Como todos nuestros productos ya están montados lo mejor posible, el montaje/instalación se realiza en un abrir y cerrar de ojos. Hemos puesto especial cuidado en que no tenga que hacer ningún trabajo de rompecabezas y que sus productos Schuette® puedan instalarse lo más rápidamente posible. La instalación en sí es un juego de niños y sólo lleva unos minutos.'
  },
  {
    question: '¿Dónde puedo encontrar las instrucciones de montaje?',
    answer:
      'Las instrucciones de montaje se incluyen con cada producto. Las encontrará en el embalaje con las asas y los soportes. Si necesita una copia adicional, póngase en contacto con nuestro equipo de atención al cliente. Estarán encantados de enviarle un archivo PDF por correo electrónico.'
  },
  {
    question: '¿Tengo que comprar soportes u otros accesorios por separado?',
    answer:
      'No. Todos los soportes de estores plisados y enrollables y otras piezas individuales necesarias para la instalación se incluyen siempre con el producto pedido. Sin embargo, en el caso de las cortinas con cinta de túnel, los ganchos o deslizadores deben comprarse por separado, por ejemplo, en una tienda de bricolaje.'
  },
  {
    question: '¿Ofrece Schuette® también un servicio de instalación?',
    answer:
      'No, ya que el montaje de nuestros productos es un juego de niños y no se requiere ayuda profesional. Todos los productos están ya preparados por nosotros, siempre que se hayan medido según las instrucciones de medición, de modo que no hay que reajustar nada y sólo hay que montar los soportes y las asas. Para ello no es necesaria la ayuda profesional de un equipo de montaje, lo que también supone un considerable ahorro de costes.'
  },
  {
    question: '¿Dispone Schuette® de un servicio de reparaciones?',
    answer:
      'Sí, si necesita cordones más largos, algo ha ido mal durante el montaje o ha ocurrido otro percance, estaremos encantados de ayudarle. Póngase en contacto con nuestro equipo de atención al cliente por correo electrónico o chat.'
  },
  {
    question: '¿Qué puedo hacer si he cometido un error?',
    answer:
      'Dependiendo del grado de error que haya cometido, no es un gran problema. A veces incluso basta con sustituir los soportes por otros diferentes. Si ha medido mal, póngase en contacto con nuestro equipo de atención al cliente e indique su número de pedido, las dimensiones en cuestión y las nuevas dimensiones necesarias.'
  },
  {
    question: '¿Cuánto tarda el envío?',
    answer:
      'El plazo de entrega de cada producto se muestra en la página del producto y en la cesta de la compra.'
  },
  {
    question: '¿Cuánto cuesta el envío?',
    answer:
      'Los gastos de envío son gratuitos en Alemania y Austria. Los gastos de envío para otros países de entrega se muestran en la cesta de la compra en cuanto se selecciona el país de entrega.'
  },
  {
    question: '¿Cómo puedo comprobar el estado de mi pedido?',
    answer:
      'A los pocos días de realizar su pedido, recibirá un correo electrónico con el número de envío, la empresa de mensajería correspondiente y un enlace para realizar el seguimiento de su envío a la dirección de correo electrónico facilitada en el pedido. Puede utilizar esta información para comprobar el estado del envío en cualquier momento.'
  },
  {
    question: '¿Cuáles son las formas de pago disponibles?',
    answer:
      'Todos los métodos de pago disponibles se muestran en la cesta de la compra. Tiene a su disposición los siguientes métodos de pago:'
  },
  {
    question: '¿Cuándo y cómo recibo la factura?',
    answer:
      'Recibirá la factura proforma por correo electrónico como archivo adjunto a la confirmación del pedido. La factura definitiva se enviará tras la recepción del pago en la confirmación de pago. Si no ha recibido la factura, póngase en contacto con nuestro servicio de atención al cliente.'
  }
]
