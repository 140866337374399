<template>
  <div class="section-faq">
    <div class="container-1000">
      <div class="section-tag-container">
        <div class="section-tag">
          Schuette.shop FAQ
        </div>
      </div>
      <h2 class="text-center">
        Schuette.shop - Frequently asked Questions
      </h2>
      <div class="category-faq-container">
        <category-faq collapsable>
          <template #question>
            What does Schuette® stand for?
          </template>
          <template #answer>
            Our family manufactory offers much more than just window coverings, sun protection and home accessories. We have made it our main task to put a smile on your face, increase your living comfort and improve your quality of life. With Schuette® products, you not only get products for your home, but much more! Just try them out and feel the difference. ♥︎
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Can I get advice if I'm not sure about the best window coverings?
          </template>
          <template #answer>
            Of course. If you are not sure whether pleated blinds, honeycomb blinds, roller blinds or curtains are the best choice for your windows, our team of experts will be happy to help you at any time! Simply send us pictures of your windows and tell us whether you can drill or not and, if necessary, which desired effect you are most interested in: translucent, slightly darkening, completely darkening, against heat and cold, etc. We look forward to hearing from you at any time!
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            How can I contact customer service?
          </template>
          <template #answer>
            Our customer service can be contacted by e-mail at emailfuerdich@schuette.shop or via our message system on the homepage.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Are all Schuette® products opaque?
          </template>
          <template #answer>
            Yes, regardless of whether you choose a pleated blind, roller blind or curtain, all Schuette® products are always 100% opaque and some also keep sunlight out completely and help to darken rooms entirely.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Which fabric should I choose?
          </template>
          <template #answer>
            The choice of the right fabric can be made in terms of color, design and the properties of the fabric. Each type of fabric and design has its own advantages. The fabric properties sometimes also vary depending on the choice of color. You can find more information on the respective fabric properties on our product page.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            What is the silver coating on Thermo products?
          </template>
          <template #answer>
            The coating on the back (window side) of our thermal fabrics reflects the sun's rays and, among other things, significantly slows down the penetration of heat. An additional property of the reflective coating is a medium to high darkening of the room. With dark fabric colors, the inherent color of the heat protection finish on the back of the fabric is silver, but with light colors it is more of a beige tone.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Can I order fabric samples?
          </template>
          <template #answer>
            Yes, fabric samples can be ordered via our customer service.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Can I order just fabrics or profiles?
          </template>
          <template #answer>
            That depends. It is possible to order fabric samples and additional roof window guide profiles. If required, please contact our customer service. Pleated blind and roller blind brackets can also be reordered at any time via our homepage. Entire fabric panels, profile rails and curtain eyelets cannot be ordered separately.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            What are the return conditions?
          </template>
          <template #answer>
            The return conditions vary depending on the product type. All made-to-measure products, i.e. custom-made products, cannot be returned or exchanged as they are made individually and specifically for each customer directly after the order has been placed. Products that are not made to measure can be returned within the return period, provided they are in perfect condition. The respective product type is described in the product title. You can find more detailed information on our return conditions in our general terms and conditions and in the cancellation policy.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Where is the Schuette® manufactory located?
          </template>
          <template #answer>
            Our Schuette® family manufactory is located in the heart of Europe and therefore not, like the majority of other manufacturers, outside the EU, e.g. in Asia. Local materials, local production, local delivery for a sustainable future! ♥︎
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Is it possible to view Schuette® products in a showroom before buying?
          </template>
          <template #answer>
            At the moment we do not have any showrooms or stationary stores with our Schuette® products. We have therefore made the product images all the more realistic so that you can be sure that you will receive exactly the product you see on our homepage. In addition, fabric samples can be ordered via our customer service if required.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            When does the production of my order begin?
          </template>
          <template #answer>
            To ensure the fastest possible shipment of all products (including custom-made products), all Schuette® products are made for our customers immediately after the order is placed. We even start some preparation and processing steps automatically and immediately after the order is placed. We can therefore proudly say that the majority of all orders can be delivered before the announced delivery date.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Are any tools required for the assembly of Schuette® products?
          </template>
          <template #answer>
            This depends on the selected mounting method. No tools are required for an installation without drilling. If you decide to install pleated blinds or roller blinds with drilling, a screwdriver may even be all you need, depending on the window type. If you also have a cordless screwdriver and small drill bits (⌀ 1.0 - 2.0 mm) to hand, the installation will be even quicker and easier.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            How much time do I need for installation?
          </template>
          <template #answer>
            This depends on the chosen product and the corresponding mounting method. It was extremely important for us to make the assembly of each product, regardless of the assembly method, as easy and quick as possible for you. As all our products are already assembled as well as possible, assembly/installation is completed in no time at all. We have taken extra care to ensure that you don't have to do any puzzle work and that your Schuette® products can be installed as quickly as possible. The installation itself is child's play and only takes a few minutes.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Where can I find the installation instructions?
          </template>
          <template #answer>
            The assembly instructions are included with every product. You will find them in the packaging with the handles and brackets. If you need an additional copy, please contact our customer service. They will be happy to send you a PDF file by email.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Do I have to buy brackets or other accessories separately?
          </template>
          <template #answer>
            That is not necessary. All pleated and roller blind brackets and other individual parts required for installation are always included with the ordered product. For curtains with tunnel tape, however, the hooks or glides must be purchased separately, for example from a DIY store.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Does Schuette® also offer an installation service?
          </template>
          <template #answer>
            An installation service is not needed, as the installation of our products is child's play and no professional help is required. All products are already prepared by us so that nothing needs to be readjusted and only the brackets and handles need to be attached. No professional help from an assembly team is required for this, which also saves considerable costs!
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Does Schuette® have a repair service?
          </template>
          <template #answer>
            Yes, in the event that pleated blinds or roller blinds need to be adjusted or shortened or something has gone wrong during installation, we have set up a repair service. Please contact our customer support team by email or chat.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            What can I do if I have made a mistake?
          </template>
          <template #answer>
            Depending on the extent to which you have made a mistake, this is not a big deal. With pleated blinds, it is sometimes even enough to simply replace the brackets with different ones. If that is not enough, we still have our repair service, which allows us to make minor changes to the dimensions. Have you made a mistake in measuring? Then please contact our customer service and provide your order number and the affected products together with the new desired dimensions.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            How long does shipping take?
          </template>
          <template #answer>
            The delivery time for the respective product is displayed on the product page and in the shopping cart. In general, we also ship larger orders within 5-10 working days. If you have special delivery time requirements, please contact us.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            How much does shipping cost?
          </template>
          <template #answer>
            Shipping is free of charge within Germany and to Austria. The shipping costs for other delivery countries are displayed in the shopping cart as soon as the delivery country has been selected.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            How can I check the delivery status of my order?
          </template>
          <template #answer>
            Within a few days of placing your order, we will send an email with the tracking number, the relevant delivery company and a link to track your parcel to the email address provided in the order. You can use this information to check the shipment status at any time.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Which payment methods are available to me?
          </template>
          <template #answer>
            All available payment methods are displayed in the shopping cart.<br>
            The following payment methods are available to you:<br>
            Prepayment/bank transfer<br>
            Instant bank transfer<br>
            Credit card<br>
            PayPal<br>
            Klarna Pay Later purchase on account<br>
            Klarna Slice It installment purchase<br>
            Giropay<br>
            EPS<br>
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            When and how do I receive my invoice?
          </template>
          <template #answer>
            You will receive the pro forma invoice by email as an attachment to the order confirmation. The final invoice will be sent after receipt of payment in the payment confirmation. If you have not received an invoice, please contact our customer service.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            What are pleated blinds, and how do they work?
          </template>
          <template #answer>
            Pleated blinds are window coverings that consist of one piece of accordion-pleated fabric. The pleated fabric along with the handles on both profile rails allows for easy adjustment of the window blind. These blinds can be set at any height and adjusted from both the bottom as well as the top profile rail. Opening the blind stretches the individual pleats, while closing the window covering accentuates the pleat pattern.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Are pleated blinds suitable for different window shapes and sizes?
          </template>
          <template #answer>
            Yes. The best window shape for pleated blinds are rectangular windows, due to the equal rectangular form of the window covering itself. Windows can be very small starting from a size of 20x20 cm to very wide and high windows with a size up to 180x250 cm.
            However it is indeed also possible to cover windows in forms of circles, triangles, trapezes with pleated blinds. For an assembly like this, choose a mount with drilling to the wall or window frame.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            What are the advantages of pleated blinds over other window coverings?
          </template>
          <template #answer>
            <p>
              Pleated blinds have a wide range of benefits that include:
            </p>
            <div class="check-list-container">
              <ul class="check-list is-fullwidth">
                <li>
                  a sleek and timeless design
                </li>
                <li>a space-saving form</li>
                <li>endless top-down-bottom-up adjustments</li>
                <li>a wide range of functions depending on the chosen fabric: from light-filtering to dimming or fully darkening along with different stages of acoustic improvement</li>
                <li>assembly with or without drilling</li>
                <li>a broad palette of fabric colors and styles to match any interior and preference</li>
              </ul>
            </div>
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            What are the benefits of custom pleated blinds?
          </template>
          <template #answer>
            Custom pleated blinds are tailored individually to your preferences. It’s not just the functions or color of the fabric, profile rails and mounts, but most importantly the opportunity to perfectly match (with an accuracy to 1mm) the size of the blind to your window. This allows for a harmonious look as well as no compromises in terms functionality.
            In short, custom pleated blinds guarantee a better fit, color match, light-control, room acoustics as well as more privacy and better results in terms of energy-efficiency.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Are energy-efficient pleated blinds worth it?
          </template>
          <template #answer>
            Yes. Pleated blinds help with insulation and energy efficiency. The extent to which energy costs are lowered and how much better the insulation is, depends on the chosen fabric and type of pleated blind. The winner among pleated blinds in terms of energy-efficiency and insulation are Honeycomb blinds. Due to their air-trapping capability, during the colder months 40% less warmed air is lost through the window and in summer over 70% less heat enters the room (in comparison to no blind).
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            How to clean and maintain pleated blinds for longevity?
          </template>
          <template #answer>
            <p>
              Light dirt and stains:
            </p>
            <div class="check-list-container">
              <ul class="check-list is-fullwidth">
                <li>
                  Carefully remove possible dust with a dry brush or vacuum on the lowest setting.
                </li>
                <li>Use some lukewarm water and mild detergent on a soft non-fuzzy cloth to wipe and dab the affected area. Don’t rub or drench your blind in the mixture.</li>
              </ul>
            </div>
            <p>
              Stubborn dirt:
            </p>
            <div class="check-list-container">
              <ul class="check-list is-fullwidth">
                <li> Run a bath consisting of a fine fabric detergent solution (not hotter than 30ºC).</li>
                <li>
                  Close the pleated blind completely and swirl it in the mixture.
                </li>
                <li>Rinse the window blind with some clear water and squeeze it gently to remove excess water.</li>
                <li>
                  Hang your blind up and leave it closed to dry for about 12-16 hours. Your pleated blind should be regularly closed and opened again to prevent the pleats from sticking together.
                </li>
              </ul>
              <p>Finished!</p>
            </div>
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            How to measure windows for pleated blinds?
          </template>
          <template #answer>
            For drilling or gluing your pleated shades taking measurements is exactly the same. Meaning you can swap the mounting to the other one if you’d like at any time. Measure as follows for drilling/gluing:
            - Width = Glass pane width incl. sealing rubber - 0.3 cm
            - Height = Glass pane height incl. sealing rubber + 1 cm
            <p>
              The measurement instructions differ when picking a clamp mounting for your pleated shades:
            </p>
            - Width = Glass pane width incl. sealing rubber + 1 to 3 cm (per side)
            - Height = Window frame height + 2 to 5 cm
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Do pleated blinds provide privacy while allowing natural light in?
          </template>
          <template #answer>
            Yes. Pleated blinds always provide full privacy when completely opened. Depending on the chosen fabric they simultaneously let natural light in. For maximum possible light flow while retaining complete privacy, choose a light-colored fabric without thermal coating (e.g. Premium White).
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Are pleated blinds customizable in terms of colors and patterns?
          </template>
          <template #answer>
            Yes. That is one of the beauties of made-to-measure pleated blinds. Custom-made blinds can be personalized in terms of fabric type and color, which also includes different fabric patterns, mount type and color, profile rail color and measurements.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Are there special pleated blind fabrics suitable for moist environments like kitchens and bathrooms?
          </template>
          <template #answer>
            Yes. In order for pleated blinds to be suitable for moist environments they should not consist of natural fabrics like cotton, silk or linen, because these fabrics tend to soak up moisture and therefore rot or fade in the long run.
            The best moisture-resistant option are pleated blinds made of Polyester. Polyester blinds are durable, easy to clean and can be used in kitchens and bathrooms.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Which Roller Blinds Are Best?
          </template>
          <template #answer>
            The best roller blinds depend on your specific needs. For the kitchen and bathroom, consider Day & Night double roller blinds, offering both privacy and light control. For bedrooms or nurseries, solar roller blinds, also known as blackout roller blinds, provide optimal darkness and insulation.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Are Roller Blinds in Style?
          </template>
          <template #answer>
            Yes, roller blinds are timeless and adaptable to various interior styles (e.g. minimalistic, modern, boho and more). They have been on the market for a long time and that for a good reason. They offer a classic and modern look, making them a versatile choice for any room.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Can Roller Blinds Be Top Down Bottom Up?
          </template>
          <template #answer>
            Yes. To accommodate your preferences, roller blinds can be top-down bottom-up simply by screwing the back of the roller shade, i.e. the rail, onto the ceiling with the bottom up. The roller shade mechanism adapts automatically allowing for a smooth and efficient light and privacy control.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Do Roller Blinds Block Heat?
          </template>
          <template #answer>
            Yes, roller blinds block heat and UV rays, with the degree of effectiveness determined by the fabric choice. Providing effective energy efficiency and temperature regulation, Thermo Blackout roller blinds are the best for this purpose, due to their opaque fabric being additionally equipped with a silver coating that effectively reflects sun rays.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            How Do Roller Blinds Work?
          </template>
          <template #answer>
            Roller blinds feature a fabric attached on one side to a hollow tube, which can be rolled up or down using a beaded chain loop. On the bottom of the fabric there is a weighting bar keeping the fabric that is hanging down smooth and in position. These blinds are user-friendly and versatile for light control.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            How to Measure Roller Blinds?
          </template>
          <template #answer>
            To measure roller blinds accurately, add a small allowance to your window’s width and height, considering the window handle’s clearance. The measurement process is the same for both inside and outside mount options.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            What Color Roller Blinds Should I Get?
          </template>
          <template #answer>
            The choice of roller blind color depends on your preferences. You can match the color to your window trim, walls, and decor in order to make the room appear bigger or choose contrasting tones for accentuating your windows. Roller blinds offer both style and privacy in various colors. With neutrals like white, beige or gray, you’re always on the safe and timeless side.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            How to Install Roller Blinds?
          </template>
          <template #answer>
            Installing roller blinds is straightforward. They come almost fully assembled, requiring only the attachment of brackets to your window frame or wall. You can choose between no-drill or drill mounts. Both mounting options are always provided. <br>
            Assembly without drilling: 1. Slide the clamp holders on the back of the roller blind rail. 2. Attach the adhesive straps onto the clamp holders (optional). 3. Click the roller blind with the attached clamp holders onto your window frame.
            <br>
            Assembly with drilling: 1. Screw the mounts with drilling on the desired position of your window frame, ceiling or wall. 2. Click the roller blind rail on the mounted brackets.
            <br>
            Roller blinds can also be mounted outside the window recess. Screw the provided brackets with drilling onto the wall above the window recess.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Can Roller Blinds Be Repaired?
          </template>
          <template #answer>
            <p>
              Yes. If your roller blind does not roll up evenly, i.e. the fabric rolls up more to one side (called telescoping) instead of straightly up, try the following steps:
            </p>
            <div class="check-list-container">
              <ul class="check-list is-fullwidth">
                <li>Check whether your roller blinds brackets are even and in line. For an assembly without drilling, the clamp holders shouldn’t be too loose on the window.</li>
                <li>
                  Use a spirit level to check whether your blind hangs horizontally on the window. (A common reason for telescoping.)
                </li>
                <li>
                  Unroll the fabric completely and roll it slowly up again.
                </li>
                <li>
                  If the previous solutions didn’t help, unroll the fabric completely until the tube to which the fabric is affixed becomes visible. Take a 5 cm strip of masking tape and attach it exclusively to the tube, oriented perpendicular to the fabric. Place it on the side of the tube where the fabric isn’t properly aligned (e.g. if the material rolls up to the left, attach the strip to the right side of the tube). Check if the fabric rolls up evenly now. If not, add additional strips on top of the previous one.
                </li>
              </ul>
            </div>
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            How to Clean Roller Blinds?
          </template>
          <template #answer>
            Cleaning roller blinds is simple. Begin by dusting with a dry brush or vacuum on a low setting. For stains, use lukewarm water with a mild dishwashing detergent (to avoid watermarks) on a soft cloth. Avoid rubbing, and let it air dry. Double roller blinds can be fully cleaned, but the silvery backside of Thermo Blackout roller blinds should not come in contact with water or detergent.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Which blackout curtains are the best?
          </template>
          <template #answer>
            The best blackout curtain colors and fastening methods are dependent on your unique style and practical considerations. Your choice of color should harmonize with your room’s existing theme or create a striking contrast to add a pop of character to your windows. Blackout curtains come in a range of exquisite colors, allowing you to select the perfect shade that aligns with your preferences.
            <br>
            When it comes to fastening methods, you have two classic and timeless options: eyelets and tunnel tape. The decision between these two depends on the space above your window and the room’s aesthetics. Eyelets are ideal for curtain rods, offering easy installation and smooth movement. On the other hand, curtains with tunnel tape can be mounted on both curtain rods and rails. These options ensure you can achieve both functionality and style.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            How do blackout curtains work?
          </template>
          <template #answer>
            Blackout curtains are crafted from thick, tightly-woven polyester with a luxurious appearance. The secret behind their effectiveness lies in this high-quality fabric. It is this construction that allows them to cast your room into near-complete darkness along with noise-reduction and better acoustics, making them the perfect window treatment for bedrooms and living rooms or whenever you desire a serene and private environment.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Will blackout curtains reduce heat?
          </template>
          <template #answer>
            Yes. Blackout curtains are excellent at regulating room temperature, because of the tightly-woven and high-quality fabrics that provide full darkening and prevent heat from entering the room. If no sun rays can enter the room, there is nearly no opportunity for the room temperature to rise.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Are blackout curtains noise-reducing?
          </template>
          <template #answer>
            Yes. Noise from the outside penetrates to a large extent through windows. Therefore, any kind of window covering is useful. However the thicker the material the less noise comes through. Blackout curtains are definitely noise-reducing and make a tremendous difference to windows with no window coverings. When living near busy streets or surroundings the best option for maximum noise reduction would be a combination of blackout curtains and pleated shades.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            How do blackout curtains save energy?
          </template>
          <template #answer>
            Blackout curtains play a significant role in conserving energy in your home. Most of your energy loss occurs through windows, typically the thinnest part of your walls. These curtains effectively combat this issue by minimizing heat exchange between your room and the outside environment.
            <br>
            During the winter months, they trap warm air inside, preventing it from escaping through the window or any irregularities in the window frame. This means that you’ll need less time to heat your room, resulting in reduced heating costs. When it’s summertime, blackout curtains provide the same level of efficiency. By keeping sunlight at bay, your space remains cooler, reducing the need for prolonged air conditioning. This dual-season functionality ensures that blackout curtains are the ideal year-round window treatment for energy-conscious individuals.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            What sizes do blackout curtains come in?
          </template>
          <template #answer>
            Blackout curtains come in a wide variety of sizing options. In particular personalized or custom-made blackout curtains offer an extraordinary range of sizes. You can even enter the measurements in steps of 1 mm and therefore have literally millions of sizing options, with widths up to 270 cm and a maximum height of 450 cm.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Can blackout curtains be white?
          </template>
          <template #answer>
            Blackout curtains are available in various colors, including white. The color of blackout curtains doesn’t affect their light-blocking capabilities. Even lighter-colored options like white maintain their darkening effect. The key lies in the quality of the fabric and its composition, not its color. For this reason, it’s advisable to choose high-quality materials to ensure effective light blocking.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            How to hang blackout curtains?
          </template>
          <template #answer>
            Hanging blackout curtains is a straightforward process that you can easily handle. Start by mounting the curtain rod or rail, following the installation instructions provided by the supplier. If the rod or rail is already in place, the curtain installation is a breeze and doesn’t require any professional assistance.
            <br>
            For curtains with eyelets/grommets: Simply slide the eyelets onto the curtain rod with the front side of the fabric facing the room. Eyelets are classic and timeless, known for their easy installation and smooth movement.
            For curtains with tunnel tape: Tunnel tape curtains offer versatility, as they can be mounted on both curtain rods and rails. To mount them on a rod, slide the loops of the tunnel tape onto the rod. For rail installation, you’ll need matching hooks. These curtains allow for flexibility in terms of aesthetics and function.
            To install blackout curtains over blinds: Install the blinds in front of the window pane. Then, follow the instructions for hanging blackout curtains over them. This combination provides you with light control options and ensures maximum light blocking when needed.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Can blackout curtains be washed?
          </template>
          <template #answer>
            Yes. They are machine washable. Use a gentle cycle with a maximum temperature of 30 degrees Celsius and don’t overload the machine (a maximum of 50% load is recommended). Use a mild detergent without optical brighteners and opt for a low-speed spin cycle. If the curtains have eyelets, they can remain attached during washing as they are washable and non-detachable.
            <br>
            An alternative cleaning method is to hand-wash your curtains. Dab individual stains with a mixture of soap and water, then rinse with clear water. Avoid rubbing, brushing, wringing, or boiling. After washing, hang them while slightly damp to prevent creases, and do not tumble dry.
            However, blackout curtains cannot be dry cleaned, as it may damage the fabric and its light-blocking properties.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            How to iron blackout curtains?
          </template>
          <template #answer>
            Smooth Blackout curtains can be ironed on the left/back side with a temperature up to 100 degrees Celsius.
            <br>
            Velvet Blackout curtains should not be ironed in order to keep the fabrics features in tact. However if it’s absolutely necessary, steaming is possible.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Why blackout curtains?
          </template>
          <template #answer>
            Blackout curtains offer a multitude of benefits. They provide additional privacy, light control, and temperature regulation, making them versatile window coverings. These curtains can be installed alongside other window coverings, such as roller or pleated shades, allowing you to choose the most suitable option for your needs. They also serve as an excellent backdrop for videos and photos, thanks to their easy installation and a wide range of color choices. Blackout curtains are ideal for achieving a restful sleep environment, but they also enhance room acoustics and contribute to energy efficiency.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            When blackout curtains aren’t enough?
          </template>
          <template #answer>
            While blackout curtains are effective at blocking light and noise, there may be instances where they are not sufficient to fully darken a room. Bright external lights, street lamps, or intense sunlight can be challenging to combat with blackout curtains alone. In such cases, you might consider a combination of window coverings, such as blackout curtains and pleated shades, for a layered solution. This approach offers greater light control and ensures complete darkness when needed.
          </template>
        </category-faq>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryFaq from '~/components/shared/CategoryFaq'
export default {
  components: {
    CategoryFaq
  }
}
</script>
