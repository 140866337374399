<template>
  <div class="section-faq">
    <div class="container-1000">
      <div class="section-tag-container">
        <div class="section-tag">
          Schuette.shop FAQ générale
        </div>
      </div>
      <h2 class="text-center">
        Schuette.shop - Questions fréquemment posées
      </h2>
      <div class="category-faq-container">
        <category-faq collapsable>
          <template #question>
            Que signifie Schuette®?
          </template>
          <template #answer>
            Notre manufacture familiale offre bien plus que des protections solaires, des pare-vues et des accessoires d'intérieur. Notre mission principale est de vous redonner le sourire, d’augmenter votre confort et d’améliorer votre confort de vie. Avec les produits Schuette®, vous obtenez non seulement des produits pour votre maison, mais bien plus encore ! Essayez-le et ressentez la différence. ♥︎
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Puis-je obtenir des conseils si je ne suis pas sûr de choisir le meilleur revêtement de fenêtre?
          </template>
          <template #answer>
            Bien sûr que oui. Si vous n'êtes pas sûr de savoir si un store plissé, un store plissé en nid d'abeille, un store enrouleur ou un rideau est le meilleur choix pour vos fenêtres, notre équipe d'experts se fera un plaisir de vous aider ! Envoyez-nous simplement des photos de vos fenêtres et dites-nous si vous êtes autorisé à percer ou non et, si nécessaire, quel effet souhaité vous intéresse le plus : translucide, légèrement obscurcissant, obscurcissant complètement, contre la chaleur et le froid, etc. Nous serons ravis de vous répondre à tout moment !
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Comment puis-je contacter le service clientèle?
          </template>
          <template #answer>
            Notre service clientèle peut être contacté par mail à l'adresse emailfuerdich@schuette.shop ou via notre système de messagerie sur la page d'accueil.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Tous les produits Schuette® sont-ils opaques?
          </template>
          <template #answer>
            Oui. Que vous optiez pour un store plissé, un store enrouleur ou un rideau, tous les produits Schuette® sont toujours 100 % opaques et certains bloquent également complètement la lumière du soleil et contribuent à assombrir complètement les pièces.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Quel tissu choisir?
          </template>
          <template #answer>
            Le choix du bon tissu peut être fait en fonction de la couleur, du design et des propriétés du tissu. Chaque type de tissu et de design présente ses propres avantages. Les propriétés des matériaux varient parfois en fonction du choix de couleur. Vous trouverez plus d'informations sur les propriétés respectives des matériaux sur notre page produit.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Qu'est-ce que le revêtement argenté des produits Thermo?
          </template>
          <template #answer>
            L'enduction sur l'envers (côté fenêtre) de nos tissus thermiques réfléchit les rayons du soleil et ralentit entre autres nettement la pénétration de la chaleur. Une propriété supplémentaire de l'enduction brillante est un assombrissement moyen à élevé de la pièce. Pour les couleurs de tissu foncées, la couleur propre du traitement anti-chaleur sur l'envers du tissu est argentée, mais pour les couleurs claires, il s'agit plutôt d'une teinte beige.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Puis-je commander des échantillons de tissus?
          </template>
          <template #answer>
            Avec plaisir. Des échantillons de tissus peuvent être commandés via notre service client.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Puis-je simplement commander des tissus ou des profilés?
          </template>
          <template #answer>
            Cela dépend de la situation. Il est possible de commander des échantillons de tissus et des profilés de guidage supplémentaires pour les fenêtres de toit. En cas de besoin, notre service clientèle peut être contacté à cet effet. En outre, il est possible de commander à tout moment des supports pour stores plissés et enrouleurs comme accessoires sur notre site Internet. Il n'est pas possible de commander séparément des pans de tissu entiers, des rails profilés ou des œillets pour rideaux.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Quelles sont les conditions de renvoi?
          </template>
          <template #answer>
            Les conditions de retour varient en fonction du type de produit. Tous les produits sur mesure, c'est-à-dire les produits fabriqués sur mesure, ne peuvent pas être retournés ou échangés, car ils sont fabriqués individuellement et spécialement pour chaque client directement après la commande passée. Les produits qui ne sont pas sur mesure peuvent être retournés dans le délai de retour, à condition qu'ils soient en parfait état. Le type de produit concerné est décrit dans le titre du produit. Vous trouverez des informations plus détaillées sur nos conditions de retour dans nos conditions générales de vente ainsi que dans les informations sur le droit de rétractation.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Est-il possible de voir les produits Schuette® avant de les acheter?
          </template>
          <template #answer>
            Pour l'instant, nous n'avons pas encore de showrooms ou de boutiques fixes avec nos produits Schuette®. C'est pourquoi nous avons rendu les photos des produits d'autant plus réalistes, afin que vous puissiez être sûr d'obtenir exactement le produit que vous voyez sur notre site. En outre, des échantillons de tissus peuvent être commandés auprès de notre service clientèle si nécessaire.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Quand débute la fabrication de ma commande?
          </template>
          <template #answer>
            Afin d'assurer l'expédition la plus rapide possible de tous les produits (y compris les produits sur mesure), tous les produits Schuette® sont fabriqués pour nos clients immédiatement après la passation de la commande. Certaines étapes de préparation et de traitement sont même entièrement automatisées et commencent immédiatement après la réception de la commande. Nous pouvons donc affirmer avec fierté que la majorité de toutes les commandes sont livrées avant la date de livraison indiquée.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Des outils sont-ils nécessaires pour assembler les produits Schuette®?
          </template>
          <template #answer>
            Cela dépend de la méthode d'installation sélectionnée. Aucun outil n'est requis pour l'assemblage sans perçage. Si vous décidez d'installer des stores plissés ou des stores enrouleurs par perçage, vous n'aurez besoin que d'un tournevis. Si vous avez en plus une visseuse sans fil et de petites mèches (⌀ 1,0 -2,0 mm) à disposition pour le montage de stores plissés "avec perçage", cela sera encore plus rapide et plus facile.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            De combien de temps ai-je besoin pour le montage?
          </template>
          <template #answer>
            Cela dépend du produit choisi et de la méthode de montage correspondante. Il était extrêmement important pour nous de rendre le montage de chaque produit, quel que soit le type de montage, aussi simple et rapide que possible pour vous. Puisque tous nos produits sont déjà assemblés du mieux possible, l’assemblage/l’installation peut être complété en un rien de temps. Nous avons particulièrement veillé à ce que vous n'ayez pas à faire de casse-tête et à ce que vos produits Schuette® puissent être installés le plus rapidement possible. Le montage lui-même est très simple et ne prend que quelques minutes.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Où puis-je trouver les instructions de montage ?
          </template>
          <template #answer>
            Les instructions de montage sont incluses avec chaque produit. Vous pouvez les trouver dans l'emballage à proximité des poignées et des supports. Si vous avez besoin d'une copie supplémentaire, veuillez contacter notre service client. Ils se feront un plaisir de vous envoyer un fichier PDF par email.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Dois-je acheter des supports ou d'autres accessoires individuellement?
          </template>
          <template #answer>
            Ce n'est pas nécessaire. Tous les supports de stores plissés et enrouleurs ainsi que les autres pièces détachées nécessaires au montage sont toujours inclus dans le produit commandé. Toutefois, pour les rideaux avec bande tunnel, les crochets ou les patins doivent être achetés séparément, par exemple dans une quincaillerie.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Schuette® propose-t-elle également un service de montage?
          </template>
          <template #answer>
            Un service de montage n'est pas nécessaire, car le montage de nos produits est un jeu d'enfant et aucune aide professionnelle n'est nécessaire. Tous les produits, s'ils sont mesurés selon les instructions de mesure, sont déjà préparés par nos soins, de sorte que rien n'a besoin d'être réajusté et que seuls les supports et les poignées doivent être fixés. Aucune aide professionnelle d'une équipe de montage n'est requise pour cela, ce qui permet également d'économiser des coûts importants !
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Schuette® dispose-t-il d'un service de réparation?
          </template>
          <template #answer>
            Oui, nous avons mis en place un service de réparation pour les cas où les plissés ou les stores doivent être adaptés ou raccourcis, ou si quelque chose s'est mal passé lors du montage. Pour ce faire, veuillez contacter notre service d'assistance clientèle par e-mail ou par chat.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Que puis-je faire si je me suis trompé dans les mesures ?
          </template>
          <template #answer>
            Selon la manière dont vous vous êtes trompé, ce n'est pas grave. Pour les stores plissés, il suffit parfois de remplacer les fixations par d'autres. Si cela ne suffit pas, nous disposons d'un service de réparation qui nous permet de procéder à de petites modifications des dimensions. Vous vous êtes trompé de mesure ? Dans ce cas, contactez notre service clientèle et indiquez votre numéro de commande et les produits concernés ainsi que les nouvelles mesures souhaitées.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Combien de temps prend la livraison?
          </template>
          <template #answer>
            Le délai de livraison pour chaque produit est indiqué sur la page du produit ainsi que dans le panier d'achat. En règle générale, nous expédions les commandes importantes dans un délai de 5 à 10 jours ouvrables. Si vous avez des exigences particulières en matière de délai de livraison, n'hésitez pas à nous contacter.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Combien coûte l'expédition?
          </template>
          <template #answer>
            La livraison est gratuite en Allemagne et en Autriche. Les frais de port pour les autres pays de livraison sont affichés dans le panier dès que le pays de livraison a été sélectionné.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Comment puis-je vérifier l'état d'expédition de ma commande?
          </template>
          <template #answer>
            Dans les jours qui suivent votre commande, nous envoyons à l'adresse e-mail indiquée dans la commande un e-mail contenant le numéro de l'envoi, l'entreprise de livraison correspondante ainsi que le lien pour suivre l'envoi. Ces informations permettent de vérifier à tout moment le statut de l'envoi.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Quels moyens de paiement sont disponibles pour moi ?
          </template>
          <template #answer>
            <p>
              Tous les modes de paiement disponibles sont affichés dans le panier. Les modes de paiement suivants s'offrent à vous:
            </p>
            <div class="check-list-container">
              <ul class="check-list is-fullwidth">
                <li><b>Paiement anticipé/virement bancaire</b></li>
                <li><b>Virement bancaire instantané</b></li>
                <li><b>Carte de crédit</b></li>
                <li><b>PayPal</b></li>
                <li><b>Klarna Pay Later achat sur compte</b></li>
                <li><b>Klarna Slice It achat à tempérament</b></li>
                <li><b>Giropay</b></li>
                <li><b>EPS</b></li>
              </ul>
            </div>
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Quand et comment vais-je recevoir ma facture?
          </template>
          <template #answer>
            Vous recevrez la facture pro forma par email en pièce jointe à la confirmation de commande. La facture définitive sera envoyée après réception du paiement dans la confirmation de paiement. Si vous n'avez pas reçu de facture, veuillez contacter notre service client.
          </template>
        </category-faq>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryFaq from '~/components/shared/CategoryFaq'
export default {
  components: {
    CategoryFaq
  }
}
</script>
