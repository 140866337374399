export default [
  {
    question: 'Für was steht Schuette®?',
    answer:
      'Unsere Familienmanufaktur bietet viel mehr als nur Fensterabdeckungen und Wohnaccessoires. Wir haben es uns zur Hauptaufgabe gemacht, Ihnen ein Lächeln aufs Gesicht zu zaubern, Ihren Komfort zu erhöhen und Ihre Lebensqualität zu steigern. Mit Schuette® Produkten erhalten Sie daher nicht nur Produkte für Ihr Zuhause, sondern deutlich mehr! Einfach ausprobieren und Unterschied spüren. ♥︎'
  },
  {
    question:
      'Kann ich mich beraten lassen, wenn ich mir bei der Wahl der besten Fensterabdeckung nicht sicher bin?',
    answer:
      'Ja. Wenn Sie sich nicht sicher sind, ob ein Plissee, Rollo oder ein Vorhang die beste Wahl für Ihre Fenster wäre, hilft Ihnen unser Experten-Team jederzeit sehr gerne weiter! Schicken Sie uns einfach Bilder Ihrer Fenster zu und sagen uns, ob Sie Bohren dürfen oder nicht sowie bei Bedarf welcher gewünschte Effekt Sie am meisten interessiert: lichtdurchlässig, leicht verdunkelnd, vollständig verdunkelnd, gegen Hitze- und Kälte usw. Wir freuen uns jederzeit von Ihnen zu hören!'
  },
  {
    question: 'Wie kann ich den Kundenservice kontaktieren?',
    answer:
      'Unser Kundenservice kann per Mail unter emailfuerdich@schuette.shop oder über unser Nachrichtensystem auf der Homepage kontaktiert werden.'
  },
  {
    question: 'Sind alle Schuette® Produkte blickdicht?',
    answer:
      'Ja. Egal ob die Wahl auf ein Plissee, ein Rollo oder einen Vorhang fällt, alle Schuette® Produkte sind immer zu 100% blickdicht.'
  },
  {
    question: 'Welchen Stoff sollte ich wählen?',
    answer:
      'Die Auswahl des richtigen Stoffes kann in Hinblick auf Farbe, Design sowie die Eigenschaften des Stoffes getroffen werden. Jede Stoffart und jedes Design hat ihre eigenen Vorteile. Die Stoffeigenschaften variieren manchmal auch je nach Farbwahl. Näheres zu den jeweiligen Stoffeigenschaften finden Sie auf unserer Produktseite.'
  },
  {
    question: 'Was ist die silberne Beschichtung bei Thermo Produkten?',
    answer:
      'Die silberne Beschichtung auf der Rückseite (Fensterseite) unserer Thermo-Stoffe reflektiert die Sonnenstrahlen und verlangsamt unter anderem deutlich das Eindringen von Hitze. Eine zusätzliche Eigenschaft der silberglänzenden Beschichtung ist eine mittlere bis hohe Verdunkelung des Raums.'
  },
  {
    question: 'Kann ich Stoffmuster bestellen?',
    answer:
      'Ja. Stoffmuster können über unseren Kundenservice bestellt werden.'
  },
  {
    question: 'Kann ich auch nur Stoffe oder Profile bestellen?',
    answer:
      'Das kommt darauf an. Es ist möglich, Muster von Stoffen sowie zusätzliche Dachfenster-Führungsprofile nachzubestellen. Bei Bedarf kann hierfür unser Kundenservice kontaktiert werden. Zudem können jederzeit Plissee- und Rollo-Halterungen über unsere Homepage nachbestellt werden. Ganze Stoffbahnen, Profilschienen sowie Vorhang-Ösen können nicht separat bestellt werden.'
  },
  {
    question: 'Was sind die Rückgabebedingungen?',
    answer:
      'Die Rückgabebedingungen variieren je nach Produktart. Alle Produkte nach Maß, d.h. Maßanfertigungen, können nicht zurückgegeben oder umgetauscht werden, da diese individuell und speziell für jeden Kunden direkt nach aufgegebener Bestellung angefertigt werden. Produkte, die nicht auf Maß sind, können innerhalb der Rückgabefrist zurückgegeben werden, sofern diese sich in einem einwandfreien Zustand befinden. Die jeweilige Produktart ist im Titel der Produkte beschrieben. Genaueres zu unseren Rückgabebedingungen finden Sie in unseren AGBs sowie in der Widerrufsbelehrung.'
  },
  {
    question: 'Wo befindet sich die Schuette® Manufaktur?',
    answer:
      'Unsere Schuette® Familienmanufaktur befindet sich im Herzen Europas und somit nicht, wie der Großteil anderer Hersteller, außerhalb der EU wie z.B. in Asien. Lokale Materialien, lokale Produktion, lokale Lieferung für eine nachhaltige Zukunft! ♥︎'
  },
  {
    question:
      'Gibt es eine Möglichkeit Schuette® Produkte vor dem Kauf stationär anzusehen?',
    answer:
      'Momentan haben wir noch keine Ausstellungsräume oder stationäre Shops mit unseren Schuette® Produkten. Daher haben wir die Produktbilder umso realitätsgetreuer gestaltet, sodass Sie sicher sein können, dass Sie exakt das Produkt erhalten, welches Sie auf unserer Homepage sehen. Zusätzlich können bei Bedarf Stoffmuster über unseren Kundenservice bestellt werden.'
  },
  {
    question: 'Wann beginnt die Anfertigung meiner Bestellung?',
    answer:
      'Damit wir einen schnellstmöglichen Versand aller Produkte (maßgefertigte Produkte inbegriffen) gewährleisten können, werden alle Schuette® Produkte direkt nachdem die Bestellung aufgegeben wurde für unsere Kunden angefertigt. Wir können daher stolz sagen, dass der Großteil aller Bestellungen somit noch vor dem angezeigten Liefertermin zugestellt wird.'
  },
  {
    question:
      'Werden für die Montage von Schuette® Produkten irgendwelche Werkzeuge benötigt?',
    answer:
      'Dies kommt auf die ausgewählte Montagemethode an. Für eine Montage ohne Bohren werden keine Werkzeuge benötigt. Sofern Sie sich für eine Montage mit Bohren von Plissees oder Rollos entscheiden, reicht lediglich ein Schraubenzieher aus. Wenn Sie zusätzlich einen Akkuschrauber parat haben, wird es nochmal schneller gehen.'
  },
  {
    question: 'Wie viel Zeit benötige ich für die Montage?',
    answer:
      'Dies kommt auf das gewählte Produkt und die dazugehörige Montagemethode an. Es war für uns außerordentlich wichtig, die Montage von jedem Produkt, egal mit welcher Montageart, so einfach und schnell wie möglich für Sie zu gestalten. Da alle unsere Produkte bereits so gut wie möglich fertig montiert sind, ist die Montage/Installation im Handumdrehen vollbracht. Wir haben dabei besonders darauf geachtet, dass Sie keine Puzzlearbeit leisten müssen und Ihre Schuette® Produkte schnellstmöglich anzubringen sind. Die Montage selbst ist kinderleicht und dauert lediglich wenige Minuten.'
  },
  {
    question: 'Wo finde ich die Montageanleitung?',
    answer:
      'Die Montageanleitung wird zu jedem Produkt beigelegt. Diese finden Sie in der Verpackung bei den Griffen und Halterungen. Sollten Sie eine zusätzliche Kopie benötigen, kontaktieren Sie bitte unseren Kundenservice. Dieser sendet Ihnen gerne eine PDF-Datei per Mail zu.'
  },
  {
    question: 'Muss ich Halterungen oder anderes Zubehör einzeln kaufen?',
    answer:
      'Nein. Alle Plissee- und Rollo-Halterungen sowie weitere zur Montage benötigte Einzelteile liegen dem bestellten Produkt immer bei. Für Vorhänge mit Tunnelband müssen jedoch die Haken oder Gleiter separat, beispielsweise im Baumarkt, erworben werden.'
  },
  {
    question: 'Bietet Schuette® ebenfalls einen Montageservice an?',
    answer:
      'Nein, da die Montage von unseren Produkten kinderleicht ist und hierfür keinerlei professionelle Hilfe benötigt wird. Alle Produkte sind, sofern nach Messanleitung gemessen wurde, bereits so von uns vorbereitet, dass nichts nachjustiert werden muss und lediglich die Halterungen und Griffe angebracht werden müssen. Hierfür wird keine professionelle Hilfe von einem Montageteam benötigt, was zusätzlich deutlich Kosten spart!'
  },
  {
    question: 'Hat Schuette® einen Reparaturservice?',
    answer:
      'Ja. Sofern Sie längere Schnüre benötigen, bei der Montage etwas schief gegangen ist oder ein anderes Malheur vorgefallen ist, helfen wir Ihnen sehr gerne weiter. Bitte kontaktieren Sie hierfür unseren Kundensupport per Mail oder Chat.'
  },
  {
    question: 'Was kann ich tun, wenn ich mich vermessen habe?',
    answer:
      'Je nachdem, inwiefern Sie sich vermessen haben, ist dies nicht weiter schlimm. Manchmal reicht es sogar aus, lediglich die Halterungen gegen andere zu tauschen. Sofern Sie sich vermessen haben, kontaktieren Sie bitte unseren Kundenservice und nennen Ihre Bestellnummer, die betroffenen Maße sowie die neuen benötigten Maße.'
  },
  {
    question: 'Wie lange dauert der Versand?',
    answer:
      'Die Lieferzeit für das jeweilige Produkt wird auf der Produktseite sowie im Warenkorb angezeigt.'
  },
  {
    question: 'Wie viel kostet der Versand?',
    answer:
      'Der Versand ist innerhalb Deutschlands sowie nach Österreich kostenfrei. Die Versandkosten für andere Lieferländer werden im Warenkorb angezeigt, sobald das Lieferland ausgewählt wurde.'
  },
  {
    question: 'Wie kann ich den Sendungsstatus meiner Bestellung prüfen?',
    answer:
      'An die in der Bestellung genannte Mailadresse erhalten Sie innerhalb weniger Tage nach Ihrer Bestellung eine Mail mit der Sendungsnummer, dem dazugehörigen Lieferunternehmen sowie dem Link zur Sendungsverfolgung. Anhand dieser Informationen kann der Sendungsstatus jederzeit geprüft werden.'
  },
  {
    question: 'Welche Zahlungsmethoden stehen mir zur Verfügung?',
    answer:
      'Alle verfügbaren Zahlungsmethoden werden im Warenkorb angezeigt. Ihnen stehen die folgenden Zahlungsweisen zur Verfügung:\n\n- Vorkasse/Banküberweisung\n- Sofort-Überweisung\n- Kreditkarte\n- PayPal\n- Klarna Pay Later Kauf auf Rechnung\n- Klarna Slice It Ratenkauf\n- Giropay\n- EPS'
  },
  {
    question: 'Wann und wie erhalte ich meine Rechnung?',
    answer:
      'Die Proforma-Rechnung erhalten Sie per Mail als Anhang zur Bestellbestätigung. Die finale Rechnung wird nach Eingang der Zahlung in der Zahlungsbestätigung versendet. Sollten Sie keine Rechnung erhalten haben, kontaktieren Sie bitte unseren Kundenservice.'
  }
]
