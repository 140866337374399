<template>
  <div>
    <div class="intro-container">
      <div class="container-1000">
        <div class="intro-section">
          <h1 class="text-center">
            Schuette® Stores plissés, stores enrouleurs, rideaux à tomber amoureux de votre maison !
          </h1>

          <div class="grid-1-2-1 category-intro-photos category-intro-videos">
            <div class="grid-item">
              <!-- <lazy-video :video="{video: RelaxVideoTwo}" alt="willkommen" /> -->
              <b-image
                src="https://img.schuette.shop/schuette/categories/rollos/schuette-fabrics-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/categories/rollos/schuette-fabrics-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="rollos"
              />
            </div>
            <div class="grid-item">
              <lazy-video :video="{video: WelcomeVideoTwo}" alt="willkommen" />
              <!-- <div class="grid-item-content">
                <div class="category-link-new">
                  {{ $t('project.landingPage.new') }}
                </div>
                <a :href="getSLLink">
                  <b-button type="is-primary" class="cta-primary" expanded rounded icon-right="arrow-right-bold">
                    {{ $t('project.landingPage.browseAll') }}
                  </b-button>
                </a>
              </div> -->
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/categories/pleated-blinds/schuette-fabrics-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/categories/pleated-blinds/schuette-fabrics-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="plissee"
              />
            </div>
          </div>

          <div class="check-list-container">
            <ul class="check-list is-big">
              <li><b>Facile</b> - Configurez et commandez vos stores plissés directement en ligne.</li>
              <li><b>Simple</b> - Choisissez le type de montage, le tissu, le profil et la taille en quelques secondes.</li>
              <li><b>Service</b> - Chat, e-mail ou directement chez vous. Nous sommes toujours là pour vous !</li>
              <li><b>Rapide</b> - Voyez directement sur la page des produits du store plissé quand nous livrons.</li>
              <li><b>Transparent</b> - Tous les prix sont directement visibles !</li>
            </ul>
          </div>

          <h2 class="text-center">
            Bienvenue chez Schuette® Manufacture de stores plissés et stores enrouleurs
          </h2>

          <div class="text-container">
            <p class="text-center">
              Nous voulons tout simplement vous dire merci ! Merci de votre soutien, de votre appréciation de notre travail et du cœur que nous mettons dans le développement et la production de vos stores plissés, stores enrouleurs et rideaux.
            </p>
            <p class="text-center">
              Vous nous donnez une force incroyable et nous souhaitons vous remercier chaleureusement en vous offrant les meilleurs produits possibles, fabriqués avec passion !
            </p>
          </div>

          <categories v-if="page" :categories="page.navigation" home full>
            <template #features="{identifier}">
              <!-- plisy -->
              <template v-if="identifier === 'plisy'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                    <drilling-icon />
                    <no-drilling-icon />
                  </div>
                  <div class="feature-description">
                    Montage rapide en 60s (Avec ou sans percage)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    5 Collection & 74 couleurs
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Schuette® Promesse de qualitè
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    à partir de 27.99 EUR
                  </div>
                </div>
              </template>

              <!-- roletki -->
              <template v-if="identifier === 'roletki'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                    <drilling-icon />
                    <no-drilling-icon />
                  </div>
                  <div class="feature-description">
                    Montage rapide en 60s (Avec ou sans percage)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    4 Collection & 26 couleurs
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Schuette® Promesse de qualitè
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    à partir de 9.99 EUR
                  </div>
                </div>
              </template>

              <!-- zaslony -->
              <template v-if="identifier === 'zaslony'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                  </div>
                  <div class="feature-description">
                    Montage rapide en 60s
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    2 Collection & 30 couleurs
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Schuette® Promesse de qualitè
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    à partir de 42.99 EUR
                  </div>
                </div>
              </template>
            </template>
          </categories>
        </div>
      </div>
    </div>
    <div class="company-container">
      <div class="container-1000">
        <div class="company-section">
          <h2>
            Cher client ❤️
          </h2>
          <p>
            Merci d'avoir pris le temps d'en savoir plus sur nous et nos produits.
          </p>
          <p class="font-semibold">
            Les stores plissés, stores enrouleurs et systèmes de protection solaire Schuette® sont fabriqués localement dans le respect des savoir-faire les plus stricts et à des salaires équitables, ainsi qu'à partir de matériaux solides, durables et modernes.
          </p>
          <p>
            Nous sommes une petite manufacture familiale et nous utilisons, développons et vivons nos produits jour après jour. Notre petite équipe met tout en œuvre pour que nos stores plissés et nos rideaux répondent non seulement aux normes les plus élevées de l'artisanat, mais aussi et surtout aux exigences de votre maison, car nous comprenons ce que signifie « maison » et ce qu'elle représente pour vous. Notre objectif est d'améliorer la qualité de vie grâce à un design parfait et à une fonction supérieure à la fenêtre.
          </p>
          <div class="grid-3n category-intro-photos">
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-company-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-company-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette®"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-company-2.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-company-2.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette®"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-company-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-company-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette®"
              />
            </div>
          </div>
          <h2 class="text-center">
            Schuette® La promesse de qualité
          </h2>
          <p>
            Nous sommes particulièrement fiers de développer nous-mêmes chaque petite pièce de tous nos produits Schuette® et de maîtriser ainsi l'ensemble de la fabrication. Cela nous permet de maintenir des normes de qualité strictes à chaque étape.
          </p>
          <p>
            L'accent est mis sur la résistance et la longévité exceptionnelles de tous les composants ainsi que sur la simplicité d'utilisation chez vous. Nous soulignons tout cela avec notre authentique promesse de qualité Schuette® :
          </p>
          <div class="check-list-container">
            <ul class="check-list is-big">
              <li><b>Facile à utiliser</b> - Grâce à des fixations adaptées à chaque fenêtre</li>
              <li><b>Durabilité prouvée</b> - Testé 100.001 fois dans différentes configurations et dans toutes les conditions climatiques</li>
              <li><b>Facile à entretenir</b> - pour un « après » sans soucis</li>
              <li><b>Protection UV</b> - Pour éviter la décoloration des tissus et de vos meubles</li>
              <li><b>Toujours à jour</b> - en mettant l'accent sur le développement et l'optimisation constants</li>
              <li><b>Un service complet</b> - toujours là pour vous, peu importe le moment et la manière.</li>
            </ul>
          </div>
          <div class="grid-1-2-1">
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Vorhänge"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-2.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-2.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Plissee"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Rollos"
              />
            </div>
          </div>

          <h2 class="text-center ">
            Schuette® - L'original
          </h2>
          <p class="font-semibold is-big">
            Chers clients,
          </p>
          <p>
            <b>Vous ne pouvez pas copier ce que nous faisons</b>. Nos produits Schuette® sont le résultat d'années de dévouement aux détails et du développement continu de nos technologies uniques.
          </p>
          <p>
            Malgré les caractéristiques indubitables qui font des produits Schuette® des originaux incomparables, de plus en plus d'imitateurs tentent d'imiter notre succès. Nous essayons de voir comme un compliment le fait que notre travail acharné soit une source d'inspiration pour les autres.
          </p>
          <p>
            Néanmoins, ils doivent comprendre qu'il ne suffit pas d'utiliser l'étiquette "Premium" ou de copier nos images et nos designs. Il ne suffit pas non plus de faire produire nos supports haut de gamme en Chine dans une qualité inférieure et de les déclarer ensuite "Made in Europe".
          </p>
          <p>
            Les contrefacteurs tentent de vendre des produits de qualité inférieure sur le dos des PME et des manufactures familiales comme la nôtre.
          </p>
          <p>
            Mais une contrefaçon reste toujours une contrefaçon. L'original ne peut pas être reproduit, car la passion, l'énergie et le dévouement que nous mettons dans le développement, la production, le service et le contact direct avec vous sont uniques.
          </p>
          <p>
            <b> Pourquoi ?</b> Parce que nous vivons littéralement ce que nous créons jour après jour. Grâce à vous, les meilleurs clients du monde, nous sommes chaque jour plus motivés pour être encore plus assidus.
          </p>
          <p>
            Chers clients, continuez à miser sur la qualité lorsqu'il s'agit de votre maison. Misez sur nous, sur l'original, sur la manufacture familiale Schuette® et sur des personnes qui consacrent leur vie à créer des produits qui enrichissent votre maison.
          </p>

          <h2 class="text-center">
            En tant que petite entreprise familiale, nous savons ce que signifie « maison »
          </h2>
          <p>
            C'est pourquoi nous développons et fabriquons nos produits non seulement en respectant les normes de qualité les plus strictes en matière de fabrication et de matériaux, mais aussi et surtout pour répondre à vos normes et à vos souhaits en tant qu'utilisateur, car nous apprécions et respectons votre « maison ».
          </p>
          <div class="company-image">
            <b-image
              src="https://img.schuette.shop/schuette/homepage/fr/schuette-unique.jpg.webp"
              webp-fallback="https://img.schuette.shop/schuette/homepage/fr/schuette-unique.jpg"
              class="skeleton-image-placeholder is-hidden-mobile"
              lazy
              alt="Schuette Bedeutung"
            />
            <b-image
              src="https://img.schuette.shop/schuette/homepage/fr/schuette-unique-mobile.jpg.webp"
              webp-fallback="https://img.schuette.shop/schuette/homepage/fr/schuette-unique-mobile.jpg"
              class="skeleton-image-placeholder is-hidden-tablet mobile-unique"
              lazy
              alt="Schuette Bedeutung"
            />
          </div>
          <p>
            <b>Personne ne recherche des stores plissés de bricolage ou des stores chinois</b>,  mais malheureusement, ce type de produits a trop souvent atterri dans le panier de nombreux acheteurs par le passé, car les produits de meilleure qualité étaient souvent trop éloignés en termes de prix.
          </p>
          <p>
            <b>En tant que producteur, nous résolvons désormais ce problème en nous ouvrant à la vente directe aux clients finaux</b> et non exclusivement aux architectes, hôtels, restaurants et entreprises de montage renommées pour les systèmes de protection solaire, comme c'était le cas jusqu'à présent, et croyez-nous quand nous disons que cela nous procure une joie incroyable jour après jour ❤️
          </p>
          <p>
            Notre famille et notre équipe seraient honorées de voir nos produits jouer avec les rayons du soleil dans votre maison et de vous laisser enchanter par eux.
          </p>
          <p>
            <b>Cordialement,</b>
          </p>
          <div class="schuette-family">
            <logo-schuette class="family-logo" />Famille Schuette®
          </div>
        </div>
      </div>
    </div>
    <div class="products-highlights">
      <div class="container-1000">
        <h2 class="text-center">
          Parcourez tous les produits:
        </h2>
        <categories v-if="page" :categories="page.navigation" home />
      </div>
    </div>
  </div>
</template>

<script>
import LazyVideo from 'marketplace-front-core/components/shared/LazyVideo.vue'
import Categories from '../landing/Categories.vue'
import faq from './faq-fr.js'

import LogoSchuette from '~/static/images/logo-schuette.svg?inline'
import NoDrillingIcon from '~/assets/images/homepage/nodrilling-square.svg?inline'
import DrillingIcon from '~/assets/images/homepage/drilling-square.svg?inline'
import QuickIcon from '~/assets/images/homepage/quick-outline-square.svg?inline'
import CollectionsIcon from '~/assets/images/homepage/collections-outline-square.svg?inline'
import ColorsIcon from '~/assets/images/homepage/colors-outline-square.svg?inline'
import StrongIcon from '~/assets/images/homepage/strong-outline-square.svg?inline'
import CheckIcon from '~/assets/images/homepage/check-square.svg?inline'

import WelcomeVideoTwo from '~/static/images/schuette-welcome-video-2.mp4'

export default {
  name: 'HomePageEn',
  components: {
    LazyVideo,
    Categories,
    NoDrillingIcon,
    DrillingIcon,
    QuickIcon,
    CollectionsIcon,
    ColorsIcon,
    StrongIcon,
    CheckIcon,
    LogoSchuette
  },
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      WelcomeVideoTwo
    }
  },
  head () {
    return {
      script: [{ type: 'application/ld+json', json: this.getFaqSchemaOrg }]
    }
  },
  computed: {
    getFaqSchemaOrg () {
      const entity = faq.map((x) => {
        return {
          '@type': 'Question',
          name: x.question.replace(/["]+/g, '&quot;'),
          acceptedAnswer: {
            '@type': 'Answer',
            text: x.answer.replace(/["]+/g, '&quot;')
          }
        }
      })
      const schema = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [...entity]
      }
      return schema
    }
  }
}
</script>
