export default [
  {
    question: 'Que signifie Schuette®?',
    answer:
      "Notre manufacture familiale offre bien plus que des couvre-fenêtres et des accessoires pour la maison. Notre mission principale est de vous redonner le sourire, d'augmenter votre confort et d'améliorer votre qualité de vie. Avec les produits Schuette®, vous obtenez non seulement des produits pour votre maison, mais bien plus encore ! Essayez-le et ressentez la différence. ♥︎"
  },
  {
    question:
      'Puis-je obtenir des conseils si je ne suis pas sûr de choisir le meilleur revêtement de fenêtre?',
    answer:
      "Oui. Si vous ne savez pas si un store plissé, un store enrouleur ou un rideau serait le meilleur choix pour vos fenêtres, notre équipe d'experts se fera un plaisir de vous aider à tout moment ! Envoyez-nous simplement des photos de vos fenêtres et dites-nous si vous êtes autorisé à percer ou non et, le cas échéant, quel effet souhaité vous intéresse le plus : translucide, légèrement obscurcissant, obscurcissant complètement, contre la chaleur et le froid, etc. Nous serons ravis de vous répondre à tout moment!"
  },
  {
    question: 'Comment puis-je contacter le service clientèle?',
    answer:
      "Notre service clientèle peut être contacté par mail à l'adresse emailfuerdich@schuette.shop ou via notre système de messagerie sur la page d'accueil."
  },
  {
    question: 'Tous les produits Schuette® sont-ils opaques?',
    answer:
      'Oui. Que vous choisissiez un store plissé, un store enrouleur ou un rideau, tous les produits Schuette® sont toujours 100% opaques.'
  },
  {
    question: 'Quel tissu choisir?',
    answer:
      "Le choix du bon tissu peut être fait en fonction de la couleur, du design et des propriétés du tissu. Chaque type de tissu et de design présente ses propres avantages. Les propriétés des matériaux varient parfois en fonction du choix de couleur. Vous trouverez plus d'informations sur les propriétés respectives des matériaux sur notre page produit."
  },
  {
    question: "Qu'est-ce que le revêtement argenté des produits Thermo?",
    answer:
      "Le revêtement argenté au dos (côté fenêtre) de nos tissus thermiques reflète les rayons du soleil et ralentit, entre autres, considérablement la pénétration de la chaleur. Une propriété supplémentaire du revêtement argenté brillant est un moyen d'assombrissement élevé de la pièce."
  },
  {
    question: 'Puis-je commander des échantillons de tissus?',
    answer:
      'Oui. Des échantillons de tissus peuvent être commandés via notre service client.'
  },
  {
    question: 'Puis-je simplement commander des tissus ou des profilés?',
    answer:
      "Ça dépend. Il est possible de commander à nouveau des échantillons de tissu et des profilés de guidage de fenêtres de toit supplémentaires. En cas de besoin, notre service client peut être contacté. De plus, les supports de stores plissés et de stores enrouleurs peuvent être commandés à tout moment via notre page d'accueil. Les panneaux de tissu, les rails profilés et les œillets de rideaux entiers ne peuvent pas être commandés séparément."
  },
  {
    question: 'Quelles sont les conditions de renvoi?',
    answer:
      "Les conditions de retour varient en fonction du type de produit. Tous les produits sur mesure, c'est-à-dire les produits fabriqués sur mesure, ne peuvent pas être retournés ou échangés, car ils sont fabriqués individuellement et spécialement pour chaque client directement après la commande passée. Les produits qui ne sont pas sur mesure peuvent être retournés dans le délai de retour, à condition qu'ils soient en parfait état. Le type de produit concerné est décrit dans le titre du produit. Vous trouverez des informations plus détaillées sur nos conditions de retour dans nos conditions générales de vente ainsi que dans les informations sur le droit de rétractation."
  },
  {
    question: 'Où se trouve la manufacture Schuette®?',
    answer:
      "Notre manufacture familiale Schuette® est située au cœur de l'Europe et n'est donc pas, comme la plupart des autres fabricants, située en dehors de l'UE comme par exemple en Asie. Matériaux locaux, production locale, livraison locale pour un avenir durable! ♥︎"
  },
  {
    question:
      'Est-il possible de voir les produits Schuette® avant de les acheter?',
    answer:
      "Pour l'instant, nous n'avons pas encore de showrooms ou de boutiques fixes avec nos produits Schuette®. C'est pourquoi nous avons rendu les photos des produits d'autant plus réalistes, afin que vous puissiez être sûr d'obtenir exactement le produit que vous voyez sur notre site. En outre, des échantillons de tissus peuvent être commandés auprès de notre service clientèle si nécessaire."
  },
  {
    question: 'Quand débute la fabrication de ma commande?',
    answer:
      "Afin d'assurer l'expédition la plus rapide possible de tous les produits (y compris les produits sur mesure), tous les produits Schuette® sont fabriqués pour nos clients immédiatement après la passation de la commande. Nous pouvons donc affirmer avec fierté que la majorité de toutes les commandes sont livrées avant la date de livraison indiquée."
  },
  {
    question:
      'Des outils sont-ils nécessaires pour assembler les produits Schuette®?',
    answer:
      "Cela dépend de la méthode d'installation sélectionnée. Aucun outil n'est requis pour l'assemblage sans perçage. Si vous décidez d'installer des stores plissés ou des stores enrouleurs par perçage, vous n'aurez besoin que d'un tournevis. Si vous avez également une visseuse sans fil à portée de main, cela ira encore plus vite."
  },
  {
    question: 'De combien de temps ai-je besoin pour le montage?',
    answer:
      "Cela dépend du produit choisi et du mode d'assemblage associé. Il était extrêmement important pour nous de rendre le montage de chaque produit, quel que soit le type de montage, aussi simple et rapide que possible pour vous. Puisque tous nos produits sont déjà assemblés du mieux possible, l'assemblage/l'installation peut être complété en un rien de temps. Nous avons particulièrement veillé à ce que vous n'ayez pas à faire de casse-tête et à ce que vos produits Schuette® puissent être installés le plus rapidement possible. Le montage lui-même est très simple et ne prend que quelques minutes."
  },
  {
    question: 'Où puis-je trouver les instructions de montage ?',
    answer:
      "Les instructions de montage sont incluses avec chaque produit. Vous pouvez les trouver dans l'emballage à proximité des poignées et des supports. Si vous avez besoin d'une copie supplémentaire, veuillez contacter notre service client. Ils se feront un plaisir de vous envoyer un fichier PDF par email."
  },
  {
    question:
      "Dois-je acheter des supports ou d'autres accessoires individuellement?",
    answer:
      'Non. Tous les supports de stores plissés et enrouleurs ainsi que les autres pièces détachées nécessaires au montage sont toujours inclus dans le produit commandé. Toutefois, pour les rideaux avec bande tunnel, les crochets ou les patins doivent être achetés séparément, par exemple dans une quincaillerie.'
  },
  {
    question: 'Schuette® propose-t-elle également un service de montage?',
    answer:
      "Non, car le montage de nos produits est un jeu d'enfant et aucune aide professionnelle n'est nécessaire. Tous les produits, s'ils sont mesurés selon les instructions de mesure, sont déjà préparés par nos soins, de sorte que rien n'a besoin d'être réajusté et que seuls les supports et les poignées doivent être fixés. Aucune aide professionnelle d'une équipe de montage n'est requise pour cela, ce qui permet également d'économiser des coûts importants!"
  },
  {
    question: "Schuette® dispose-t-il d'un service de réparation?",
    answer:
      "Oui. Si vous avez besoin de cordons plus longs, si quelque chose s'est mal passé lors du montage ou si un autre incident s'est produit, nous serons heureux de vous aider. Veuillez contacter notre service client par e-mail ou par chat."
  },
  {
    question: 'Que puis-je faire si je me suis trompé dans les mesures ?',
    answer:
      "Selon la manière dont vous vous êtes trompé, ce n'est pas grave. Parfois, il suffit même de remplacer les supports par d'autres. Si vous vous êtes trompé, veuillez contacter notre service clientèle et indiquer votre numéro de commande, les dimensions concernées et les nouvelles dimensions nécessaires."
  },
  {
    question: 'Combien de temps prend la livraison?',
    answer:
      'Le délai de livraison du produit concerné est affiché sur la page du produit et dans le panier.'
  },
  {
    question: "Combien coûte l'expédition?",
    answer:
      'La livraison est gratuite en Allemagne et en Autriche. Les frais de port pour les autres pays de livraison sont affichés dans le panier dès que le pays de livraison a été sélectionné.'
  },
  {
    question: "Comment puis-je vérifier l'état d'expédition de ma commande?",
    answer:
      "Quelques jours après avoir passé votre commande, vous recevrez un email avec le numéro de suivi, le transporteur associé et le lien de suivi de l'envoi vers l'adresse email indiquée dans la commande. Le statut de l'expédition peut être vérifié à tout moment grâce à ces informations."
  },
  {
    question: 'Quels moyens de paiement sont disponibles pour moi ?',
    answer:
      "Tous les modes de paiement disponibles sont affichés dans le panier. Les modes de paiement suivants s'offrent à vous:\n\n- Paiement anticipé/virement bancaire\n- Virement bancaire instantané\n- Carte de crédit\n- PayPal\n- Klarna Pay Later achat sur compte\n- Klarna Slice It achat à tempérament\n- Giropay\n- EPS"
  },
  {
    question: 'Quand et comment vais-je recevoir ma facture?',
    answer:
      "Vous recevrez la facture pro forma par email en pièce jointe à la confirmation de commande. La facture définitive sera envoyée après réception du paiement dans la confirmation de paiement. Si vous n'avez pas reçu de facture, veuillez contacter notre service client."
  }
]
