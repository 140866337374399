<template>
  <div class="section-faq">
    <div class="container-1000">
      <div class="section-tag-container">
        <div class="section-tag">
          FAQ generales
        </div>
      </div>
      <h2 class="text-center">
        Schuette.shop - Preguntas más frecuentes
      </h2>
      <div class="category-faq-container">
        <category-faq collapsable>
          <template #question>
            ¿Qué es Schuette®?
          </template>
          <template #answer>
            Nuestra fábrica familiar ofrece mucho más que protección solar e intimidad y accesorios para el hogar. Hemos convertido en nuestra principal tarea poner una sonrisa en su cara, aumentar su comodidad y mejorar su calidad de vida. Con los productos Schuette®, no sólo obtendrá productos para su hogar, ¡sino mucho más! Simplemente pruébelos y sienta la diferencia. ♥︎
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Puedo obtener asesoramiento si no estoy seguro de cuáles son los mejores revestimientos para ventanas?
          </template>
          <template #answer>
            Sí, si no está seguro de si un estor plisado, un estor enrollable o una cortina son la mejor opción para sus ventanas, nuestro equipo de expertos estará encantado de ayudarle en cualquier momento. Sólo tiene que enviarnos fotos de sus ventanas e indicarnos si puede taladrar o no y, en caso necesario, qué efecto desea: translúcido, ligeramente oscurecedor, completamente oscurecedor, contra el calor y el frío, etc. Estaremos encantados de atenderle.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Cómo puedo ponerme en contacto con el servicio de atención al cliente?
          </template>
          <template #answer>
            Puede ponerse en contacto con nuestro servicio de atención al cliente por correo electrónico en emailfuerdich@schuette.shop o a través de nuestro sistema de mensajería en la página de inicio.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Son opacos todos los productos Schuette®?
          </template>
          <template #answer>
            Sí, independientemente de si elige un estor plisado, enrollable o cortina, todos los productos Schuette® son siempre 100% opacos y algunos también impiden el paso de la luz solar y ayudan a oscurecer completamente las habitaciones.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Qué tejido debo elegir?
          </template>
          <template #answer>
            La elección del tejido adecuado puede hacerse en función del color, el diseño y las propiedades del tejido. Cada tipo de tejido y diseño tiene sus propias ventajas. A veces, las propiedades del tejido también varían en función del color elegido. Encontrará más información sobre las propiedades de cada tejido en nuestra página de productos.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Qué es el revestimiento plateado de los productos Thermo?
          </template>
          <template #answer>
            El revestimiento posterior (cara de la ventana) de nuestros tejidos Thermo refleja los rayos solares y, entre otras cosas, ralentiza considerablemente la penetración del calor. Una propiedad adicional del revestimiento brillante es un oscurecimiento medio o alto de la habitación. Con colores de tejido oscuros, el color inherente del acabado de protección térmica del reverso del tejido es plateado, pero con colores claros es más bien un tono beige.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Puedo pedir muestras de tejido?
          </template>
          <template #answer>
            Por su puesto, puede pedir muestras de tejido a través de nuestro servicio de atención al cliente.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Puedo pedir sólo tejidos o perfiles?
          </template>
          <template #answer>
            Depende. Es posible pedir muestras de tejido y perfiles guía para tragaluces adicionales. Si lo desea, póngase en contacto con nuestro servicio de atención al cliente. Los soportes para persianas plisadas y enrollables también pueden pedirse como accesorios en cualquier momento a través de nuestra página web.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Cuáles son las condiciones de devolución?
          </template>
          <template #answer>
            La política de devoluciones varía en función del tipo de producto. Todos los productos personalizados, es decir, hechos a medida, no pueden devolverse ni cambiarse, ya que se confeccionan de forma individual y específica para cada cliente directamente después de realizar el pedido. Los productos que no están hechos a medida pueden devolverse dentro del plazo de devolución, siempre que estén en perfectas condiciones. El tipo de producto correspondiente se describe en el título del producto. Encontrará información más detallada sobre nuestras condiciones de devolución en nuestras condiciones generales y en la política de cancelación.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Es posible ver los productos Schuette® en una sala de exposición antes de comprarlos?
          </template>
          <template #answer>
            Por el momento no disponemos de ninguna sala de exposición o tienda física con los productos Schuette®. Por eso hemos hecho que las imágenes de los productos sean lo más realistas posible, para que pueda estar seguro de que recibirá exactamente el producto que ve en nuestra página web. Además, si lo desea, puede solicitar muestras de tejido a través de nuestro servicio de atención al cliente.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Cuándo comienza la producción de mi pedido?
          </template>
          <template #answer>
            Para garantizar el envío más rápido posible de todos los productos (incluidos los productos personalizados), todos los productos Schuette® se fabrican para nuestros clientes inmediatamente después de realizar el pedido. Incluso iniciamos algunos de los pasos de preparación y procesamiento de forma totalmente automática e inmediatamente después de recibir el pedido. Por eso podemos decir con orgullo que la mayoría de los pedidos pueden entregarse antes de la fecha de entrega indicada.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Se necesitan herramientas para el montaje de los productos Schuette®?
          </template>
          <template #answer>
            Esto depende del método de montaje seleccionado. Para la instalación sin atornillar no se necesitan herramientas. Si decide instalar estores plisados o enrollables atornillando, puede ser suficiente un destornillador, dependiendo del tipo de ventana. Si además tiene a mano un taladro y brocas pequeñas (⌀ 1,0 - 2,0 mm) para el montaje de estores plisados "para taladrar", será aún más rápido y sencillo.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Cuánto tiempo necesito para la instalación?
          </template>
          <template #answer>
            Depende del producto elegido y del método de montaje correspondiente. Para nosotros era muy importante que el montaje de cada producto, independientemente del método de montaje, fuera lo más fácil y rápido posible para usted. Como todos nuestros productos ya están montados lo mejor posible, el montaje/instalación se realiza en un abrir y cerrar de ojos. Hemos puesto especial cuidado en que no tenga que hacer ningún trabajo de rompecabezas y que sus productos Schuette® puedan instalarse lo más rápidamente posible. La instalación en sí es un juego de niños y sólo lleva unos minutos.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Dónde puedo encontrar las instrucciones de montaje?
          </template>
          <template #answer>
            Las instrucciones de montaje se incluyen con cada producto. Las encontrará en el embalaje con las asas y los soportes. Si necesita una copia adicional, póngase en contacto con nuestro equipo de atención al cliente. Estarán encantados de enviarle un archivo PDF por correo electrónico.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Tengo que comprar soportes u otros accesorios por separado?
          </template>
          <template #answer>
            No es necesario. Todos los soportes de estores plisados y enrollables y otras piezas individuales necesarias para la instalación se incluyen siempre con el producto pedido. Sin embargo, en el caso de las cortinas con cinta de túnel, los ganchos o deslizadores deben comprarse por separado, por ejemplo, en una tienda de bricolaje.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Ofrece Schuette® también un servicio de instalación?
          </template>
          <template #answer>
            No es necesario un servicio de instalación, ya que el montaje de nuestros productos es un juego de niños y no se requiere ayuda profesional. Todos los productos están ya preparados por nosotros, siempre que se hayan medido según las instrucciones de medición, de modo que no hay que reajustar nada y sólo hay que montar los soportes y las asas. Para ello no es necesaria la ayuda profesional de un equipo de montaje, lo que también supone un considerable ahorro de costes.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Dispone Schuette® de un servicio de reparaciones?
          </template>
          <template #answer>
            Sí, hemos creado un servicio de reparaciones en caso de que los estores plisados o enrollables necesiten ajustarse o acortarse, o si algo ha ido mal durante la instalación. Póngase en contacto con nuestro equipo de atención al cliente por correo electrónico o nuestro chat.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Qué puedo hacer si he cometido un error?
          </template>
          <template #answer>
            Dependiendo de hasta qué punto se haya equivocado, no hay ningún problema. En el caso de los estores plisados, a veces basta con sustituir los soportes por otros diferentes. Si eso no es suficiente, seguimos contando con nuestro servicio de reparación, en el que podemos realizar pequeños cambios en el tamaño del estor. ¿Ha tomado usted mismo las medidas? Entonces póngase en contacto con nuestro servicio de atención al cliente e indíquenos su número de pedido y los productos afectados junto con las nuevas dimensiones deseadas.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Cuánto tarda el envío?
          </template>
          <template #answer>
            El plazo de entrega de cada producto se muestra en la página del producto y en la cesta de la compra. Por regla general, los pedidos grandes se envían en un plazo de 5 a 10 días laborables. Si necesita un plazo de entrega especial, póngase en contacto con nosotros.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Cuánto cuesta el envío?
          </template>
          <template #answer>
            Los gastos de envío son gratuitos en Alemania y Austria. Los gastos de envío para otros países de entrega se muestran en la cesta de la compra en cuanto se selecciona el país de entrega.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Cómo puedo comprobar el estado de mi pedido?
          </template>
          <template #answer>
            A los pocos días de realizar su pedido, recibirá un correo electrónico con el número de envío, la empresa de mensajería correspondiente y un enlace para realizar el seguimiento de su envío a la dirección de correo electrónico facilitada en el pedido. Puede utilizar esta información para comprobar el estado del envío en cualquier momento.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Cuáles son las formas de pago disponibles?
          </template>
          <template #answer>
            <p>
              Todos los métodos de pago disponibles se muestran en la cesta de la compra. Tiene a su disposición los siguientes métodos de pago:
            </p>
            <div class="check-list-container">
              <ul class="check-list is-fullwidth">
                <li><b>Pago por adelantado/transferencia bancaria</b></li>
                <li><b>Transferencia bancaria instantánea</b></li>
                <li><b>Tarjeta de crédito</b></li>
                <li><b>PayPal</b></li>
                <li><b>Compra a cuenta Klarna Pay Later</b></li>
                <li><b>Compra a plazos Klarna Slice It</b></li>
                <li><b>Giropay</b></li>
                <li><b>EPS</b></li>
              </ul>
            </div>
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            ¿Cuándo y cómo recibo la factura?
          </template>
          <template #answer>
            Recibirá la factura proforma por correo electrónico como archivo adjunto a la confirmación del pedido. La factura definitiva se enviará tras la recepción del pago en la confirmación de pago. Si no ha recibido la factura, póngase en contacto con nuestro servicio de atención al cliente.
          </template>
        </category-faq>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryFaq from '~/components/shared/CategoryFaq'
export default {
  components: {
    CategoryFaq
  }
}
</script>
