<template>
  <div>
    <div class="intro-container">
      <div class="container-1000">
        <div class="intro-section">
          <h1 class="text-center">
            Schuette® plissegardiner, rullegardiner og gardiner til hjemmet ditt!
          </h1>

          <div class="grid-1-2-1 category-intro-photos category-intro-videos">
            <div class="grid-item">
              <!-- <lazy-video :video="{video: RelaxVideoTwo}" alt="willkommen" /> -->
              <b-image
                src="https://img.schuette.shop/schuette/categories/rollos/schuette-fabrics-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/categories/rollos/schuette-fabrics-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="rollos"
              />
            </div>
            <div class="grid-item">
              <lazy-video :video="{ video: WelcomeVideoTwo }" alt="willkommen" />
              <!-- <div class="grid-item-content">
                <div class="category-link-new">
                  {{ $t('project.landingPage.new') }}
                </div>
                <a :href="getSLLink">
                  <b-button type="is-primary" class="cta-primary" expanded rounded icon-right="arrow-right-bold">
                    {{ $t('project.landingPage.browseAll') }}
                  </b-button>
                </a>
              </div> -->
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/categories/pleated-blinds/schuette-fabrics-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/categories/pleated-blinds/schuette-fabrics-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="plissee"
              />
            </div>
          </div>

          <div class="check-list-container">
            <ul class="check-list is-big">
              <li><b>Ukomplisert</b> - konfigurer og bestill plissegardiner, rullegardiner, gardiner & hjemmetekstiler dine direkte på nettet.</li>
              <li><b>Enkelt</b> - velg stoff, størrelse, monteringstype og mer på bare noen få sekunder.</li>
              <li><b>Service</b> - via chat, e-post eller direkte hos deg. Vi er alltid der for deg!</li>
              <li><b>Rask</b> - Se direkte på produktsiden når vi leverer.</li>
              <li>
                <b>Transparent</b> - Alle priser er direkte synlige!
              </li>
            </ul>
          </div>

          <div class="text-container">
            <p class="text-center">
              <b>Velkommen til plissegardin og rullegardin produsenten Schuette®</b>. Vi vil bare si takk! Takk for
              støtten og anerkjennelsen av arbeidet vårt og den lidenskapen vi legger i utviklingen og produksjonen av
              plisségardiner, rullegardiner og gardiner. Du gir oss utrolig mye styrke, og vi vil gjerne takke deg for
              dette med best mulig og lidenskapelig produserte produkter!
            </p>
          </div>

          <categories v-if="page" :categories="page.navigation" home full>
            <template #features="{ identifier }">
              <!-- plisy -->
              <template v-if="identifier === 'plisy'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                    <drilling-icon />
                    <no-drilling-icon />
                  </div>
                  <div class="feature-description">
                    Rask 60s montering
                    (med eller uten boring)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    5 kolleksjoner og 74 farger
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Schuette® kvalitetsløfte
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    Fra 27,99 EUR<br>
                    Gratis levering
                  </div>
                </div>
              </template>

              <!-- roletki -->
              <template v-if="identifier === 'roletki'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                    <drilling-icon />
                    <no-drilling-icon />
                  </div>
                  <div class="feature-description">
                    Rask 60s montering
                    (med eller uten boring)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    2 kolleksjoner og 26 farger
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Schuette® kvalitetsløfte
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    Fra 9,99 EUR<br>
                    Gratis levering
                  </div>
                </div>
              </template>

              <!-- zaslony -->
              <template v-if="identifier === 'zaslony'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                  </div>
                  <div class="feature-description">
                    Rask 60s montering
                    (med øyer eller gardinbånd)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    2 kolleksjoner og 30 farger
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Schuette® kvalitetsløfte
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    Fra 42,99 EUR<br>
                    Gratis levering
                  </div>
                </div>
              </template>
            </template>
          </categories>
        </div>
      </div>
    </div>
    <div class="company-container">
      <div class="container-1000">
        <div class="company-section">
          <h2>
            Kjære kunde ❤️,
          </h2>
          <p>
            Takk for at du tar deg tid til å finne ut mer om oss og produktene våre.
          </p>
          <p class="font-semibold">
            Schuette® plisségardiner, rullegardiner og solskjermingssystemer produseres lokalt med de strengeste krav til
            håndverk og rettferdige lønninger, og av solide, holdbare og moderne materialer.
          </p>
          <p>
            Vi er en liten familiedrevet fabrikk, og vi bruker, utvikler og lever med produktene våre hver eneste dag. Det
            lille teamet vårt gjør alt det kan for å sikre at plisségardinene og gardinene våre ikke bare oppfyller de
            høyeste standarder for håndverk, men fremfor alt kravene til hjemmet ditt, fordi vi forstår hva "hjem" betyr
            og hva det betyr for deg. Vårt mål er å forbedre livskvaliteten din gjennom perfekt design og overlegen
            vindusfunksjon.
          </p>
          <div class="company-image">
            <b-image
              src="https://img.schuette.shop/schuette/homepage/schuette-1.jpg.webp"
              webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-1.jpg"
              class="skeleton-image-placeholder"
              lazy
              alt="Schuette Familien Manufaktur"
            />
          </div>
          <h2 class="text-center">
            Schuette® kvalitetsløfte
          </h2>
          <p>
            Vi er spesielt stolte av at vi utvikler hver eneste komponent i alle Schuette®-produktene våre selv, og at vi
            derfor holder hele produksjonsprosessen i egne hender. Dette gjør det mulig for oss å opprettholde strenge
            kvalitetsstandarder i hvert eneste ledd.
          </p>
          <p>
            Fokuset vårt er på eksepsjonell holdbarhet og lang levetid for alle komponenter samt ukomplisert bruk i
            hjemmet. Alt dette understreker vi med vårt autentiske Schuette®-kvalitetsløfte:
          </p>
          <div class="check-list-container">
            <ul class="check-list is-big">
              <li><b>Enkel å bruke</b> - Geschikte bevestigingen voor elk raam</li>
              <li>
                <b>Bevist holdbarhet</b> - 100.001 keer getest in de meest uiteenlopende configuraties en onder alle
                klimaatomstandigheden
              </li>
              <li><b>Enkelt vedlikehold</b> - for et bekymringsløst "etterpå"</li>
              <li><b>UV-beskyttelse</b> - For å forhindre falming av tekstiler og møbler</li>
              <li><b>Alltid oppdatert</b> - Med fokus på kontinuerlig videreutvikling og optimalisering.</li>
              <li><b>Allsidig service</b> - Alltid der for deg, uansett når og hvordan.</li>
            </ul>
          </div>
          <div class="grid-1-2-1">
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Vorhänge"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-2.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-2.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Plissee"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Rollos"
              />
            </div>
          </div>

          <h2 class="text-center ">
            Schuette® - Originale
          </h2>
          <p class="font-semibold is-big">
            Kjære kunder,
          </p>
          <p>
            <b> Det vi gjør kan ikke kopieres</b>. Schuette®-produktene våre er et resultat av årelangt arbeid med
            detaljer og kontinuerlig utvikling av vår unike teknologi.
          </p>
          <p>
            Til tross for de umiskjennelige egenskapene som gjør Schuette®-produktene til enestående originaler, er det
            stadig flere som prøver å kopiere suksessen vår. Vi prøver å ta det som et kompliment at vårt harde arbeid er
            en kilde til inspirasjon for andre.
          </p>
          <p>
            De bør imidlertid forstå at det ikke er nok å bruke merket "Premium" eller kopiere bildene og designene våre.
            Det er heller ikke nok å få våre høykvalitetsfester produsert i Kina og deretter merke dem som "Made in
            Europe".
            Forfalskerne prøver å selge dårligere produkter i ly av mellomstore bedrifter og familiedrevne produsenter som
            vår.
          </p>
          <p>
            Men en forfalskning forblir alltid en forfalskning. Originalen kan ikke reproduseres, fordi lidenskapen,
            energien og engasjementet vi legger i utvikling, produksjon, service og direkte kontakt med deg er unik.
          </p>
          <p>
            <b>Hvorfor det?</b> Fordi vi bokstavelig talt lever det vi skaper - dag etter dag. Takket være dere, verdens
            beste kunder, er vi motiverte til å jobbe enda hardere hver dag.
          </p>
          <p>
            Kjære kunder, fortsett å stole på kvalitet når det gjelder hjemmet deres. Stol på oss, på originalen, på
            Schuette®-familiefabrikken og på mennesker som vier livet sitt til å skape produkter som beriker hjemmet ditt.
          </p>

          <h2 class="text-center">
            Som en liten familiebedrift vet vi hva "hjem" betyr
          </h2>
          <p>
            Derfor utvikler og produserer vi produktene våre ikke bare i samsvar med de høyeste kvalitetsstandarder for
            produksjon og materialer, men fremfor alt for å oppfylle dine standarder og ønsker som bruker, fordi vi
            verdsetter og respekterer ditt "hjem".
          </p>
          <div class="company-image">
            <b-image
              src="https://img.schuette.shop/schuette/homepage/de/schuette-unique.jpg.webp"
              webp-fallback="https://img.schuette.shop/schuette/homepage/de/schuette-unique.jpg"
              class="skeleton-image-placeholder is-hidden-mobile"
              lazy
              alt="Schuette Bedeutung"
            />
            <b-image
              src="https://img.schuette.shop/schuette/homepage/de/schuette-unique-mobile.jpg.webp"
              webp-fallback="https://img.schuette.shop/schuette/homepage/de/schuette-unique-mobile.jpg"
              class="skeleton-image-placeholder is-hidden-tablet mobile-unique"
              lazy
              alt="Schuette Bedeutung"
            />
          </div>
          <p>
            <b>Ingen er på jakt etter såkalte gjør-det-selv-plisségardiner eller kinesiske persienner</b>, men dessverre
            har denne typen produkter altfor ofte havnet i handlekurven til mange kjøpere tidligere, ettersom produkter av
            høyere kvalitet ofte rett og slett skilte seg for mye ut prismessig.
          </p>
          <p>
            <b>Som produsent løser vi nå dette problemet ved å åpne for direktesalg til deg som sluttkunde</b>
            og ikke bare til arkitekter, hoteller, restauranter og anerkjente installatører av solskjermingssystemer som
            tidligere. Tro oss når vi sier at dette er en utrolig glede for oss hver eneste dag. ❤️
          </p>
          <p>
            Det ville være en ære for familien vår og teamet vårt om produktene våre kunne leke med solstrålene hjemme hos
            deg og trollbinde deg.
          </p>
          <p><b>Med vennlig hilsen,</b></p>
          <div class="schuette-family">
            <logo-schuette class="family-logo" />Schuette®-familien
          </div>
        </div>
      </div>
    </div>
    <div class="products-highlights">
      <div class="container-1000">
        <mini-categories v-if="page" :categories="page.navigation" home />
      </div>
    </div>
  </div>
</template>

<script>
import LazyVideo from 'marketplace-front-core/components/shared/LazyVideo.vue'
import Categories from '../landing/Categories.vue'
import MiniCategories from '../landing/MiniCategories'
import faq from './faq-no.js'

import LogoSchuette from '~/static/images/logo-schuette.svg?inline'
import NoDrillingIcon from '~/assets/images/homepage/nodrilling-square.svg?inline'
import DrillingIcon from '~/assets/images/homepage/drilling-square.svg?inline'
import QuickIcon from '~/assets/images/homepage/quick-outline-square.svg?inline'
import CollectionsIcon from '~/assets/images/homepage/collections-outline-square.svg?inline'
import ColorsIcon from '~/assets/images/homepage/colors-outline-square.svg?inline'
import StrongIcon from '~/assets/images/homepage/strong-outline-square.svg?inline'
import CheckIcon from '~/assets/images/homepage/check-square.svg?inline'

import WelcomeVideoTwo from '~/static/images/schuette-welcome-video-2.mp4'

export default {
  name: 'HomePageEn',
  components: {
    LazyVideo,
    Categories,
    MiniCategories,
    NoDrillingIcon,
    DrillingIcon,
    QuickIcon,
    CollectionsIcon,
    ColorsIcon,
    StrongIcon,
    CheckIcon,
    LogoSchuette
  },
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      WelcomeVideoTwo
    }
  },
  head () {
    return {
      script: [{ type: 'application/ld+json', json: this.getFaqSchemaOrg }]
    }
  },
  computed: {
    getFaqSchemaOrg () {
      const entity = faq.map((x) => {
        return {
          '@type': 'Question',
          name: x.question.replace(/["]+/g, '&quot;'),
          acceptedAnswer: {
            '@type': 'Answer',
            text: x.answer.replace(/["]+/g, '&quot;')
          }
        }
      })
      const schema = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [...entity]
      }
      return schema
    }
  }
}
</script>

<style lang="scss" scoped>
.intro-section {
  @include mobile {
    h1 {
      font-size: 24px !important;
    }
  }

  h2 {
    margin: 32px 0 16px 0;
    font-size: 20px !important;
    font-weight: 600;
  }
}

.homepage-category .check-list-container ul.check-list {
  max-width: 85%;
}

:deep(.welcome-video) video {
  border-radius: 16px;
}
</style>
