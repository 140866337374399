<template>
  <div>
    <div class="intro-container">
      <div class="container-1000">
        <div class="intro-section">
          <h1 class="text-center">
            Estores plisados, estores enrollables y cortinas Schuette® que enamorarán a su hogar.
          </h1>

          <div class="grid-1-2-1 category-intro-photos category-intro-videos">
            <div class="grid-item">
              <!-- <lazy-video :video="{video: RelaxVideoTwo}" alt="willkommen" /> -->
              <b-image
                src="https://img.schuette.shop/schuette/categories/rollos/schuette-fabrics-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/categories/rollos/schuette-fabrics-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="rollos"
              />
            </div>
            <div class="grid-item">
              <lazy-video :video="{video: WelcomeVideoTwo}" alt="willkommen" />
              <!-- <div class="grid-item-content">
                <div class="category-link-new">
                  {{ $t('project.landingPage.new') }}
                </div>
                <a :href="getSLLink">
                  <b-button type="is-primary" class="cta-primary" expanded rounded icon-right="arrow-right-bold">
                    {{ $t('project.landingPage.browseAll') }}
                  </b-button>
                </a>
              </div> -->
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/categories/pleated-blinds/schuette-fabrics-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/categories/pleated-blinds/schuette-fabrics-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="plissee"
              />
            </div>
          </div>

          <div class="check-list-container">
            <ul class="check-list is-big">
              <li><b>Sin complicaciones</b> - Configure y solicite sus estores plisados directamente online</li>
              <li><b>Sencillo</b> - Elija el tipo de instalación, el tejido, el perfil y el tamaño en sólo unos segundos</li>
              <li><b>Servicio</b> - Chat, correo electrónico o directamente en su residencia. Estamos siempre a su disposición!</li>
              <li><b>Rápido</b> - Vea directamente en la página del producto cuándo realizamos la entrega</li>
              <li><b>Transparente</b> - ¡Todos los precios son directamente visibles!</li>
            </ul>
          </div>

          <h2 class="text-center">
            Bienvenido a la fábrica de estores plisados y enrollables Schuette®
          </h2>

          <div class="text-container">
            <p class="text-center">
              Queremos darle las gracias. Gracias por su apoyo, así como por el aprecio a nuestro trabajo, en el que ponemos el corazón y el alma, invirtiendo en el desarrollo y en la producción de sus estores plisados, estores enrollables y cortinas.
            </p>
            <p class="text-center">
              Todo ello nos da una fuerza increíble y queremos agradecérselo con los mejores productos fabricados con pasión.
            </p>
          </div>

          <categories v-if="page" :categories="page.navigation" home full>
            <template #features="{identifier}">
              <!-- plisy -->
              <template v-if="identifier === 'plisy'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                    <drilling-icon />
                    <no-drilling-icon />
                  </div>
                  <div class="feature-description">
                    Rápido Montaje en 60s (Con o sin perforación)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    5 colecciones y 74 colores
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Compromiso de calidad Schuette®
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    Desde 27,99 EUR <br>
                    Envío gratuito
                  </div>
                </div>
              </template>

              <!-- roletki -->
              <template v-if="identifier === 'roletki'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                    <drilling-icon />
                    <no-drilling-icon />
                  </div>
                  <div class="feature-description">
                    Rápido Montaje en 60s (Con o sin perforación)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    4 colecciones y 26 colores
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Compromiso de calidad Schuette®
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    Desde 9,99 EUR<br>
                    Envío gratuito
                  </div>
                </div>
              </template>

              <!-- zaslony -->
              <template v-if="identifier === 'zaslony'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                  </div>
                  <div class="feature-description">
                    Rápido Montaje en 60s <br>
                    (Con ojales o cinta pasabarras)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    2 colecciones y 30 colores
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Compromiso de calidad Schuette®
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    Desde 42,99 EUR<br>
                    Envío gratuito
                  </div>
                </div>
              </template>
            </template>
          </categories>
        </div>
      </div>
    </div>
    <div class="company-container">
      <div class="container-1000">
        <div class="company-section">
          <h2>
            Estimado cliente ❤️,
          </h2>
          <p>
            Le agradecemos que se haya tomado la molestia de informarse sobre nosotros y nuestros productos.
          </p>
          <p class="font-semibold">
            Los estores plisados, los estores enrollables y los sistemas de protección solar Schuette® se fabrican localmente con la más estricta artesanía y salarios justos, así como con materiales sólidos, duraderos y modernos.
          </p>
          <p>
            Somos una pequeña fábrica familiar y utilizamos, desarrollamos y vivimos nuestros productos día tras día. Nuestro pequeño equipo hace todo lo posible para garantizar que nuestros estores plisados, estores enrollables y cortinas no sólo cumplan los más altos estándares de artesanía, sino sobre todo los requisitos de su hogar, porque entendemos lo que significa el "hogar" y lo que también significa para usted. Nuestro objetivo es mejorar la calidad de vida mediante un diseño perfecto y una función superior en la ventana.
          </p>
          <div class="grid-3n category-intro-photos">
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-company-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-company-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette®"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-company-2.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-company-2.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette®"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-company-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-company-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette®"
              />
            </div>
          </div>
          <h2 class="text-center">
            Compromiso de calidad de Schuette®
          </h2>
          <p>
            Estamos especialmente orgullosos de desarrollar nosotros mismos cada una de las piezas de todos nuestros productos Schuette® y, por tanto, de mantener todo el proceso de producción en nuestras manos. Esto nos permite mantener estrictas normas de calidad en cada una de las fases.
          </p>
          <p>
            Nos centramos en ofrecer una durabilidad extraordinaria de cada componente, así como un uso sencillo. Esta es la promesa de calidad Schuette®:
          </p>
          <div class="check-list-container">
            <ul class="check-list is-big">
              <li><b>Fácil de usar</b> - soportes adecuados para cada ventana.</li>
              <li><b>Durabilidad probada</b> - Probada 100.001 veces en varias configuraciones y bajo todas las condiciones climáticas.</li>
              <li><b>Fácil cuidado </b> -  No hay que preocuparse.</li>
              <li><b>SProtección UV</b> - Para evitar la decoloración de tejidos y muebles.</li>
              <li><b>Siempre al día</b> - Con un constante enfoque en el desarrollo y la optimización</li>
              <li><b>Servicio completo</b> - Siempre a su disposición, sin importar cuándo ni cómo.</li>
            </ul>
          </div>
          <div class="grid-1-2-1">
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Vorhänge"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-2.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-2.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Plissee"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Rollos"
              />
            </div>
          </div>

          <h2 class="text-center ">
            Schuette® - El original
          </h2>
          <p class="font-semibold is-big">
            Estimados clientes,
          </p>
          <p>
            <b> DLo que hacemos no se puede copiar</b>. Los productos Schuette® son el resultado de años de dedicación al detalle y del desarrollo continuo de tecnologías propias.
          </p>
          <p>
            A pesar de las inconfundibles características que hacen a los productos Schuette® originales e incomparables, cada vez hay más imitadores que intentan copiar nuestro éxito. Intentamos tomarnos esto como un cumplido, en cuanto nuestro duro trabajo es fuente de inspiración para otros.
          </p>
          <p>
            Sin embargo, deben entender que no basta con utilizar la etiqueta "Premium" o copiar nuestras imágenes y diseños. Tampoco basta con que nuestros soportes de alta calidad se fabriquen en China y luego se etiqueten como "Made in Europe".
          </p>
          <p>
            Los falsificadores intentan vender productos de calidad inferior a costa de empresas medianas y fabricantes familiares como nosotros
          </p>
          <p>
            Pero una falsificación siempre sigue siendo una falsificación. El original no puede reproducirse porque la pasión, la energía y la dedicación que ponemos en el desarrollo, la producción, el servicio y el contacto directo con usted son únicas.
          </p>
          <p>
            <b> ¿Por qué?</b> Porque vivimos literalmente lo que creamos, día tras día. Gracias a ustedes, los mejores clientes del mundo, nos sentimos motivados para trabajar cada día con más ahínco.
          </p>
          <p>
            Estimados clientes, sigan confiando en la calidad cuando se trate de su hogar. Confíen en nosotros, en el original, en la fábrica de la familia Schuette® y en las personas que dedican su vida a crear productos que enriquecen su hogar.
          </p>

          <h2 class="text-center">
            Como pequeña empresa familiar, sabemos lo que significa "hogar"
          </h2>
          <p>
            Por eso desarrollamos y producimos nuestros productos no sólo cumpliendo las normas más estrictas de calidad en fabricación y materiales, sino sobre todo para satisfacer sus deseos y exigencias como usuario, porque valoramos y respetamos su "hogar".
          </p>
          <div class="company-image">
            <b-image
              src="https://img.schuette.shop/schuette/homepage/es/schuette-unique.jpg.webp"
              webp-fallback="https://img.schuette.shop/schuette/homepage/es/schuette-unique.jpg"
              class="skeleton-image-placeholder is-hidden-mobile"
              lazy
              alt="Schuette Bedeutung"
            />
            <b-image
              src="https://img.schuette.shop/schuette/homepage/es/schuette-unique-mobile.jpg.webp"
              webp-fallback="https://img.schuette.shop/schuette/homepage/es/schuette-unique-mobile.jpg"
              class="skeleton-image-placeholder is-hidden-tablet mobile-unique"
              lazy
              alt="Schuette Bedeutung"
            />
          </div>
          <p>
            <b>Nadie quiere los llamados estores plisados de las tiendas de bricolaje o los estores enrollables chinos</b>, pero lamentablemente este tipo de productos suelen acabar frecuentemente en los carritos de la compra de muchos consumidores, ya que otros productos de mayor calidad a menudo imponen por su precio.
          </p>
          <p>
            <b>Como fabricante, ahora estamos resolviendo este problema y abriéndonos a la venta directa con clientes individuales</b> y no sólo a arquitectos, hoteles, restaurantes y empresas de instalación de sistemas de protección solar. Créanos cuando le decimos que esto nos da un placer increíble día tras día ❤️.
          </p>
          <p>
            Sería un honor para nuestra familia y nuestro equipo que dejara que nuestros productos jueguen con los rayos del sol en su casa y que de este modo queden encantados.
          </p>
          <p>
            <b>Un cordial saludo,</b>
          </p>
          <div class="schuette-family">
            <logo-schuette class="family-logo" />La familia Schuette®
          </div>
        </div>
      </div>
    </div>
    <div class="products-highlights">
      <div class="container-1000">
        <h2 class="text-center">
          Ver todos los productos:
        </h2>
        <categories v-if="page" :categories="page.navigation" home />
      </div>
    </div>
  </div>
</template>

<script>
import LazyVideo from 'marketplace-front-core/components/shared/LazyVideo.vue'
import Categories from '../landing/Categories.vue'
import faq from './faq-es.js'

import LogoSchuette from '~/static/images/logo-schuette.svg?inline'
import NoDrillingIcon from '~/assets/images/homepage/nodrilling-square.svg?inline'
import DrillingIcon from '~/assets/images/homepage/drilling-square.svg?inline'
import QuickIcon from '~/assets/images/homepage/quick-outline-square.svg?inline'
import CollectionsIcon from '~/assets/images/homepage/collections-outline-square.svg?inline'
import ColorsIcon from '~/assets/images/homepage/colors-outline-square.svg?inline'
import StrongIcon from '~/assets/images/homepage/strong-outline-square.svg?inline'
import CheckIcon from '~/assets/images/homepage/check-square.svg?inline'

import WelcomeVideoTwo from '~/static/images/schuette-welcome-video-2.mp4'

export default {
  name: 'HomePageEn',
  components: {
    LazyVideo,
    Categories,
    NoDrillingIcon,
    DrillingIcon,
    QuickIcon,
    CollectionsIcon,
    ColorsIcon,
    StrongIcon,
    CheckIcon,
    LogoSchuette
  },
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      WelcomeVideoTwo
    }
  },
  head () {
    return {
      script: [{ type: 'application/ld+json', json: this.getFaqSchemaOrg }]
    }
  },
  computed: {
    getFaqSchemaOrg () {
      const entity = faq.map((x) => {
        return {
          '@type': 'Question',
          name: x.question.replace(/["]+/g, '&quot;'),
          acceptedAnswer: {
            '@type': 'Answer',
            text: x.answer.replace(/["]+/g, '&quot;')
          }
        }
      })
      const schema = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [...entity]
      }
      return schema
    }
  }
}
</script>
