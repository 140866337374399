<template>
  <div class="section-faq">
    <div class="container-1000">
      <div class="section-tag-container">
        <div class="section-tag">
          Schuette.shop FAQ
        </div>
      </div>
      <h2 class="text-center">
        Schuette.shop - Domande frequenti
      </h2>
      <div class="category-faq-container">
        <category-faq>
          <template #question>
            Cosa significa Schuette®?
          </template>
          <template #answer>
            La nostra azienda a conduzione familiare offre molto di più di semplici protezioni solari, schermi per la privacy e accessori per la casa. Il nostro compito principale è quello di farvi sorridere, aumentare il vostro comfort abitativo e migliorare la vostra qualità di vita. Con i prodotti Schuette® non avrete solo prodotti per la vostra casa, ma molto di più! Basta provarli e sentire la differenza. ♥︎
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Posso chiedere un consiglio se non sono sicuro di quali siano i migliori rivestimenti per finestre?
          </template>
          <template #answer>
            Certo che è possibile. Se non siete sicuri che le tende plissettate, le tende a nido d'ape, le tende a rullo o le tende siano la scelta migliore per le vostre finestre, il nostro team di esperti sarà lieto di aiutarvi in qualsiasi momento! Basta inviarci le foto delle vostre finestre e dirci se potete forare o meno e, se necessario, a quale effetto desiderato siete maggiormente interessati: traslucido, leggermente oscurante, completamente oscurante, contro il caldo e il freddo, ecc. Saremo lieti di ricevere una risposta in qualsiasi momento!
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Come posso contattare il servizio clienti?
          </template>
          <template #answer>
            Il nostro servizio clienti può essere contattato via e-mail all'indirizzo emailfuerdich@schuette.shop o tramite il nostro sistema di messaggistica sulla homepage.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Tutti i prodotti Schuette® sono opachi?
          </template>
          <template #answer>
            Sì, indipendentemente dal fatto che si scelga una tenda plissettata, una tenda a rullo o una tenda, tutti i prodotti Schuette® sono sempre opachi al 100% e alcuni di essi impediscono l'ingresso della luce solare e contribuiscono a oscurare completamente gli ambienti.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Quale tessuto scegliere?
          </template>
          <template #answer>
            La scelta del tessuto giusto può essere fatta in base al colore, al design e alle proprietà del tessuto. Ogni tipo di tessuto e design ha i suoi vantaggi. Le proprietà del tessuto variano talvolta anche in base alla scelta del colore. Per ulteriori informazioni sulle proprietà dei tessuti, consultare la pagina dei prodotti.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Che cos'è il rivestimento argentato dei prodotti Thermo?
          </template>
          <template #answer>
            Il rivestimento sul rovescio (lato finestra) dei nostri tessuti Thermo riflette i raggi solari e, tra l'altro, rallenta notevolmente la penetrazione del calore. Un'ulteriore proprietà del rivestimento lucido è un oscuramento medio-alto dell'ambiente. Con i colori scuri del tessuto, il colore intrinseco della finitura di protezione termica sul retro del tessuto è argento, mentre con i colori chiari è più un tono beige.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Posso ordinare dei campioni di tessuto?
          </template>
          <template #answer>
            Con piacere. I campioni di tessuto possono essere ordinati tramite il nostro servizio clienti.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Posso ordinare solo i tessuti o anche i profili?
          </template>
          <template #answer>
            Dipende. È possibile ordinare campioni di tessuto e profili di guida per finestre per tetti aggiuntivi. Se necessario, contattare il nostro servizio clienti. Anche le staffe per tende plissettate e a rullo possono essere ordinate come accessori in qualsiasi momento tramite la nostra homepage. Non è possibile ordinare separatamente interi pannelli di tessuto, profili e occhielli per tende.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Quali sono le condizioni di restituzione?
          </template>
          <template #answer>
            Le condizioni di restituzione variano a seconda del tipo di prodotto. Tutti i prodotti su misura, ovvero i prodotti personalizzati, non possono essere restituiti o sostituiti in quanto vengono realizzati individualmente e specificamente per ogni cliente direttamente dopo l'ordine. I prodotti non su misura possono essere restituiti entro il periodo di restituzione, a condizione che siano in perfette condizioni. Il tipo di prodotto in questione è descritto nel titolo del prodotto. Informazioni più dettagliate sulle nostre condizioni di reso sono disponibili nei nostri termini e condizioni generali e nella politica di cancellazione.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            È possibile vedere i prodotti Schuette® in uno showroom prima dell'acquisto?
          </template>
          <template #answer>
            Al momento non disponiamo di showroom o negozi fissi con i nostri prodotti Schuette®. Per questo motivo abbiamo reso le immagini dei prodotti ancora più realistiche, in modo che possiate essere sicuri di ricevere esattamente il prodotto che vedete sulla nostra homepage. Inoltre, se necessario, è possibile ordinare campioni di tessuto tramite il nostro servizio clienti.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Quando inizia la produzione del mio ordine?
          </template>
          <template #answer>
            Per garantire la spedizione più rapida possibile di tutti i prodotti (compresi quelli personalizzati), tutti i prodotti Schuette® vengono realizzati per i nostri clienti subito dopo l'ordine. Alcune fasi di preparazione e lavorazione iniziano addirittura automaticamente e immediatamente dopo la ricezione dell'ordine. Possiamo quindi affermare con orgoglio che la maggior parte degli ordini può essere consegnata prima della data di consegna indicata.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Sono necessari degli strumenti per l'installazione dei prodotti Schuette®?
          </template>
          <template #answer>
            Dipende dal metodo di montaggio scelto. Per l'installazione senza foratura non sono necessari attrezzi. Se si decide di installare tende plissettate o a rullo con foratura, può essere sufficiente un cacciavite, a seconda del tipo di finestra. Se avete a portata di mano anche un avvitatore a batteria e piccole punte da trapano (⌀ 1,0 - 2,0 mm), il montaggio delle tende plissettate "con trapano" sarà ancora più rapido e semplice.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Quanto tempo è necessario per l'installazione?
          </template>
          <template #answer>
            Dipende dal prodotto scelto e dal relativo metodo di montaggio.Per noi era estremamente importante che l'assemblaggio di ogni prodotto, indipendentemente dal metodo di montaggio, fosse il più semplice e rapido possibile.Poiché tutti i nostri prodotti sono già assemblati nel miglior modo possibile, l'assemblaggio/installazione viene completato in pochissimo tempo.Ci siamo preoccupati di garantire che non dobbiate fare alcun lavoro di puzzle e che i vostri prodotti Schuette® possano essere installati il più rapidamente possibile.L'installazione è un gioco da ragazzi e richiede solo pochi minuti.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Dove posso trovare le istruzioni di montaggio?
          </template>
          <template #answer>
            Le istruzioni di montaggio sono incluse in ogni prodotto.Le troverete nella confezione con le maniglie e le staffe.Se avete bisogno di una copia aggiuntiva, contattate il nostro servizio clienti.Sarà lieto di inviarvi un file PDF via e-mail.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Devo acquistare separatamente le staffe o altri accessori?
          </template>
          <template #answer>
            Non è necessario. Tutte le staffe per tende plissettate e a rullo e le altre parti singole necessarie per l'installazione sono sempre incluse nel prodotto ordinato. Per le tende con nastro a tunnel, invece, i ganci o le guide devono essere acquistati separatamente, ad esempio presso un negozio di bricolage.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Schuette® offre anche un servizio di installazione?
          </template>
          <template #answer>
            Il servizio di installazione non è necessario, poiché l'installazione dei nostri prodotti è un gioco da ragazzi e non è necessario l'aiuto di un professionista.Tutti i prodotti sono già preparati da noi, in modo da non dover regolare nulla e da dover fissare solo le staffe e le maniglie.Per questo non è necessario l'aiuto professionale di un team di montaggio, con un notevole risparmio di costi!
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Schuette® dispone di un servizio di riparazione?
          </template>
          <template #answer>
            Sì, disponiamo di un servizio di riparazione nel caso in cui le tende plissettate o le tende a rullo debbano essere regolate o accorciate, o se qualcosa va storto durante l'installazione. Contattate il nostro team di assistenza clienti via e-mail o chat.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Cosa posso fare se ho commesso un errore?
          </template>
          <template #answer>
            A seconda dell'entità dell'errore di misurazione, non è un problema. Nel caso delle tende plissettate, a volte è sufficiente sostituire le staffe con altre. Se ciò non bastasse, abbiamo il nostro servizio di riparazione che consente di apportare piccole modifiche dimensionali.Avete preso le misure da soli? Allora contattate il nostro servizio clienti e fornite il vostro numero d'ordine, i prodotti interessati e le nuove dimensioni desiderate.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Quanto tempo ci vuole per la spedizione?
          </template>
          <template #answer>
            I tempi di consegna di ciascun prodotto sono indicati nella pagina del prodotto e nel carrello.Di norma, spediamo anche gli ordini più grandi entro 5-10 giorni lavorativi.Se avete esigenze particolari in termini di tempi di consegna, contattateci.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Quanto costa la spedizione?
          </template>
          <template #answer>
            La spedizione è gratuita in Germania e in Austria. I costi di spedizione per gli altri Paesi di consegna vengono visualizzati nel carrello non appena viene selezionato il Paese di consegna.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Come posso controllare lo stato di consegna del mio ordine?
          </template>
          <template #answer>
            Entro pochi giorni dall'ordine, invieremo un'e-mail all'indirizzo di posta elettronica indicato nell'ordine con il numero di spedizione, il relativo corriere e il link per tracciare la spedizione.Potrete utilizzare queste informazioni per controllare lo stato della spedizione in qualsiasi momento.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Quali sono i metodi di pagamento disponibili?
          </template>
          <template #answer>
            Tutti i metodi di pagamento disponibili sono visualizzati nel carrello.
            Sono disponibili i seguenti metodi di pagamento:<br>
            Pagamento anticipato/bonifico bancario<br>
            Bonifico bancario istantaneo<br>
            Carta di credito<br>
            PayPal<br>
            Acquisto su conto corrente Klarna Pay Later<br>
            Acquisto rateale Klarna Slice It<br>
            Giropay<br>
            EPS<br>
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Quando e come riceverò la fattura?
          </template>
          <template #answer>
            Riceverete la fattura proforma via e-mail in allegato alla conferma d'ordine.La fattura definitiva sarà inviata dopo il ricevimento del pagamento nella conferma di pagamento. Se non avete ricevuto la fattura, contattate il nostro servizio clienti.
          </template>
        </category-faq>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryFaq from '~/components/shared/CategoryFaq'

export default {
  components: {
    CategoryFaq
  },
  props: {
    categories: {
      type: Array,
      default: null
    }
  }
}
</script>
