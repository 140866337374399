<template>
  <div>
    <div class="intro-container">
      <div class="container-1000">
        <div class="intro-section">
          <h1 class="text-center">
            Schuette® plisségordijnen, rolgordijnen, gordijnen om verliefd op te worden voor je huis!
          </h1>

          <div class="grid-1-2-1 category-intro-photos category-intro-videos">
            <div class="grid-item">
              <!-- <lazy-video :video="{video: RelaxVideoTwo}" alt="willkommen" /> -->
              <b-image
                src="https://img.schuette.shop/schuette/categories/rollos/schuette-fabrics-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/categories/rollos/schuette-fabrics-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="rollos"
              />
            </div>
            <div class="grid-item">
              <lazy-video :video="{video: WelcomeVideoTwo}" alt="willkommen" />
              <!-- <div class="grid-item-content">
                <div class="category-link-new">
                  {{ $t('project.landingPage.new') }}
                </div>
                <a :href="getSLLink">
                  <b-button type="is-primary" class="cta-primary" expanded rounded icon-right="arrow-right-bold">
                    {{ $t('project.landingPage.browseAll') }}
                  </b-button>
                </a>
              </div> -->
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/categories/pleated-blinds/schuette-fabrics-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/categories/pleated-blinds/schuette-fabrics-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="plissee"
              />
            </div>
          </div>

          <div class="check-list-container">
            <ul class="check-list is-big">
              <li><b>Ongecompliceerd</b> - configureer en bestel je plisségordijnen direct online</li>
              <li><b>Eenvoudig</b> - Selecteer het installatietype, de stof, het profiel & de maat in slechts enkele seconden</li>
              <li><b>Service</b> - Chat, e-mail of direct bij u op locatie. We staan altijd voor je klaar!</li>
              <li><b>Snel</b> - Zie direct op de plisségordijn productpagina wanneer we leveren</li>
              <li>
                <b>Transparant</b> - Alle prijzen zijn direct zichtbaar!
              </li>
            </ul>
          </div>

          <div class="text-container">
            <p class="text-center">
              Welkom bij de Schuette®-fabriek van plisségordijnen en rolgordijnen. We willen u bedanken! Bedankt voor uw steun en uw waardering voor ons werk en de passie die we steken in de ontwikkeling en productie van uw plisségordijnen, rolgordijnen en gordijnen. Jullie geven ons ongelooflijk veel kracht en we willen jullie hiervoor bedanken met de best mogelijke & met passie vervaardigde producten!
            </p>
          </div>

          <categories v-if="page" :categories="page.navigation" home full>
            <template #features="{ identifier }">
              <!-- plisy -->
              <template v-if="identifier === 'plisy'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                    <drilling-icon />
                    <no-drilling-icon />
                  </div>
                  <div class="feature-description">
                    Snelle installatie 60s
                    (Met of zonder boren)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    5 collecties & 74 kleuren
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Schuette® kwaliteitsbelofte
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    Vanaf 27,99 EUR<br>
                    Gratis verzending
                  </div>
                </div>
              </template>

              <!-- roletki -->
              <template v-if="identifier === 'roletki'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                    <drilling-icon />
                    <no-drilling-icon />
                  </div>
                  <div class="feature-description">
                    Snelle installatie 60s
                    (Met of zonder boren)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    2 collecties & 26 kleuren
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Schuette® kwaliteitsbelofte
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    Vanaf 9,99 EUR<br>
                    Gratis verzending
                  </div>
                </div>
              </template>

              <!-- zaslony -->
              <template v-if="identifier === 'zaslony'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                  </div>
                  <div class="feature-description">
                    Snelle installatie 60s
                    (Met oogjes of gordijnband)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    2 collecties & 30 kleuren
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Schuette® kwaliteitsbelofte
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    Vanaf 42,99 EUR<br>
                    Gratis verzending
                  </div>
                </div>
              </template>
            </template>
          </categories>
        </div>
      </div>
    </div>
    <div class="company-container">
      <div class="container-1000">
        <div class="company-section">
          <h2>
            Beste klant ❤️,
          </h2>
          <p>
            Bedankt dat u de tijd neemt om meer te weten te komen over ons en onze producten.
          </p>
          <p class="font-semibold">
            Schuette® plisségordijnen, rolgordijnen en zonweringsystemen worden lokaal vervaardigd met het strengste vakmanschap en eerlijke lonen en van degelijke, duurzame & moderne materialen.
          </p>
          <p>
            We zijn een kleine familiefabriek en we gebruiken, ontwikkelen & beleven onze producten dag in, dag uit. Ons kleine team doet er alles aan om ervoor te zorgen dat onze plisségordijnen en gordijnen niet alleen voldoen aan de hoogste normen van vakmanschap, maar vooral aan de eisen van jouw huis, omdat we begrijpen wat "thuis" betekent en wat het voor jou betekent. Ons doel is om uw levenskwaliteit te verbeteren door een perfect design en superieure raamfuncties.
          </p>
          <div class="company-image">
            <b-image
              src="https://img.schuette.shop/schuette/homepage/schuette-1.jpg.webp"
              webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-1.jpg"
              class="skeleton-image-placeholder"
              lazy
              alt="Schuette Familien Manufaktur"
            />
          </div>
          <h2 class="text-center">
            Schuette® kwaliteitsbelofte
          </h2>
          <p>
            We zijn bijzonder trots op het feit dat we elk onderdeel van al onze Schuette® producten zelf ontwikkelen en dus het hele productieproces in eigen handen houden. Dit stelt ons in staat om strenge kwaliteitsnormen te handhaven in elke afzonderlijke fase.
          </p>
          <p>
            Onze focus ligt op de uitzonderlijke duurzaamheid en levensduur van alle onderdelen en op het ongecompliceerde gebruik in huis. Dit alles benadrukken we met onze authentieke Schuette® kwaliteitsbelofte:
          </p>
          <div class="check-list-container">
            <ul class="check-list is-big">
              <li><b>Gebruiksvriendelijk</b> - Geschikte bevestigingen voor elk raam</li>
              <li>
                <b>Bewezen duurzaamheid</b> - 100.001 keer getest in de meest uiteenlopende configuraties en onder alle klimaatomstandigheden
              </li>
              <li><b>Onderhoudsvriendelijk</b> - Voor een zorgeloze "achteraf"</li>
              <li><b>UV-bescherming</b> - Om het verbleken van stoffen en uw meubels te voorkomen</li>
              <li><b>Altijd up-to-date</b> - Met een focus op constante verdere ontwikkeling & optimalisatie</li>
              <li><b>Allround service</b> - Staat altijd voor je klaar, wanneer en hoe dan ook.</li>
            </ul>
          </div>
          <div class="grid-1-2-1">
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Vorhänge"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-2.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-2.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Plissee"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Rollos"
              />
            </div>
          </div>

          <h2 class="text-center ">
            Schuette® - Het origineel
          </h2>
          <p class="font-semibold is-big">
            Beste klanten,
          </p>
          <p>
            <b> Wat wij doen kan niet gekopieerd worden</b>. Onze Schuette® producten zijn het resultaat van jarenlange toewijding aan details en de voortdurende ontwikkeling van onze unieke technologieën.
          </p>
          <p>
            Ondanks de onmiskenbare kenmerken die de Schuette® producten tot onvergelijkbare originelen maken, zijn er steeds meer imitators die ons succes proberen te kopiëren. We proberen het als een compliment op te vatten dat ons harde werk een bron van inspiratie is voor anderen.
          </p>
          <p>
            Ze moeten echter begrijpen dat het niet genoeg is om het label "Premium" te gebruiken of onze afbeeldingen en ontwerpen te kopiëren. Het is ook niet genoeg om onze hoogwaardige monturen in China te laten produceren en ze vervolgens te labelen als "Made in Europe".
            Namakers proberen inferieure producten te verkopen over de rug van middelgrote bedrijven en familiebedrijven zoals het onze.
          </p>
          <p>
            Maar een vervalsing blijft altijd een vervalsing. Het origineel kan niet worden nagemaakt, omdat de passie, energie en toewijding die wij steken in de ontwikkeling, productie, service en het directe contact met jou uniek zijn.
          </p>
          <p>
            <b>Waarom?</b> Omdat we letterlijk leven naar wat we creëren - dag na dag. Dankzij jullie, de beste klanten ter wereld, zijn we gemotiveerd om elke dag nog harder te werken.
          </p>
          <p>
            Beste klanten, blijf vertrouwen op kwaliteit als het om uw huis gaat. Vertrouw op ons, op het origineel, op het familiebedrijf Schuette® en op mensen die hun leven wijden aan het maken van producten die uw huis verrijken.
          </p>

          <h2 class="text-center">
            Als klein familiebedrijf weten we wat "thuis" betekent.
          </h2>
          <p>
            Daarom ontwikkelen en produceren we onze producten niet alleen volgens de hoogste kwaliteitsstandaarden op het gebied van productie en materialen, maar vooral om te voldoen aan uw normen en wensen als gebruiker, omdat we uw "thuis" waarderen en respecteren.
          </p>
          <div class="company-image">
            <b-image
              src="https://img.schuette.shop/schuette/homepage/de/schuette-unique.jpg.webp"
              webp-fallback="https://img.schuette.shop/schuette/homepage/de/schuette-unique.jpg"
              class="skeleton-image-placeholder is-hidden-mobile"
              lazy
              alt="Schuette Bedeutung"
            />
            <b-image
              src="https://img.schuette.shop/schuette/homepage/de/schuette-unique-mobile.jpg.webp"
              webp-fallback="https://img.schuette.shop/schuette/homepage/de/schuette-unique-mobile.jpg"
              class="skeleton-image-placeholder is-hidden-tablet mobile-unique"
              lazy
              alt="Schuette Bedeutung"
            />
          </div>
          <p>
            <b>Niemand zit te wachten op zogenaamde doe-het-zelf plisségordijnen of Chinese jaloezieën</b>, maar helaas zijn dit soort producten in het verleden maar al te vaak in de winkelkarretjes van veel kopers beland, omdat kwalitatief betere producten vaak gewoonweg te veel opvallen qua prijs.
          </p>
          <p>
            <b>Als fabrikant lossen we dit probleem nu op door de directe verkoop aan u, de eindklant, open te stellen</b>
            en niet zoals voorheen uitsluitend aan architecten, hotels, restaurants & gerenommeerde installatiebedrijven voor zonweringsystemen. Geloof ons als we zeggen dat dit ons dag na dag ongelooflijk veel plezier geeft ❤️
          </p>
          <p>
            Het zou een eer zijn voor onze familie en ons team als onze producten met de zonnestralen in uw huis zouden kunnen spelen en u erdoor betoverd zou kunnen worden.
          </p>
          <p><b>Met vriendelijke groeten,</b></p>
          <div class="schuette-family">
            <logo-schuette class="family-logo" />De familie Schuette®
          </div>
        </div>
      </div>
    </div>
    <div class="products-highlights">
      <div class="container-1000">
        <mini-categories v-if="page" :categories="page.navigation" home />
      </div>
    </div>
  </div>
</template>

<script>
import LazyVideo from 'marketplace-front-core/components/shared/LazyVideo.vue'
import Categories from '../landing/Categories.vue'
import MiniCategories from '../landing/MiniCategories'
import faq from './faq-de.js'

import LogoSchuette from '~/static/images/logo-schuette.svg?inline'
import NoDrillingIcon from '~/assets/images/homepage/nodrilling-square.svg?inline'
import DrillingIcon from '~/assets/images/homepage/drilling-square.svg?inline'
import QuickIcon from '~/assets/images/homepage/quick-outline-square.svg?inline'
import CollectionsIcon from '~/assets/images/homepage/collections-outline-square.svg?inline'
import ColorsIcon from '~/assets/images/homepage/colors-outline-square.svg?inline'
import StrongIcon from '~/assets/images/homepage/strong-outline-square.svg?inline'
import CheckIcon from '~/assets/images/homepage/check-square.svg?inline'

import WelcomeVideoTwo from '~/static/images/schuette-welcome-video-2.mp4'

export default {
  name: 'HomePageEn',
  components: {
    LazyVideo,
    Categories,
    MiniCategories,
    NoDrillingIcon,
    DrillingIcon,
    QuickIcon,
    CollectionsIcon,
    ColorsIcon,
    StrongIcon,
    CheckIcon,
    LogoSchuette
  },
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      WelcomeVideoTwo
    }
  },
  head () {
    return {
      script: [{ type: 'application/ld+json', json: this.getFaqSchemaOrg }]
    }
  },
  computed: {
    getFaqSchemaOrg () {
      const entity = faq.map((x) => {
        return {
          '@type': 'Question',
          name: x.question.replace(/["]+/g, '&quot;'),
          acceptedAnswer: {
            '@type': 'Answer',
            text: x.answer.replace(/["]+/g, '&quot;')
          }
        }
      })
      const schema = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [...entity]
      }
      return schema
    }
  }
}
</script>

<style lang="scss" scoped>
.intro-section {
  @include mobile {
    h1 {
      font-size: 24px !important;
    }
  }

  h2 {
    margin: 32px 0 16px 0;
    font-size: 20px !important;
    font-weight: 600;
  }
}

.homepage-category .check-list-container ul.check-list {
  max-width: 85%;
}

:deep(.welcome-video) video {
  border-radius: 16px;
}</style>
