<template>
  <div>
    <div class="intro-container">
      <div class="container-1000">
        <div class="intro-section">
          <h1 class="text-center">
            Tende plissettate, tende a rullo e tendaggi Schuette® di cui innamorarsi per la vostra casa!
          </h1>

          <div class="grid-1-2-1 category-intro-photos category-intro-videos">
            <div class="grid-item">
              <!-- <lazy-video :video="{video: RelaxVideoTwo}" alt="willkommen" /> -->
              <b-image
                src="https://img.schuette.shop/schuette/categories/rollos/schuette-fabrics-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/categories/rollos/schuette-fabrics-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="rollos"
              />
            </div>
            <div class="grid-item">
              <lazy-video :video="{video: WelcomeVideoTwo}" alt="willkommen" />
              <!-- <div class="grid-item-content">
                <div class="category-link-new">
                  {{ $t('project.landingPage.new') }}
                </div>
                <a :href="getSLLink">
                  <b-button type="is-primary" class="cta-primary" expanded rounded icon-right="arrow-right-bold">
                    {{ $t('project.landingPage.browseAll') }}
                  </b-button>
                </a>
              </div> -->
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/categories/pleated-blinds/schuette-fabrics-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/categories/pleated-blinds/schuette-fabrics-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="plissee"
              />
            </div>
          </div>

          <div class="check-list-container">
            <ul class="check-list is-big">
              <li><b>Semplice</b> - configurate e ordinate le vostre tende plissettate direttamente online.</li>
              <li>
                <b>Facile</b> - Scegliete il tipo di montaggio, il tessuto, il profilo e le dimensioni in
                pochi secondi.
              </li>
              <li><b>Assistenza</b> - via chat, e-mail o direttamente presso la vostra sede. Siamo sempre a vostra disposizione!</li>
              <li><b>Veloce</b> - Vedere direttamente sulla pagina del prodotto della tenda plissettata quando consegneremo</li>
              <li><b>Trasparente</b> - Tutti i prezzi sono direttamente visibili!</li>
            </ul>
          </div>

          <h2 class="text-center">
            Benvenuti alla Plissee & Rollo Manufaktur Schuette®
          </h2>

          <div class="text-container">
            <p class="text-center">
              Desideriamo ringraziarvi! Grazie per il vostro sostegno e per l'apprezzamento del nostro lavoro e della nostra passione, che investiamo nello sviluppo e nella produzione delle vostre tende plissettate , delle tende a. Ci date una forza incredibile e vorremmo ringraziarvi per questo con i migliori prodotti possibili e realizzati con passione!
            </p>
          </div>

          <categories v-if="page" :categories="page.navigation" home full>
            <template #features="{identifier}">
              <!-- plisy -->
              <template v-if="identifier === 'plisy'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                    <drilling-icon />
                    <no-drilling-icon />
                  </div>
                  <div class="feature-description">
                    Installazione rapida 60s
                    (con o senza foratura)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    5 collezioni e 74 colori
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Promessa di qualità Schuette®
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    Da 27,99 EUR <br>
                    Spedizione gratuita
                  </div>
                </div>
              </template>

              <!-- roletki -->
              <template v-if="identifier === 'roletki'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                    <drilling-icon />
                    <no-drilling-icon />
                  </div>
                  <div class="feature-description">
                    Installazione rapida 60s
                    (con o senza foratura)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    2 collezioni e 26 colori
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Promessa di qualità Schuette®
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    Da 9,99 EUR<br>
                    Spedizione gratuita
                  </div>
                </div>
              </template>

              <!-- zaslony -->
              <template v-if="identifier === 'zaslony'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                  </div>
                  <div class="feature-description">
                    Installazione rapida 60s
                    (con occhielli o nastro per tende)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    2 collezioni e 30 colori
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Promessa di qualità Schuette®
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    Da 42,99 EUR <br>
                    Spedizione gratuita
                  </div>
                </div>
              </template>
            </template>
          </categories>
        </div>
      </div>
    </div>
    <div class="company-container">
      <div class="container-1000">
        <div class="company-section">
          <h2>
            Gentile cliente ❤️
          </h2>
          <p>
            La ringraziamo per aver dedicato del tempo a scoprire di più su di noi e sui nostri prodotti.
          </p>
          <p class="font-semibold">
            Le tende plissettate, le tende a rullo e i sistemi di protezione solare Schuette® sono prodotti localmente con la massima cura artigianale e con salari equi, nonché con materiali solidi, durevoli e moderni.
          </p>
          <p>
            Siamo una piccola fabbrica a conduzione familiare e utilizziamo, sviluppiamo e viviamo i nostri prodotti giorno per giorno. Il nostro piccolo team fa tutto il possibile per garantire che le nostre tende plissettate non solo soddisfino i più alti standard di artigianalità, ma soprattutto i requisiti della vostra casa, perché sappiamo cosa significa "casa" e cosa significa per voi. Il nostro obiettivo è migliorare la qualità della vostra vita attraverso un design perfetto e una funzione superiore delle finestre.
          </p>
          <div class="grid-3n category-intro-photos">
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-company-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-company-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette®"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-company-2.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-company-2.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette®"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-company-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-company-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette®"
              />
            </div>
          </div>
          <h2 class="text-center">
            Promessa di qualità Schuette®
          </h2>
          <p>
            Siamo particolarmente orgogliosi del fatto che sviluppiamo noi stessi ogni singolo componente di tutti i nostri prodotti Schuette® e quindi manteniamo l'intero processo produttivo nelle nostre mani. Questo ci permette di mantenere standard di qualità rigorosi in ogni singola fase.
          </p>
          <p>
            La nostra attenzione si concentra sull'eccezionale durata e longevità di tutti i componenti, oltre che sulla facilità d'uso in casa. Sottolineiamo tutto questo con la nostra autentica promessa di qualità Schuette®:
          </p>
          <div class="check-list-container">
            <ul class="check-list is-big">
              <li><b>Facile da usare</b> - Fissaggi adatti per ogni finestra</li>
              <li><b>Durata comprovata</b> - Testata 100.001 volte in un'ampia varietà di configurazioni e in tutte le condizioni climatiche.</li>
              <li><b>Facilità di manutenzione</b> - Per un "dopo" senza problemi</li>
              <li><b>Protezione dai raggi UV</b> -  Per evitare lo sbiadimento dei tessuti e dei vostri mobili</li>
              <li><b>Sempre al passo con i tempi</b> -Con un'attenzione particolare al costante sviluppo e all'ottimizzazione.</li>
              <li><b>Servizio a tutto tondo</b> - Sempre a vostra disposizione, in qualsiasi momento e in qualsiasi modo.</li>
            </ul>
          </div>
          <div class="grid-1-2-1">
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Vorhänge"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-2.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-2.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Plissee"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Rollos"
              />
            </div>
          </div>

          <h2 class="text-center ">
            Schuette® - L'originale
          </h2>
          <p class="font-semibold is-big">
            Cari clienti,
          </p>
          <p>
            <b>Quello che facciamo non può essere copiato</b>. I nostri prodotti Schuette® sono il risultato di anni di dedizione ai dettagli e del continuo sviluppo delle nostre tecnologie uniche.
          </p>
          <p>
            Nonostante le caratteristiche inconfondibili che rendono i prodotti Schuette® degli originali ineguagliabili, ci sono sempre più imitatori che cercano di copiare il nostro successo. Cerchiamo di prendere come un complimento il fatto che il nostro duro lavoro sia fonte di ispirazione per altri.
          </p>
          <p>
            Tuttavia, questi ultimi dovrebbero capire che non è sufficiente utilizzare l'etichetta "Premium" o copiare le nostre immagini e i nostri design. Né è sufficiente che i nostri supporti di alta qualità siano prodotti in Cina e poi etichettati come "Made in Europe".
            I contraffattori cercano di vendere prodotti scadenti alle spalle di aziende di medie dimensioni e di produttori a conduzione familiare come i nostri.
          </p>
          <p>
            Ma una contraffazione rimane sempre una contraffazione. L'originale non può essere riprodotto, perché la passione, l'energia e la dedizione che mettiamo nello sviluppo, nella produzione, nel servizio e nel contatto diretto con voi sono unici.
          </p>
          <p>
            <b> Perché?</b> Perché viviamo letteralmente ciò che creiamo, giorno dopo giorno. Grazie a voi, i migliori clienti del mondo, siamo motivati a lavorare ogni giorno ancora più duramente.
          </p>
          <p>
            Cari clienti, continuate ad affidarvi alla qualità quando si tratta della vostra casa. Affidatevi a noi, all'originale, alla manifattura della famiglia Schuette® e alle persone che dedicano la loro vita a creare prodotti che arricchiscono la vostra casa.
          </p>

          <h2 class="text-center">
            Come piccola azienda a conduzione familiare, sappiamo cosa significa "casa"
          </h2>
          <p>
            Per questo motivo sviluppiamo e produciamo i nostri prodotti non solo nel rispetto dei più alti standard di qualità nella produzione e nei materiali, ma soprattutto per soddisfare i vostri standard e i vostri desideri come utenti, perché apprezziamo e rispettiamo la vostra "casa".
          </p>
          <div class="company-image">
            <b-image
              src="https://img.schuette.shop/schuette/homepage/it/schuette-unique.png.webp"
              webp-fallback="https://img.schuette.shop/schuette/homepage/it/schuette-unique.png"
              class="skeleton-image-placeholder"
              lazy
              alt="Schuette Bedeutung"
            />
          </div>
          <p>
            <b>Nessuno cerca le cosiddette tende plissettate fai-da-te o le tende cinesi</b>, ma purtroppo in passato questi tipi di prodotti sono finiti troppo spesso nei carrelli della spesa di molti acquirenti, perché spesso i prodotti di qualità superiore si distinguevano troppo per il prezzo.
          </p>
          <p>
            <b>Come produttori, ora stiamo risolvendo questo problema aprendo la vendita diretta a voi, il cliente finale,</b> e non solo esclusivamente ad architetti, hotel, ristoranti e rinomate aziende di installazione di sistemi di schermatura solare come in passato. Credeteci se vi diciamo che questo ci dà un piacere incredibile, giorno dopo giorno ❤️
          </p>
          <p>
            Sarebbe un onore per la nostra famiglia e per il nostro team se i nostri prodotti potessero giocare con i raggi del sole nella vostra casa e voi poteste rimanerne incantati.
          </p>
          <p><b>Cordiali saluti,</b></p>
          <div class="schuette-family">
            <logo-schuette class="family-logo" />La famiglia Schuette®
          </div>
        </div>
      </div>
    </div>
    <div class="products-highlights">
      <div class="container-1000">
        <h2 class="text-center">
          Tuffati nei nostri prodotti:
        </h2>
        <categories v-if="page" :categories="page.navigation" home />
      </div>
    </div>
  </div>
</template>

<script>
import LazyVideo from 'marketplace-front-core/components/shared/LazyVideo.vue'
import Categories from '../landing/Categories.vue'
// import CategoryFaq from '~/components/shared/CategoryFaq'

import LogoSchuette from '~/static/images/logo-schuette.svg?inline'
import NoDrillingIcon from '~/assets/images/homepage/nodrilling-square.svg?inline'
import DrillingIcon from '~/assets/images/homepage/drilling-square.svg?inline'
import QuickIcon from '~/assets/images/homepage/quick-outline-square.svg?inline'
import CollectionsIcon from '~/assets/images/homepage/collections-outline-square.svg?inline'
import ColorsIcon from '~/assets/images/homepage/colors-outline-square.svg?inline'
import StrongIcon from '~/assets/images/homepage/strong-outline-square.svg?inline'
import CheckIcon from '~/assets/images/homepage/check-square.svg?inline'

import WelcomeVideoTwo from '~/static/images/schuette-welcome-video-2.mp4'

export default {
  name: 'HomePageEn',
  components: {
    Categories,
    // CategoryFaq,
    NoDrillingIcon,
    DrillingIcon,
    QuickIcon,
    CollectionsIcon,
    ColorsIcon,
    StrongIcon,
    CheckIcon,
    LogoSchuette,
    LazyVideo
  },
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      WelcomeVideoTwo
    }
  }
}
</script>
