<template>
  <div>
    <div v-if="project === 'rzaska'" class="columns is-centered">
      <div class="column is-10">
        <rzaska />
      </div>
    </div>
    <div v-if="project === 'schuette' && page" class="homepage-category">
      <!-- Menu subkategorii -->
      <div class="container-1000">
        <category-tiles :categories="page.navigation" home />
      </div>

      <!-- Treść -->
      <template v-if="currentLang === 'pl'">
        <home-en :page="page" />
      </template>
      <template v-if="currentLang === 'en'">
        <home-en :page="page" />
      </template>
      <template v-if="currentLang === 'de'">
        <home-de :page="page" />
      </template>
      <template v-if="currentLang === 'fr'">
        <home-fr :page="page" />
      </template>
      <template v-if="currentLang === 'es'">
        <home-es :page="page" />
      </template>
      <template v-if="currentLang === 'it'">
        <home-it :page="page" />
      </template>
      <template v-if="currentLang === 'nl'">
        <home-nl :page="page" />
      </template>
      <template v-if="currentLang === 'no'">
        <home-no :page="page" />
      </template>

      <!-- Opinie -->
      <div class="section-comments">
        <comments-from-page :rates="page.rates" :gallery="page.gallery" />
      </div>

      <!-- FAQ -->
      <template v-if="currentLang === 'en'">
        <en-faq />
      </template>
      <template v-if="currentLang === 'de'">
        <de-faq :categories="page.navigation" />
      </template>
      <template v-if="currentLang === 'fr'">
        <fr-faq />
      </template>
      <template v-if="currentLang === 'es'">
        <es-faq />
      </template>
      <template v-if="currentLang === 'nl'">
        <nl-faq />
      </template>
      <template v-if="currentLang === 'it'">
        <it-faq />
      </template>
      <template v-if="currentLang === 'no'">
        <no-faq />
      </template>
    </div>
  </div>
</template>

<script>

import CommentsFromPage from 'marketplace-front-core/components/comments/CommentsFromPage.vue'
// import MiniCategories from '../components/landing/MiniCategories'
import CategoryTiles from '~/components/landing/CategoryTiles.vue'
import homeEn from '~/components/home/en.vue'
import homeDe from '~/components/home/de.vue'
import homeFr from '~/components/home/fr.vue'
import homeEs from '~/components/home/es.vue'
import homeIt from '~/components/home/it.vue'
import homeNl from '~/components/home/nl.vue'
import homeNo from '~/components/home/no.vue'
import rzaska from '~/components/home/pl.vue'
import EnFaq from '~/components/home/en-faq.vue'
import DeFaq from '~/components/home/de-faq.vue'
import FrFaq from '~/components/home/fr-faq.vue'
import EsFaq from '~/components/home/es-faq.vue'
import NlFaq from '~/components/home/nl-faq.vue'
import ItFaq from '~/components/home/it-faq.vue'
import NoFaq from '~/components/home/no-faq.vue'

export default {
  name: 'HomePage',
  components: {
    homeEn,
    homeDe,
    homeFr,
    homeEs,
    homeIt,
    homeNl,
    homeNo,
    rzaska,
    CommentsFromPage,
    // MiniCategories,
    CategoryTiles,
    EnFaq,
    DeFaq,
    FrFaq,
    EsFaq,
    NlFaq,
    ItFaq,
    NoFaq
  },
  layout: 'landing',
  middleware: 'languages',
  async asyncData ({ app, store }) {
    if (process.env.project === 'schuette') {
      return {
        page: await app.$api.default.getHome().catch(() => { return null })
      }
    }
  },
  head () {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    return {
      titleTemplate: '%s',
      title: this.getTitle,
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      meta: [
        { hid: 'description', name: 'description', content: this.getDescription },
        ...i18nHead.meta
      ],
      link: [
        ...this.metaLink(i18nHead.link)
      ],
      script: [
        ...(this.project === 'schuette' && this.page && this.page.rates
          ? [{
              type: 'application/ld+json',
              json: {
                type: 'application/ld+json',
                json: {
                  '@type': 'Organization',
                  name: 'Schuette®',
                  logo: 'https://img.schuette.shop/schuette/logo-schuette.png',
                  aggregateRating: {
                    '@type': 'AggregateRating',
                    ratingValue: this.page.rates.rate,
                    bestRating: 5.00,
                    ratingCount: this.page.rates.count
                  }
                }
              }
            }]
          : []),
        ...(this.project === 'schuette' && this.page && this.page.description
          ? [{
              type: 'application/ld+json',
              json: {
                type: 'application/ld+json',
                json: {
                  '@type': 'WebPage',
                  name: this.page.description.name_seo,
                  description: this.page.description.description_seo
                }
              }
            }]
          : [])
      ]
    }
  },
  computed: {
    getTitle () {
      if (this.project === 'rzaska') {
        return 'Rząska® - Stwórz wyjątkowy wystrój, który pokochasz!'
      }
      if (this.project === 'schuette') {
        return this.page && this.page.description && this.page.description.name_seo ? this.page.description.name_seo : ''
      }

      return this.project
    },
    getDescription () {
      if (this.project === 'rzaska') {
        return 'RĄSKA - jako producent wykonamy roletę na każdy rodzaj okna! Proste zamawianie bezpośrednio przy pomocy konfiguratora produktu. Gwarancja darmowej dostawy!'
      }
      if (this.project === 'schuette') {
        return this.page && this.page.description && this.page.description.description_seo ? this.page.description.description_seo : 'Schuette'
      }

      return this.project
    }
  },
  mounted () {
    if (process.env.facebookPixedId && this.$fb && this.$fb.fbq) {
      this.$fb.fbq('track', 'PageView')
    }
  }
}
</script>
