<template>
  <div class="section-faq">
    <div class="container-1000">
      <div class="section-tag-container">
        <div class="section-tag">
          Schuette.shop FAQ
        </div>
      </div>
      <h2 class="text-center">
        Schuette.shop - Häufig gestellte Fragen
      </h2>
      <div class="category-faq-container">
        <category-faq collapsable>
          <template #question>
            Für was steht Schuette®?
          </template>
          <template #answer>
            Unsere Familienmanufaktur bietet viel mehr als nur Sonnenschutz, Sichtschutz und Wohnaccessoires. Wir haben es uns zur Hauptaufgabe gemacht, Ihnen ein Lächeln aufs Gesicht zu zaubern, Ihren Wohnkomfort zu erhöhen und Ihre Lebensqualität zu steigern. Mit Schuette® Produkten erhalten Sie daher nicht nur Produkte für Ihr Zuhause, sondern deutlich mehr! Einfach ausprobieren und Unterschied spüren. ♥︎
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Kann ich mich beraten lassen, wenn ich mir bei der Wahl der besten Fensterabdeckung nicht sicher bin?
          </template>
          <template #answer>
            Natürlich. Wenn Sie sich nicht sicher sind, ob Plissee, Wabenplissee, Rollo oder Vorhang die beste Wahl für Ihre Fenster ist, hilft Ihnen unser Experten-Team jederzeit sehr gerne weiter! Schicken Sie uns einfach Bilder Ihrer Fenster zu und sagen uns ob Sie Bohren dürfen oder nicht sowie bei Bedarf welcher gewünschte Effekt Sie am meisten interessiert: lichtdurchlässig, leicht verdunkelnd, vollständig verdunkelnd, gegen Hitze- und Kälte usw. Wir freuen uns jederzeit von Ihnen zu hören!
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Wie kann ich den Kundenservice kontaktieren?
          </template>
          <template #answer>
            Unser Kundenservice kann per Mail unter emailfuerdich@schuette.shop oder über unser Nachrichtensystem auf der Homepage kontaktiert werden.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Sind alle Schuette® Produkte blickdicht?
          </template>
          <template #answer>
            Ja. Egal ob die Wahl auf ein Plissee, ein Rollo oder einen Vorhang fällt, alle Schuette® Produkte sind immer zu 100% blickdicht und manche halten Sonnenlicht auch komplett draußen und helfen dabei, Räume ganz abzudunkeln.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Welchen Stoff sollte ich wählen?
          </template>
          <template #answer>
            Die Auswahl des richtigen Stoffes kann in Hinblick auf Farbe, Design sowie die Eigenschaften des Stoffes getroffen werden. Jede Stoffart und jedes Design hat ihre eigenen Vorteile. Die Stoffeigenschaften variieren manchmal auch je nach Farbwahl. Näheres zu den jeweiligen Stoffeigenschaften finden Sie auf unserer Produktseite.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Was ist die silberne Beschichtung bei Thermo Produkten?
          </template>
          <template #answer>
            Die Beschichtung auf der Rückseite (Fensterseite) unserer Thermo-Stoffe reflektiert die Sonnenstrahlen und verlangsamt unter anderem deutlich das Eindringen von Hitze. Eine zusätzliche Eigenschaft der glänzenden Beschichtung ist eine mittlere bis hohe Verdunkelung des Raums. Bei dunklen Stofffarben ist die Eigenfarbe der Hitzeschutzausrüstung auf der Stoffrückseite silbern, aber bei hellen Farben eher ein Beigeton.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Kann ich Stoffmuster bestellen?
          </template>
          <template #answer>
            Sehr gerne. Stoffmuster können über unseren Kundenservice bestellt werden.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Kann ich auch nur Stoffe oder Profile bestellen?
          </template>
          <template #answer>
            Das kommt darauf an. Es ist möglich, Muster von Stoffen sowie zusätzliche Dachfenster-Führungsprofile nachzubestellen. Bei Bedarf kann hierfür unser Kundenservice kontaktiert werden. Zudem können jederzeit Plissee- und Rollo-Halterungen über unsere Homepage als Zubehör nachbestellt werden. Ganze Stoffbahnen, Profilschienen sowie Vorhang-Ösen können nicht separat bestellt werden.
          </template>
        </category-faq>
        <mini-categories v-if="categories" :categories="categories" home />
        <category-faq collapsable>
          <template #question>
            Was sind die Rückgabe&shy;bedingungen?
          </template>
          <template #answer>
            Die Rückgabebedingungen variieren je nach Produktart. Alle Produkte nach Maß, d.h. Maßanfertigungen, können nicht zurückgegeben oder umgetauscht werden, da diese individuell und speziell für jeden Kunden direkt nach aufgegebener Bestellung angefertigt werden. Produkte, die nicht auf Maß sind, können innerhalb der Rückgabefrist zurückgegeben werden, sofern diese sich in einem einwandfreien Zustand befinden. Die jeweilige Produktart ist im Titel der Produkte beschrieben. Genaueres zu unseren Rückgabebedingungen finden Sie in unseren AGBs sowie in der Widerrufsbelehrung.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Gibt es eine Möglichkeit Schuette® Produkte vor dem Kauf stationär anzusehen?
          </template>
          <template #answer>
            Momentan haben wir noch keine Ausstellungsräume oder stationäre Shops mit unseren Schuette® Produkten. Daher haben wir die Produktbilder umso realitätsgetreuer gestaltet, sodass Sie sicher sein können, dass Sie exakt das Produkt erhalten, welches Sie auf unserer Homepage sehen. Zusätzlich können bei Bedarf Stoffmuster über unseren Kundenservice bestellt werden.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Wann beginnt die Anfertigung meiner Bestellung?
          </template>
          <template #answer>
            Damit wir einen schnellstmöglichen Versand aller Produkte (maßgefertigte Produkte inbegriffen) gewährleisten können, werden alle Schuette® Produkte direkt nachdem die Bestellung aufgegeben wurde, für unsere Kunden angefertigt. Mit einigen Vorbereitungs- und Bearbeitungsschritten beginnen wir sogar automatisch und unmittelbar nach dem Bestelleingang. Wir können daher stolz sagen, dass der Großteil aller Bestellungen noch vor dem angezeigten Liefertermin zugestellt werden kann.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Werden für die Montage von Schuette® Produkten irgendwelche Werkzeuge benötigt?
          </template>
          <template #answer>
            Dies kommt auf die ausgewählte Befestigungsart an. Für eine Montage ohne Bohren werden keine Werkzeuge benötigt. Sofern Sie sich für eine Montage mit Bohren von Plissees oder Rollos entscheiden, reicht je nach Fenstertyp sogar ein Schraubenzieher aus. Wenn Sie für die Montage von Plissees “mit Bohren” zusätzlich einen Akkuschrauber und kleine Bohrer (⌀ 1.0 -2.0 mm) parat haben, wird es nochmal schneller und einfacher gehen.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Wie viel Zeit benötige ich für die Montage?
          </template>
          <template #answer>
            Dies kommt auf das gewählte Produkt und die dazugehörige Befestigungsmethode an. Es war für uns außerordentlich wichtig, die Montage von jedem Produkt, egal mit welcher Montageart, so einfach und schnell wie möglich für Sie zu gestalten. Da alle unsere Produkte bereits so gut wie möglich fertig montiert sind, ist die Montage/Installation im Handumdrehen vollbracht. Wir haben dabei besonders darauf geachtet, dass Sie keine Puzzlearbeit leisten müssen und Ihre Schuette® Produkte schnellstmöglich anzubringen sind. Die Montage selbst ist kinderleicht und dauert lediglich wenige Minuten.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Wo finde ich die Montageanleitung?
          </template>
          <template #answer>
            Die Montageanleitung wird zu jedem Produkt beigelegt. Diese finden Sie in der Verpackung bei den Griffen und Halterungen. Sollten Sie eine zusätzliche Kopie benötigen, kontaktieren Sie bitte unseren Kundenservice. Dieser sendet Ihnen gerne eine PDF-Datei per Mail zu.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Muss ich Halterungen oder anderes Zubehör einzeln kaufen?
          </template>
          <template #answer>
            Das ist nicht nötig. Alle Plissee- und Rollo-Halterungen sowie weitere zur Montage benötigte Einzelteile liegen dem bestellten Produkt immer bei. Für Vorhänge mit Tunnelband müssen jedoch die Haken oder Gleiter separat, beispielsweise im Baumarkt, erworben werden.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Bietet Schuette® ebenfalls einen Montageservice an?
          </template>
          <template #answer>
            Ein Montageservice ist nicht nötig, da die Montage von unseren Produkten kinderleicht ist und hierfür keinerlei professionelle Hilfe benötigt wird. Alle Produkte sind, sofern nach Messanleitung gemessen wurde, bereits so von uns vorbereitet, dass nichts nachjustiert werden muss und lediglich die Halterungen und Griffe angebracht werden müssen. Hierfür wird keine professionelle Hilfe von einem Montageteam benötigt, was zusätzlich deutlich Kosten spart!
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Hat Schuette® einen Reparaturservice?
          </template>
          <template #answer>
            Ja. Für den Fall, dass Plissees oder Rollos angepasst oder eingekürzt werden müssen oder bei der Montage etwas schief gegangen ist, haben wir einen Reparaturservice eingerichtet. Bitte kontaktieren Sie dafür unseren Kundensupport per Mail oder Chat.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Was kann ich tun, wenn ich mich vermessen habe?
          </template>
          <template #answer>
            Je nachdem, inwiefern Sie sich vermessen haben, ist dies nicht weiter schlimm. Bei Plissees reicht es sogar manchmal aus, lediglich die Halterungen gegen andere zu tauschen. Reicht das nicht aus, dann haben wir ja noch unseren Reparaturservice im Zuge dessen wir kleinere Maßänderungen vornehmen können. Haben Sie sich vermessen? Dann kontaktieren Sie bitte unseren Kundenservice und geben Ihre Bestellnummer und die betroffenen Produkte zusammen mit den neuen Wunschmaßen durch.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Wie lange dauert der Versand?
          </template>
          <template #answer>
            Die Lieferzeit für das jeweilige Produkt wird auf der Produktseite sowie im Warenkorb angezeigt. In der Regel versenden wir auch größere Bestellungen innerhalb von 5-10 Werktagen. Haben Sie spezielle Anforderungen an die Lieferzeit, sprechen Sie uns gerne an.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Wie viel kostet der Versand?
          </template>
          <template #answer>
            Der Versand ist innerhalb Deutschlands sowie nach Österreich kostenfrei. Die Versandkosten für andere Lieferländer werden im Warenkorb angezeigt, sobald das Lieferland ausgewählt wurde.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Wie kann ich den Sendungsstatus meiner Bestellung prüfen?
          </template>
          <template #answer>
            An die in der Bestellung genannte Mailadresse schicken wir innerhalb weniger Tage nach Ihrer Bestellung eine Mail mit der Sendungsnummer, dem dazugehörigen Lieferunternehmen sowie dem Link zur Sendungsverfolgung. Anhand dieser Informationen kann der Sendungsstatus jederzeit geprüft werden.
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Welche Zahlungsmethoden stehen mir zur Verfügung?
          </template>
          <template #answer>
            <p>
              Alle verfügbaren Zahlungsmethoden werden im Warenkorb angezeigt. Ihnen stehen die folgenden Zahlungsweisen zur Verfügung:
            </p>
            <div class="check-list-container">
              <ul class="check-list is-fullwidth">
                <li><b>Vorkasse/Banküberweisung</b></li>
                <li><b>Sofort-Überweisung</b></li>
                <li><b>Kreditkarte</b></li>
                <li><b>PayPal</b></li>
                <li><b>Klarna Pay Later Kauf auf Rechnung</b></li>
                <li><b>Klarna Slice It Ratenkauf</b></li>
                <li><b>Giropay</b></li>
                <li><b>EPS</b></li>
              </ul>
            </div>
          </template>
        </category-faq>
        <category-faq collapsable>
          <template #question>
            Wann und wie erhalte ich meine Rechnung?
          </template>
          <template #answer>
            Die Proforma-Rechnung erhalten Sie per Mail als Anhang zur Bestellbestätigung. Die finale Rechnung wird nach Eingang der Zahlung in der Zahlungsbestätigung versendet. Sollten Sie keine Rechnung erhalten haben, kontaktieren Sie bitte unseren Kundenservice.
          </template>
        </category-faq>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryFaq from '~/components/shared/CategoryFaq'
import MiniCategories from '~/components/landing/MiniCategories'

export default {
  components: {
    CategoryFaq,
    MiniCategories
  },
  props: {
    categories: {
      type: Array,
      default: null
    }
  }
}
</script>
