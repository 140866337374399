<template>
  <div class="section-faq">
    <div class="container-1000">
      <div class="section-tag-container">
        <div class="section-tag">
          Schuette.shop FAQ
        </div>
      </div>
      <h2 class="text-center">
        Schuette.shop - Häufig gestellte Fragen
      </h2>
      <div class="category-faq-container">
        <category-faq>
          <template #question>
            Waar staat Schuette® voor?
          </template>
          <template #answer>
            Ons familiebedrijf biedt veel meer dan alleen zonwering, privacyschermen en woonaccessoires. Wij hebben er onze hoofdtaak van gemaakt om een glimlach op je gezicht te toveren, je wooncomfort te verhogen en je levenskwaliteit te verbeteren. Met Schuette® producten krijg je niet alleen producten voor je huis, maar nog veel meer! Probeer ze gewoon uit en voel het verschil. ♥︎
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Kan ik advies krijgen als ik niet zeker weet wat de beste raambekleding is?
          </template>
          <template #answer>
            Natuurlijk kan dat. Als je niet zeker weet of plisségordijnen, honingraatplisségordijnen, rolgordijnen of gordijnen de beste keuze zijn voor jouw ramen, helpt ons team van experts je graag verder! Stuur ons gewoon foto's van je ramen en vertel ons of je kunt boren of niet en, indien nodig, in welk gewenst effect je het meest geïnteresseerd bent: lichtdoorlatend, licht verduisterend, volledig verduisterend, tegen warmte en kou, enz. We horen graag van je!
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Hoe kan ik contact opnemen met de klantenservice?
          </template>
          <template #answer>
            Onze klantenservice kan per e-mail worden gecontacteerd op emailfuerdich@schuette.shop of via ons berichtensysteem op de homepage.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Zijn alle Schuette® producten ondoorzichtig?
          </template>
          <template #answer>
            Ja, of je nu kiest voor een plisségordijn, rolgordijn of gordijn, alle Schuette® producten zijn altijd 100% ondoorzichtig en sommige houden ook het zonlicht volledig buiten en helpen om kamers volledig te verduisteren.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Welke stof moet ik kiezen?
          </template>
          <template #answer>
            De keuze voor de juiste stof kan worden gemaakt op basis van kleur, dessin en de eigenschappen van de stof. Elke stofsoort en elk dessin heeft zijn eigen voordelen. De stofeigenschappen variëren soms ook afhankelijk van de kleurkeuze. Meer informatie over de verschillende stofeigenschappen vind je op onze productpagina.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Wat is de zilvercoating op Thermo producten?
          </template>
          <template #answer>
            De coating aan de achterkant (raamkant) van onze Thermo stoffen reflecteert de zonnestralen en vertraagt onder andere het binnendringen van warmte aanzienlijk. Een bijkomende eigenschap van de glanzende coating is een gemiddelde tot hoge verduistering van de kamer. Bij donkere stofkleuren is de inherente kleur van de hittebeschermende afwerking op de achterkant van de stof zilver, maar bij lichte kleuren is het meer een beige tint.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Kan ik stofstalen bestellen?
          </template>
          <template #answer>
            Met alle plezier. Stoffenstalen kunnen worden besteld via onze klantenservice.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Kan ik alleen stoffen of alleen profielen bestellen?
          </template>
          <template #answer>
            Dat hangt ervan af. Het is mogelijk om stofstalen en extra dakraamgeleidingsprofielen bij te bestellen. Neem indien nodig contact op met onze klantenservice. Plisségordijn- en rolgordijnbeugels kunnen ook altijd als accessoires worden besteld via onze homepage. Hele stofpanelen, profielrails en gordijnogen kunnen niet los besteld worden.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Wat zijn de retourvoorwaarden?
          </template>
          <template #answer>
            De retourvoorwaarden verschillen per producttype. Alle op maat gemaakte producten, d.w.z. producten op maat, kunnen niet worden geretourneerd of geruild, omdat ze direct na de bestelling individueel en specifiek voor elke klant worden gemaakt. Producten die niet op maat worden gemaakt, kunnen binnen de retourtermijn worden geretourneerd, mits ze in perfecte staat verkeren. Het desbetreffende producttype wordt beschreven in de producttitel. Meer gedetailleerde informatie over onze retourvoorwaarden vind je in onze algemene voorwaarden en in de annuleringsvoorwaarden.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Waar is de Schuette® fabriek gevestigd?
          </template>
          <template #answer>
            Onze Schuette®-fabriek bevindt zich in het hart van Europa en dus niet, zoals de meeste andere fabrikanten, buiten de EU, bijvoorbeeld in Azië. Lokale materialen, lokale productie, lokale levering voor een duurzame toekomst! ♥︎
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Is het mogelijk om Schuette® producten in een showroom te bekijken voordat ik ze koop?
          </template>
          <template #answer>
            Op dit moment hebben we geen showrooms of winkels met onze Schuette® producten. Daarom hebben we de productafbeeldingen des te realistischer gemaakt, zodat u er zeker van kunt zijn dat u precies het product ontvangt dat u op onze homepage ziet. Bovendien kun je, indien gewenst, stofstalen bestellen via onze klantenservice.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Wanneer begint de productie van mijn bestelling?
          </template>
          <template #answer>
            Om een zo snel mogelijke verzending van alle producten (ook op maat gemaakte producten) te garanderen, worden alle Schuette® producten onmiddellijk na de bestelling voor onze klanten gemaakt. We beginnen zelfs automatisch en onmiddellijk na ontvangst van de bestelling met enkele voorbereidings- en verwerkingsstappen. We kunnen daarom met trots zeggen dat de meerderheid van alle bestellingen voor de aangegeven leverdatum geleverd kan worden.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Is er gereedschap nodig voor de installatie van Schuette® producten?
          </template>
          <template #answer>
            Dit hangt af van de gekozen montagemethode. Voor montage zonder boren is geen gereedschap nodig. Als u besluit om plisségordijnen of rolgordijnen te installeren met boren, kan een schroevendraaier zelfs voldoende zijn, afhankelijk van het type raam. Als je ook een accuschroevendraaier en kleine boortjes (⌀ 1,0 - 2,0 mm) bij de hand hebt voor het monteren van plisségordijnen "met boren", gaat het nog sneller en gemakkelijker.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Hoeveel tijd heb ik nodig voor de installatie?
          </template>
          <template #answer>
            Dit hangt af van het gekozen product en de bijbehorende montagemethode. Het was uiterst belangrijk voor ons om de installatie van elk product, ongeacht de montagemethode, zo snel en gemakkelijk mogelijk voor je te maken. Omdat al onze producten al zo goed mogelijk gemonteerd zijn, is de montage/installatie in een mum van tijd voltooid. We hebben er extra op gelet dat u geen puzzelwerk hoeft te doen en dat uw Schuette® producten zo snel mogelijk geïnstalleerd kunnen worden. De montage zelf is kinderspel en neemt slechts enkele minuten in beslag.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Waar kan ik de montagehandleiding vinden?
          </template>
          <template #answer>
            Bij elk product zit een montagehandleiding. Je vindt ze in de verpakking met de handgrepen en beugels. Als je een extra exemplaar nodig hebt, neem dan contact op met onze klantenservice. Zij sturen je graag een PDF-bestand per e-mail.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Moet ik beugels of andere accessoires apart kopen?
          </template>
          <template #answer>
            Dat is niet nodig. Alle plissé- en rolgordijnbeugels en andere losse onderdelen die nodig zijn voor de installatie worden altijd meegeleverd met het bestelde product. Voor gordijnen met tunnelband moeten de haken of glijders echter apart worden gekocht, bijvoorbeeld bij een bouwmarkt.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Biedt Schuette® ook een installatieservice aan?
          </template>
          <template #answer>
            Een installatieservice is niet nodig, aangezien de installatie van onze producten kinderspel is en er geen professionele hulp nodig is. Alle producten zijn al door ons voorbereid, zodat niets opnieuw hoeft te worden afgesteld en alleen de beugels en handgrepen moeten worden bevestigd. Hiervoor is geen professionele hulp van een montageteam nodig, wat ook nog eens aanzienlijke kosten bespaart!
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Heeft Schuette® een reparatieservice?
          </template>
          <template #answer>
            Ja, we hebben een reparatieservice voor het geval plisségordijnen of rolgordijnen moeten worden aangepast of ingekort, of als er iets misgaat tijdens de installatie. Neem contact op met onze klantenservice via e-mail of chat.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Wat kan ik doen als ik een fout heb gemaakt?
          </template>
          <template #answer>
            Afhankelijk van de mate waarin je een fout hebt gemaakt, is dit geen probleem. Bij plisségordijnen is het soms zelfs voldoende om gewoon de beugels door andere te vervangen. Als dat niet genoeg is, hebben we nog altijd onze reparatieservice waarbij we kleine maatwijzigingen kunnen doorvoeren. Heb je zelf gemeten? Neem dan contact op met onze klantenservice en geef je bestelnummer en de betreffende producten door, samen met de nieuwe gewenste afmetingen.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Hoe lang duurt de verzending?
          </template>
          <template #answer>
            De levertijd voor elk product wordt weergegeven op de productpagina en in het winkelmandje. In de regel verzenden we ook grotere bestellingen binnen 5-10 werkdagen. Als je speciale vereisten hebt voor de levertijd, neem dan contact met ons op.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Hoeveel kost de verzending?
          </template>
          <template #answer>
            De verzending is gratis binnen Duitsland en naar Oostenrijk. De verzendkosten voor andere landen worden weergegeven in het winkelmandje zodra het land van levering is geselecteerd.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Hoe kan ik de bezorgstatus van mijn bestelling controleren?
          </template>
          <template #answer>
            Binnen enkele dagen na je bestelling sturen we een e-mail naar het e-mailadres dat je bij de bestelling hebt opgegeven met het zendingsnummer, het betreffende bezorgbedrijf en de link om de zending te volgen. Je kunt deze informatie gebruiken om op elk gewenst moment de status van de zending te controleren.
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Welke betalingsmethoden zijn beschikbaar voor mij?
          </template>
          <template #answer>
            Alle beschikbare betalingsmethoden worden weergegeven in het winkelmandje.
            De volgende betalingsmethoden zijn beschikbaar:
            <p>Vooruitbetaling/overschrijving</p>
            <p>Directe bankoverschrijving</p>
            <p>Kredietkaart</p>
            <p>PayPal</p>
            <p>Klarna Pay Later aankoop op rekening</p>
            <p>Klarna Slice It aankoop op afbetaling</p>
            <p>Giropay</p>
            <p>EPS</p>
          </template>
        </category-faq>
        <category-faq>
          <template #question>
            Wanneer en hoe ontvang ik mijn factuur?
          </template>
          <template #answer>
            Je ontvangt de proforma factuur per e-mail als bijlage bij de orderbevestiging. De definitieve factuur wordt verzonden na ontvangst van de betaling in de betalingsbevestiging. Als je geen factuur hebt ontvangen, neem dan contact op met onze klantenservice.
          </template>
        </category-faq>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryFaq from '~/components/shared/CategoryFaq'
import MiniCategories from '~/components/landing/MiniCategories'

export default {
  components: {
    CategoryFaq,
    MiniCategories
  },
  props: {
    categories: {
      type: Array,
      default: null
    }
  }
}
</script>
