<template>
  <div>
    <div class="intro-container">
      <div class="container-1000">
        <div class="intro-section">
          <h1 class="text-center">
            Schuette® Pleated blinds, Roller blinds, Curtains to fall in love with for your home!
          </h1>

          <div class="grid-1-2-1 category-intro-photos category-intro-videos">
            <div class="grid-item">
              <!-- <lazy-video :video="{video: RelaxVideoTwo}" alt="willkommen" /> -->
              <b-image
                src="https://img.schuette.shop/schuette/categories/rollos/schuette-fabrics-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/categories/rollos/schuette-fabrics-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="rollos"
              />
            </div>
            <div class="grid-item">
              <lazy-video :video="{video: WelcomeVideoTwo}" alt="willkommen" />
              <!-- <div class="grid-item-content">
                <div class="category-link-new">
                  {{ $t('project.landingPage.new') }}
                </div>
                <a :href="getSLLink">
                  <b-button type="is-primary" class="cta-primary" expanded rounded icon-right="arrow-right-bold">
                    {{ $t('project.landingPage.browseAll') }}
                  </b-button>
                </a>
              </div> -->
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/categories/pleated-blinds/schuette-fabrics-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/categories/pleated-blinds/schuette-fabrics-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="plissee"
              />
            </div>
          </div>

          <div class="check-list-container">
            <ul class="check-list is-big">
              <li><b>Uncomplicated</b> - Configure and order your window blinds and curtains directly online</li>
              <li><b>Simple</b> - Choose mounting type, fabric, profile & size in seconds</li>
              <li><b>Service</b> - Chat, email or directly at your location. We are always there for you!</li>
              <li><b>Fast</b> - See directly on the product page of the pleated blinds when we deliver</li>
              <li><b>Transparent</b> - All prices are directly visible!</li>
            </ul>
          </div>

          <h2 class="text-center">
            Welcome to the pleated blind & roller blind manufacturer Schuette®
          </h2>

          <div class="text-container">
            <p class="text-center">
              We just want to say thank you! Thank you for your support, as well as your appreciation of our work & passion, which we invest in the development and production of your pleated blinds, roller blinds and curtains.
            </p>
            <p class="text-center">
              You give us an incredible amount of strength & we would like to thank you for this with the best possible & passionately manufactured products!
            </p>
          </div>

          <categories v-if="page" :categories="page.navigation" home full>
            <template #features="{identifier}">
              <!-- plisy -->
              <template v-if="identifier === 'plisy'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                    <drilling-icon />
                    <no-drilling-icon />
                  </div>
                  <div class="feature-description">
                    Sleek 60s assembly (Drill or No-Drill)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    5 collections & 74 colors
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Schuette® Quality Promise
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    From 27.99 EUR <br>
                    Free shipping
                  </div>
                </div>
              </template>

              <!-- roletki -->
              <template v-if="identifier === 'roletki'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                    <drilling-icon />
                    <no-drilling-icon />
                  </div>
                  <div class="feature-description">
                    Sleek 60s assembly (Drill or No-Drill)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    2 collections & 26 colors
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Schuette® Quality Promise
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    From 9.99 EUR <br>
                    Free shipping
                  </div>
                </div>
              </template>

              <!-- zaslony -->
              <template v-if="identifier === 'zaslony'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                  </div>
                  <div class="feature-description">
                    Sleek 60s assembly (With Eyelets or Tunnel tape)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    2 collections & 30 colors
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Schuette® Quality Promise
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    From 42.99 EUR <br>
                    Free shipping
                  </div>
                </div>
              </template>
            </template>
          </categories>
        </div>
      </div>
    </div>
    <div class="company-container">
      <div class="container-1000">
        <div class="company-section">
          <h2>
            Dear customer ❤️
          </h2>
          <p>
            Thank you for taking the time to learn more about us and our products.
          </p>
          <p class="font-semibold">
            Schuette® Pleated Blinds, Roller Blinds and sun protection systems are manufactured locally under strictest craftsmanship and at fair wages, as well as from solid, durable & modern materials.
          </p>
          <p>
            We are a small family-owned factory and we use, develop & live our products day in, day out. Our small team does everything possible to ensure that our pleated blinds, roller blinds and curtains not only meet the highest standards of craftsmanship, but above all the demands of your home, because we understand what “home” means and what it means for you. Enhancing quality of life through perfect design and superior functionality on windows is our aspiration.
          </p>
          <div class="grid-3n category-intro-photos">
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-company-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-company-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette®"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-company-2.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-company-2.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette®"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-company-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-company-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette®"
              />
            </div>
          </div>
          <h2 class="text-center">
            Schuette® Quality Promise
          </h2>
          <p>
            We take pride in manufacturing every single component of our Schuette® products personally in-house and therefore keep the entire production process in our own hands. This enables us to maintain strict quality standards at every single stage.
          </p>
          <p>
            The focus is on exceptional durability and longevity of all components as well as ease of use in your home. We underline all this with our authentic Schuette® quality promise:
          </p>
          <div class="check-list-container">
            <ul class="check-list is-big">
              <li><b>Easy to use</b> - Suitable fixings for every window</li>
              <li><b>Proven durability</b> - Tested 100,001 times in various configurations and under all climatic conditions</li>
              <li><b>Effortless care</b> - For an unworried Aftercare</li>
              <li><b>UV protection</b> - To prevent the fabrics & your furniture from fading</li>
              <li><b>Always up-to-date</b> - With a focus on constant development & optimization</li>
              <li><b>All-round service</b> - Always here for you, no matter when, no matter how.</li>
            </ul>
          </div>
          <div class="grid-1-2-1">
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Vorhänge"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-2.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-2.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Plissee"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Rollos"
              />
            </div>
          </div>

          <h2 class="text-center ">
            Schuette® - The Original
          </h2>
          <p class="font-semibold is-big">
            Dear customers,
          </p>
          <p>
            <b>What we do can not be copied</b>. Our Schuette® products are the result of years of dedication to detail and the continuous development of our unique technologies.
          </p>
          <p>
            Despite the unmistakable features that make Schuette® products incomparable originals, there are more and more imitators trying to copy our success. We try to take it as a compliment that our hard work is a source of inspiration for others.
          </p>
          <p>
            However, they should understand that it is not enough to use the label "premium" or to copy our images and designs. Nor is it enough to have our high-quality mounts produced in China in inferior quality and then declare them as "Made in Europe".
            Counterfeiters try to sell inferior products on the back of medium-sized companies and family-run manufacturers like ours.
          </p>
          <p>
            But a counterfeit always remains a counterfeit. The original cannot be reproduced, because the passion, energy and dedication we put into development, production, service and direct contact with you are unique.
          </p>
          <p>
            <b>Why?</b> Because we literally live what we create - day after day. Thanks to you, the best customers in the world, we are motivated to work even harder every day.
          </p>
          <p>
            Dear customers, please continue to rely on quality when it comes to your home. Rely on us, on the original, on the Schuette® family manufactory and on people who dedicate their lives to creating products that enrich your home.
          </p>

          <h2 class="text-center">
            We as a small family business know what “home” means
          </h2>
          <p>
            That's why we develop and produce our products not only in compliance with the highest quality standards in manufacturing and materials, but above all to meet your standards and wishes as a user, because we appreciate and respect your “home”.
          </p>
          <div class="company-image">
            <b-image
              src="https://img.schuette.shop/schuette/homepage/en/schuette-unique.jpg.webp"
              webp-fallback="https://img.schuette.shop/schuette/homepage/en/schuette-unique.jpg"
              class="skeleton-image-placeholder is-hidden-mobile"
              lazy
              alt="Schuette Bedeutung"
            />
            <b-image
              src="https://img.schuette.shop/schuette/homepage/en/schuette-unique-mobile.jpg.webp"
              webp-fallback="https://img.schuette.shop/schuette/homepage/en/schuette-unique-mobile.jpg"
              class="skeleton-image-placeholder is-hidden-tablet mobile-unique"
              lazy
              alt="Schuette Bedeutung"
            />
          </div>
          <p>
            <b>Nobody is looking for so-called DIY store pleated blinds or China blinds</b>, but unfortunately this kind of products in the past ended up too often in the shopping carts of many buyers, because much higher quality products often simply stood out too much in price.
          </p>
          <p>
            <b>We now solve this problem as a producer by the opening up direct sales to you as the end customers</b> and not exclusively to architects, hotels, restaurants & renowned installation companies for sun protection systems as before and believe us when we say that this gives us unbelievable pleasure day after day ❤️
          </p>
          <p>
            Our family and our team would be honored if you would let our products play with the sunrays in your home and be enchanted by them.
          </p>
          <p><b>Love,</b></p>
          <div class="schuette-family">
            <logo-schuette class="family-logo" />Schuette® Family
          </div>
        </div>
      </div>
    </div>
    <div class="products-highlights">
      <div class="container-1000">
        <h2 class="text-center">
          Dive into our products:
        </h2>
        <categories v-if="page" :categories="page.navigation" home />
      </div>
    </div>
  </div>
</template>

<script>
import LazyVideo from 'marketplace-front-core/components/shared/LazyVideo.vue'
import Categories from '../landing/Categories.vue'
import faq from './faq-en.js'

import LogoSchuette from '~/static/images/logo-schuette.svg?inline'
import NoDrillingIcon from '~/assets/images/homepage/nodrilling-square.svg?inline'
import DrillingIcon from '~/assets/images/homepage/drilling-square.svg?inline'
import QuickIcon from '~/assets/images/homepage/quick-outline-square.svg?inline'
import CollectionsIcon from '~/assets/images/homepage/collections-outline-square.svg?inline'
import ColorsIcon from '~/assets/images/homepage/colors-outline-square.svg?inline'
import StrongIcon from '~/assets/images/homepage/strong-outline-square.svg?inline'
import CheckIcon from '~/assets/images/homepage/check-square.svg?inline'

import WelcomeVideoTwo from '~/static/images/schuette-welcome-video-2.mp4'

export default {
  name: 'HomePageEn',
  components: {
    LazyVideo,
    Categories,
    NoDrillingIcon,
    DrillingIcon,
    QuickIcon,
    CollectionsIcon,
    ColorsIcon,
    StrongIcon,
    CheckIcon,
    LogoSchuette
  },
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      WelcomeVideoTwo
    }
  },
  head () {
    return {
      script: [{ type: 'application/ld+json', json: this.getFaqSchemaOrg }]
    }
  },
  computed: {
    getFaqSchemaOrg () {
      const entity = faq.map((x) => {
        return {
          '@type': 'Question',
          name: x.question.replace(/["]+/g, '&quot;'),
          acceptedAnswer: {
            '@type': 'Answer',
            text: x.answer.replace(/["]+/g, '&quot;')
          }
        }
      })
      const schema = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [...entity]
      }
      return schema
    },
    getSLLink () {
      return this.localePath({
        name: 'product',
        params: {
          productName: this.toSlugTitle({ name: this.getSLName }),
          productId: 66837
        }
      })
    },
    getSLName () {
      switch (this.currentLang) {
        // LINK_PRODUKCYJNY
        case 'en':
          return 'schuette-fitted-sheet-with-all-round-elastic-wow-collection-white-90-x-200-x-20-cm'
        case 'fr':
          return 'schuette-drap-housse-avec-elastique-circulaire-collection-wow-blanc-90-x-200-x-20-cm'
        case 'es':
          return 'sabana-bajera-schuette-con-elastico-integral-wow-coleccion-blanco-90-x-200-x-20-cm'
        case 'it':
          return 'lenzuolo-teso-schuette-con-elastico-integrale-wow-collezione-bianco-90-x-200-x-20-cm'
        default:
          return 'schuette-spannbettlaken-mit-rundumgummi-90-x-200-x-20-cm-wow-kollektion-weis'
      }
    }
  }
}
</script>
