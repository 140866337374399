export default [
  {
    question: 'What does Schuette® stand for?',
    answer:
      ' Our family manufactory offers much more than just window coverings and home accessories. We have made it our main task to put a smile on your face, increase your comfort and improve your quality of life. With Schuette® products, you not only get products for your home, but much more! Just try them out and feel the difference. ♥︎'
  },
  {
    question:
      "Can I get advice if I'm not sure about the best window coverings?",
    answer:
      'Yes, if you are not sure whether a pleated blind, roller blind or curtain would be the best choice for your windows, our team of experts will be happy to help you at any time! Simply send us pictures of your windows and tell us whether you can drill or not and, if necessary, which desired effect you are most interested in: translucent, slightly darkening, completely darkening, against heat and cold, etc. We look forward to hearing from you at any time!'
  },
  {
    question: 'How can I contact customer service?',
    answer:
      'Our customer service can be contacted by e-mail at emailfuerdich@schuette.shop or via our message system on the homepage.'
  },
  {
    question: 'Are all Schuette® products opaque?',
    answer:
      'Yes, regardless of whether you choose a pleated blind, roller blind or curtain, all Schuette® products are always 100% opaque.'
  },
  {
    question: 'Which fabric should I choose?',
    answer:
      'The choice of the right fabric can be made in terms of color, design and the properties of the fabric. Each type of fabric and design has its own advantages. The fabric properties sometimes also vary depending on the choice of color. You can find more information on the respective fabric properties on our product page.'
  },
  {
    question: 'What is the silver coating on Thermo products?',
    answer:
      "The silver coating on the reverse side (window side) of our Thermo fabrics reflects the sun's rays and, among other things, significantly slows down the penetration of heat. An additional property of the shiny silver coating is a medium to high darkening of the room."
  },
  {
    question: 'Can I order fabric samples?',
    answer: 'Yes, fabric samples can be ordered via our customer service.'
  },
  {
    question: 'Can I order just fabrics or profiles?',
    answer:
      'That depends. It is possible to order fabric samples and additional roof window guide profiles. If required, please contact our customer service. Pleated blind and roller blind brackets can also be reordered at any time via our homepage. Entire fabric panels, profile rails and curtain eyelets cannot be ordered separately.'
  },
  {
    question: 'What are the return conditions?',
    answer:
      'The return conditions vary depending on the product type. All made-to-measure products, i.e. custom-made products, cannot be returned or exchanged as they are made individually and specifically for each customer directly after the order has been placed. Products that are not made to measure can be returned within the return period, provided they are in perfect condition. The respective product type is described in the product title. You can find more detailed information on our return conditions in our general terms and conditions and in the cancellation policy.'
  },
  {
    question: 'Where is the Schuette® manufactory located?',
    answer:
      'Our Schuette® family manufactory is located in the heart of Europe and therefore not, like the majority of other manufacturers, outside the EU, e.g. in Asia. Local materials, local production, local delivery for a sustainable future! ♥︎'
  },
  {
    question:
      'Is it possible to view Schuette® products in a showroom before buying?',
    answer:
      'At the moment we do not have any showrooms or stationary stores with our Schuette® products. We have therefore made the product images all the more realistic so that you can be sure that you will receive exactly the product you see on our homepage. In addition, fabric samples can be ordered via our customer service if required.'
  },
  {
    question: 'When does the production of my order begin?',
    answer:
      'To ensure the fastest possible shipment of all products (including custom-made products), all Schuette® products are made for our customers immediately after the order is placed. We can therefore proudly say that the majority of all orders are delivered before the indicated delivery date.'
  },
  {
    question: 'Are any tools required for the assembly of Schuette® products?',
    answer:
      'This depends on the chosen installation method. No tools are required for installation without drilling. If you decide to install pleated blinds or roller blinds with drilling, all you need is a screwdriver. If you also have a cordless screwdriver to hand, it will be even quicker.'
  },
  {
    question: 'How much time do I need for installation?',
    answer:
      "This depends on the chosen product and the corresponding installation method. It was extremely important for us to make the assembly of each product, regardless of the assembly method, as easy and quick as possible for you. As all our products are already assembled as well as possible, assembly/installation is completed in no time at all. We have taken extra care to ensure that you don't have to do any puzzle work and that your Schuette® products can be installed as quickly as possible. The installation itself is child's play and only takes a few minutes."
  },
  {
    question: 'Where can I find the installation instructions?',
    answer:
      'The assembly instructions are included with every product. You will find them in the packaging with the handles and brackets. If you need an additional copy, please contact our customer service. They will be happy to send you a PDF file by email.'
  },
  {
    question: 'Do I have to buy brackets or other accessories separately?',
    answer:
      ' No. All pleated and roller blind brackets and other individual parts required for installation are always included with the ordered product. For curtains with tunnel tape, however, the hooks or glides must be purchased separately, for example from a DIY store.'
  },
  {
    question: 'Does Schuette® also offer an installation service?',
    answer:
      "No, as the installation of our products is child's play and no professional help is required. All products are already prepared by us so that nothing needs to be readjusted and only the brackets and handles need to be attached. No professional help from an assembly team is required for this, which also saves considerable costs!"
  },
  {
    question: 'Does Schuette® have a repair service?',
    answer:
      'Yes, if you need longer cords, something has gone wrong during assembly or another mishap has occurred, we will be happy to help you. Please contact our customer support team by email or chat.'
  },
  {
    question: 'What can I do if I have made a mistake?',
    answer:
      'Depending on the extent to which you have made a mistake, this is not a big deal. Sometimes it is even sufficient to simply replace the brackets with different ones. If you have mismeasured, please contact our customer service and state your order number, the affected dimensions and the new dimensions required.'
  },
  {
    question: 'How long does shipping take?',
    answer:
      'The delivery time for the respective product is displayed on the product page and in the shopping cart.'
  },
  {
    question: 'How much does shipping cost?',
    answer:
      'Shipping is free of charge within Germany and to Austria. The shipping costs for other delivery countries are displayed in the shopping cart as soon as the delivery country has been selected.'
  },
  {
    question: 'How can I check the delivery status of my order?',
    answer:
      'Within a few days of placing your order, you will receive an email with the consignment number, the relevant delivery company and a link to track your consignment to the email address provided in the order. You can use this information to check the shipment status at any time.'
  },
  {
    question: 'When and how do I receive my invoice?',
    answer:
      'You will receive the pro forma invoice by email as an attachment to the order confirmation. The final invoice will be sent after receipt of payment in the payment confirmation. If you have not received an invoice, please contact our customer service.'
  },
  {
    question: 'What are pleated blinds, and how do they work?',
    answer:
      '  Pleated blinds are window coverings that consist of one piece of accordion-pleated fabric. The pleated fabric along with the handles on both profile rails allows for easy adjustment of the window blind. These blinds can be set at any height and adjusted from both the bottom as well as the top profile rail. Opening the blind stretches the individual pleats, while closing the window covering accentuates the pleat pattern.'
  },
  {
    question:
      'Are pleated blinds suitable for different window shapes and sizes?',
    answer:
      ' Yes. The best window shape for pleated blinds are rectangular windows, due to the equal rectangular form of the window covering itself. Windows can be very small starting from a size of 20x20 cm to very wide and high windows with a size up to 180x250 cm. However it is indeed also possible to cover windows in forms of circles, triangles, trapezes with pleated blinds. For an assembly like this, choose a mount with drilling to the wall or window frame.'
  },
  {
    question: 'Are energy-efficient pleated blinds worth it?',
    answer:
      'Yes. Pleated blinds help with insulation and energy efficiency. The extent to which energy costs are lowered and how much better the insulation is, depends on the chosen fabric and type of pleated blind. The winner among pleated blinds in terms of energy-efficiency and insulation are Honeycomb blinds. Due to their air-trapping capability, during the colder months 40% less warmed air is lost through the window and in summer over 70% less heat enters the room (in comparison to no blind).'
  },
  {
    question:
      'Do pleated blinds provide privacy while allowing natural light in?',
    answer:
      'Yes. Pleated blinds always provide full privacy when completely opened. Depending on the chosen fabric they simultaneously let natural light in. For maximum possible light flow while retaining complete privacy, choose a light-colored fabric without thermal coating (e.g. Premium White).'
  },
  {
    question:
      'Are pleated blinds customizable in terms of colors and patterns?',
    answer:
      ' Yes. That is one of the beauties of made-to-measure pleated blinds. Custom-made blinds can be personalized in terms of fabric type and color, which also includes different fabric patterns, mount type and color, profile rail color and measurements.'
  },
  {
    question:
      'Are there special pleated blind fabrics suitable for moist environments like kitchens and bathrooms?',
    answer:
      ' Yes. In order for pleated blinds to be suitable for moist environments they should not consist of natural fabrics like cotton, silk or linen, because these fabrics tend to soak up moisture and therefore rot or fade in the long run. The best moisture-resistant option are pleated blinds made of Polyester. Polyester blinds are durable, easy to clean and can be used in kitchens and bathrooms.'
  },
  {
    question: 'Are Roller Blinds in Style?',
    answer:
      ' Yes, roller blinds are timeless and adaptable to various interior styles (e.g. minimalistic, modern, boho and more). They have been on the market for a long time and that for a good reason. They offer a classic and modern look, making them a versatile choice for any room.'
  },
  {
    question: 'Can Roller Blinds Be Top Down Bottom Up?',
    answer:
      ' Yes. To accommodate your preferences, roller blinds can be top-down bottom-up simply by screwing the back of the roller shade, i.e. the rail, onto the ceiling with the bottom up. The roller shade mechanism adapts automatically allowing for a smooth and efficient light and privacy control.'
  },
  {
    question: 'Do Roller Blinds Block Heat?',
    answer:
      'Yes, roller blinds block heat and UV rays, with the degree of effectiveness determined by the fabric choice. Providing effective energy efficiency and temperature regulation, Thermo Blackout roller blinds are the best for this purpose, due to their opaque fabric being additionally equipped with a silver coating that effectively reflects sun rays.'
  },
  {
    question: 'How Do Roller Blinds Work?',
    answer:
      'Roller blinds feature a fabric attached on one side to a hollow tube, which can be rolled up or down using a beaded chain loop. On the bottom of the fabric there is a weighting bar keeping the fabric that is hanging down smooth and in position. These blinds are user-friendly and versatile for light control.'
  },
  {
    question: 'How to Measure Roller Blinds?',
    answer:
      ' To measure roller blinds accurately, add a small allowance to your window’s width and height, considering the window handle’s clearance. The measurement process is the same for both inside and outside mount options.'
  },
  {
    question: 'What Color Roller Blinds Should I Get?',
    answer:
      'The choice of roller blind color depends on your preferences. You can match the color to your window trim, walls, and decor in order to make the room appear bigger or choose contrasting tones for accentuating your windows. Roller blinds offer both style and privacy in various colors. With neutrals like white, beige or gray, you’re always on the safe and timeless side.'
  },
  {
    question: 'How to Install Roller Blinds?',
    answer:
      'Installing roller blinds is straightforward. They come almost fully assembled, requiring only the attachment of brackets to your window frame or wall. You can choose between no-drill or drill mounts. Both mounting options are always provided. Assembly without drilling: 1. Slide the clamp holders on the back of the roller blind rail. 2. Attach the adhesive straps onto the clamp holders (optional). 3. Click the roller blind with the attached clamp holders onto your window frame. Assembly with drilling: 1. Screw the mounts with drilling on the desired position of your window frame, ceiling or wall. 2. Click the roller blind rail on the mounted brackets. Roller blinds can also be mounted outside the window recess. Screw the provided brackets with drilling onto the wall above the window recess.'
  },
  {
    question: 'How to Clean Roller Blinds?',
    answer:
      'Cleaning roller blinds is simple. Begin by dusting with a dry brush or vacuum on a low setting. For stains, use lukewarm water with a mild dishwashing detergent (to avoid watermarks) on a soft cloth. Avoid rubbing, and let it air dry. Double roller blinds can be fully cleaned, but the silvery backside of Thermo Blackout roller blinds should not come in contact with water or detergent.'
  },
  {
    question: 'Which blackout curtains are the best?',
    answer:
      'The best blackout curtain colors and fastening methods are dependent on your unique style and practical considerations. Your choice of color should harmonize with your room’s existing theme or create a striking contrast to add a pop of character to your windows. Blackout curtains come in a range of exquisite colors, allowing you to select the perfect shade that aligns with your preferences.  When it comes to fastening methods, you have two classic and timeless options: eyelets and tunnel tape. The decision between these two depends on the space above your window and the room’s aesthetics. Eyelets are ideal for curtain rods, offering easy installation and smooth movement. On the other hand, curtains with tunnel tape can be mounted on both curtain rods and rails. These options ensure you can achieve both functionality and style.'
  },
  {
    question: 'How do blackout curtains work?',
    answer:
      'Blackout curtains are crafted from thick, tightly-woven polyester with a luxurious appearance. The secret behind their effectiveness lies in this high-quality fabric. It is this construction that allows them to cast your room into near-complete darkness along with noise-reduction and better acoustics, making them the perfect window treatment for bedrooms and living rooms or whenever you desire a serene and private environment.'
  },
  {
    question: 'Will blackout curtains reduce heat?',
    answer:
      ' Yes. Blackout curtains are excellent at regulating room temperature, because of the tightly-woven and high-quality fabrics that provide full darkening and prevent heat from entering the room. If no sun rays can enter the room, there is nearly no opportunity for the room temperature to rise.'
  },
  {
    question: 'Are blackout curtains noise-reducing?',
    answer:
      'Yes. Noise from the outside penetrates to a large extent through windows. Therefore, any kind of window covering is useful. However the thicker the material the less noise comes through. Blackout curtains are definitely noise-reducing and make a tremendous difference to windows with no window coverings. When living near busy streets or surroundings the best option for maximum noise reduction would be a combination of blackout curtains and pleated shades.'
  },
  {
    question: 'What sizes do blackout curtains come in?',
    answer:
      ' Blackout curtains come in a wide variety of sizing options. In particular personalized or custom-made blackout curtains offer an extraordinary range of sizes. You can even enter the measurements in steps of 1 mm and therefore have literally millions of sizing options, with widths up to 270 cm and a maximum height of 450 cm.'
  },
  {
    question: 'Can blackout curtains be white?',
    answer:
      'Blackout curtains are available in various colors, including white. The color of blackout curtains doesn’t affect their light-blocking capabilities. Even lighter-colored options like white maintain their darkening effect. The key lies in the quality of the fabric and its composition, not its color. For this reason, it’s advisable to choose high-quality materials to ensure effective light blocking.'
  },
  {
    question: 'Why blackout curtains?',
    answer:
      'Blackout curtains offer a multitude of benefits. They provide additional privacy, light control, and temperature regulation, making them versatile window coverings. These curtains can be installed alongside other window coverings, such as roller or pleated shades, allowing you to choose the most suitable option for your needs. They also serve as an excellent backdrop for videos and photos, thanks to their easy installation and a wide range of color choices. Blackout curtains are ideal for achieving a restful sleep environment, but they also enhance room acoustics and contribute to energy efficiency.'
  },
  {
    question: 'When blackout curtains aren’t enough?',
    answer:
      'While blackout curtains are effective at blocking light and noise, there may be instances where they are not sufficient to fully darken a room. Bright external lights, street lamps, or intense sunlight can be challenging to combat with blackout curtains alone. In such cases, you might consider a combination of window coverings, such as blackout curtains and pleated shades, for a layered solution. This approach offers greater light control and ensures complete darkness when needed.'
  }
]
